import {GenericAjaxService, adminAPI, publicAPI} from 'tds_shared_ui';

export default class NotificationsService extends GenericAjaxService {

    static updateNotifications(data){
        return super.putAPI(adminAPI, `notifications/${data.id}`, data);
    }

    static createNotifications(data){
        return super.postAPI(adminAPI, `notifications`, data);
    }

    static deleteNotifications(id){
        return super.deleteAPI(adminAPI, `notifications/${id}`);
    }

    static getNotificationsbyID(id){
        return super.getAPI(adminAPI, `notifications/${id}`);
    }

    static getListOfNotifications(date, opts){
        return super.getAPI(adminAPI, `notifications?searchdate=${date}`, opts);
    }

    static getNotifications() {
        return super.getAPI(publicAPI, `notifications`);
    }



}