import React from 'react';

import {
    ConfirmationModal,
    LoginForm,
    TranslationMixin,
    WithCommonTranslations,
} from 'tds_shared_ui';
import {WithAdminTranslations} from "../components/hocs";
import {UnauthorizedService} from "../services";

import './LoginPage.css';

export const LoginPage = WithCommonTranslations(
    WithAdminTranslations(class extends TranslationMixin(React.Component) {
        constructor(props) {
            super(props);
            this.state = {
                user: null,
                showLoginPolicy: false,
          handleConfirmSuccess: () => {
          },
        };

        this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
        this.handleLoginFailure = this.handleLoginFailure.bind(this);
        this.handleConfirmCancel = this.handleConfirmCancel.bind(this);
      }

      handleLoginSuccess(user) {
        return new Promise((resolve, reject) => {
          if (user.role === 'Admin' || user.role === 'SuperAdmin') {
            this.setState({
              showLoginPolicy: true,
              handleConfirmSuccess: () => {
                this.props.onSuccess(user);
              },
            });
            UnauthorizedService.addAuthTokenResponseInterceptor();
            resolve(user);
          } else {
            reject(this.getTranslation('ERROR_NOT_ADMIN'));
          }
        });
      }

      handleLoginFailure(error) {
        console.log(error);
      }

      handleConfirmCancel() {
        this.setState({
          user: null,
          showLoginPolicy: false,
        });
      }

      render() {
        return (

            <div className="loginPage">
              <LoginForm
                  forgotPasswordLink="/resetpassword"
                  onLoginSuccess={this.handleLoginSuccess}
                         onLoginFailure={this.handleLoginFailure}/>
              <ConfirmationModal open={this.state.showLoginPolicy}
                                 className="loginConfirmModal"
                                 title={this.getTranslation('TITLE_GM_POLICY')}
                                 content={
                                   <div>
                                     <div
                                         dangerouslySetInnerHTML={{
                                           __html: this.getTranslation(
                                               'HTML_GM_POLICY_MSG'),
                                         }}></div>
                                     <p>
                                       <a href="https://link.gm.com/uisp"
                                          rel="noopener noreferrer"
                                          target="_blank">
                                         {this.getTranslation(
                                             'LABEL_GM_CORP_USER_POLICY')}</a>
                                     </p>
                                   </div>}
                                 okText={this.getTranslation('BUTTON_AGREE')}
                                 cancelText={this.getTranslation(
                                     'BUTTON_CANCEL')}
                                 onCancel={this.handleConfirmCancel}
                                 onSuccess={this.state.handleConfirmSuccess}/>
            </div>

        );
      }
    }));

