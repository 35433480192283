import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import {
    Alert,
    AlertMixin,
    Button,
    Header,
    TranslationMixin,
    WithCommonTranslations,
    HelperUtils,
} from 'tds_shared_ui';
import {CreateNotificationsForm} from '../components/Notifications/CreateNotificationsForm';
import NotificationsService from '../services/NotificationsService';
import {WithAdminTranslations} from '../components/hocs';

export const CreateNotificationsPage = WithAdminTranslations(
    WithCommonTranslations(
        class extends AlertMixin(TranslationMixin(React.Component)) {
            constructor(props) {
                super(props);

                this.alertRef = React.createRef();
                this.beginRef = React.createRef();
                this.endRef = React.createRef();

                this.state = {
                    loading: false,
                    beginDateErrorMsg: '',
                    endDateErrorMsg: '',
                    formValues: {
                        id: '',
                        type: '',
                        begin: '',
                        end: '',
                        regions: [],
                        audience: '',

                        notificationContents: [
                            {
                                title: '',
                                message: '',
                                languageCode: 'en',
                            }],
                    },
                    alert: {
                        display: false,
                        type: 'default',
                        message: '',
                    },
                    selectedLanguages: [],
                };

                this.validateTimes = this.validateTimes.bind(this);
                this.handleSubmit = this.handleSubmit.bind(this);
                this.handleError = this.handleError.bind(this);
                this.handleSuccess = this.handleSuccess.bind(this);
                this.handleCancel = this.handleCancel.bind(this);
                this.handleChange = this.handleChange.bind(this);
                this.handleDeleteNotification = this.handleDeleteNotification.bind(
                    this);
                this.handleDeleteContent = this.handleDeleteContent.bind(this);
                this.handleContentChange = this.handleContentChange.bind(this);
                this.handleCheckChange = this.handleCheckChange.bind(this);
                this.onValidationError = this.onValidationError.bind(this);
            }

            componentDidMount() {
                this.initializeNotification();
            }

            scrollToAlert() {
                HelperUtils.scrollToAlert(this.alertRef);
            }

            getInput(ref) {
                const dom = ReactDOM.findDOMNode(ref.current);
                return dom.querySelector('input');
            }

            onValidationError() {
                this.setState({
                    alert: {
                        display: true,
                        message: this.getTranslation('ERROR_FORM_VALIDATION'),
                        type: 'error',
                    },
                });
            };

            handleSubmit() {
                let success = this.getTranslation(
                    'MESSAGE_SUCCESS_SAVE_NOTIFICATION');
                if (!this.state.formValues.id) {
                    let saveData = this.getNotificationData(this.state.formValues);
                    this.setState({
                        loading: true,
                    });
                    NotificationsService.createNotifications(saveData)
                        .then((data) => {
                            this.initializeNotification(data);
                            this.setState({
                                formValues: data,
                                loading: false,
                            });
                            this.handleSuccess(success);
                        })
                        .catch((e) => {
                            let message = this.getTranslation(
                                'ERROR_SAVING_NOTIFICATION');
                            this.handleError(message);
                        });
                }
                else {
                    let success = this.getTranslation(
                        'MESSAGE_SUCCESS_UPDATE_NOTIFICATION');
                    let saveData = this.getNotificationData(this.state.formValues);
                    this.setState({
                        loading: true,
                    });
                    NotificationsService.updateNotifications(saveData)
                        .then((data) => {
                            this.setState({
                                formValues: data,
                                loading: false,
                            });
                            this.handleSuccess(success);
                        })
                        .catch((e) => {
                            let message = this.getTranslation(
                                'ERROR_UPDATING_NOTIFICATION');
                            this.handleError(message);
                        });
                }
            }

            validateTimes() {
                let beginInput = this.getInput(this.beginRef);
                let endInput = this.getInput(this.endRef);

                let beginDate = moment(this.state.formValues.begin);
                let endDate = moment(this.state.formValues.end);

                let beginErrorMsg = '';
                let endErrorMsg = '';

                if (beginDate.isValid() && endDate.isValid()) {
                    if (beginDate.isAfter(endDate) || beginDate.isSame(endDate)) {
                        beginErrorMsg = this.getTranslation('ERROR_START_AFTER_END');
                        endErrorMsg = this.getTranslation('ERROR_END_BEFORE_START');
                    }
                }

                beginInput.setCustomValidity(beginErrorMsg);
                endInput.setCustomValidity(endErrorMsg);

                this.setState({
                    beginDateErrorMsg: beginErrorMsg,
                    endDateErrorMsg: endErrorMsg,
                });
            }

            getNotificationData(data) {

                let {begin, end} = data;
                data.begin = moment(begin).format('YYYY-MM-DDTHH:mm:ssZ');
                data.end = moment(end).format('YYYY-MM-DDTHH:mm:ssZ');

                return data;
            }

            handleSuccess(success) {
                this.setState({
                    alert: {
                        display: true,
                        type: 'success',
                        message: success,
                    },
                }, this.scrollToAlert);
            }

            handleError(message) {
                this.setState({
                    alert: {
                        display: true,
                        type: 'error',
                        message: message,
                    },
                }, this.scrollToAlert);
            }

            handleCheckChange(languageCode, language, id, checkedValue) {
                let copyArray = this.state.formValues.notificationContents.slice();

                if (checkedValue === true) {
                    copyArray.push({
                        id: id,
                        title: '',
                        message: '',
                        languageCode: languageCode,
                        language: language,
                    });
                } else if (checkedValue === false) {
                    copyArray = copyArray.filter(
                        copyArray => copyArray.languageCode !== languageCode);
                }
                this.setState((prevState) => {
                    return {
                        formValues: {
                            ...prevState.formValues,
                            notificationContents: copyArray,
                        },
                    };
                });
            }

            initializeNotification() {
                let newData = this.props.notificationData;
                if (newData === null) {
                    newData = {};
                } else {
                    let regions = newData.regions.slice();
                    let notificationContents = newData.notificationContents.slice();
                    let selectedLanguages = this.props.selectedLanguages;
                    this.setState({
                        formValues: {
                            id: newData.id || '',
                            type: newData.type || '',
                            status: newData.status || '',
                            begin: newData.begin || '',
                            end: newData.end || '',
                            regions: regions || [],
                            audience: newData.audience || '',
                            notificationContents: notificationContents || [],
                        },
                        selectedLanguages: selectedLanguages || [],
                    });
                }
            }

            handleCancel() {
                this.props.onCancel();
            }

            handleDeleteContent(languageCode) {
                let copyArray = this.state.formValues.notificationContents.slice();
                copyArray = copyArray.filter(
                    copyArray => copyArray.language !== languageCode);

                copyArray = copyArray.filter(
                    copyArray => copyArray.language !== languageCode);

                this.setState((prevState) => {
                    return {
                        formValues: {
                            ...prevState.formValues,
                            notificationContents: copyArray,
                        },
                    };
                });

            };

            handleDeleteNotification(id) {
                NotificationsService.deleteNotifications(id).then(() => {
                    this.setState({
                        id: '',
                        type: '',
                        begin: '',
                        end: '',
                        regions: [],
                        audience: '',
                        notificationContents: [
                            {
                                title: '',
                                message: '',
                                language: 'en',
                            }],
                    });
                    let success = this.getTranslation(
                        'MESSAGE_SUCCESS_DELETE_NOTIFICATION');
                    this.props.onSuccess(success);
                    this.handleCancel();
                }).catch((e) => {
                  //Adding empty catch to prevent webpack unhandled exception msg
                });
            }

            handleChange(name, value) {
                this.setState((prevState) => {
                    let newState = {...prevState};
                    newState.formValues[name] = value;
                    return newState;
                }, () => {
                    if (name === 'begin' || name === 'end') {
                        this.validateTimes();
                    }
                });
            }

            handleContentChange(name, value, language) {
                let copyContent = this.state.formValues.notificationContents.slice();
                for (let i = 0; i < copyContent.length; i++) {

                    let newContent = copyContent[i];

                    if (newContent.language === language) {
                        newContent[name] = value;
                    }
                }
                this.setState((prevState) => {
                    return {
                        formValues: {
                            ...prevState.formValues,
                            notificationContents: copyContent,
                        },
                    };
                });
            }

            render() {
                const {formValues, alert, beginDateErrorMsg, endDateErrorMsg, loading} = this.state;
                const {onCancel} = this.props;
                let name = formValues ? formValues.notificationContents.map(e => e.language) : null;

                return (
                    <div>
                        <div>
                            <Button className="p-0" variant="link"
                                    type="button"
                                    onClick={onCancel}>
                                {this.getTranslation('BUTTON_RETURN')}</Button>
                        </div>
                        <Header level={1}>{this.getTranslation(
                            'HEADER_CREATE_PAGE')}</Header>
                        {alert.display &&
                        <Alert ref={this.alertRef} type={alert.type}>
                            {alert.message}</Alert>}

                        <p>{this.getTranslation('TEXT_CREATE_PAGE')}</p>

                        <CreateNotificationsForm
                            onError={this.handleError}
                            onSuccess={this.handleSuccess}
                            onChange={this.handleChange}
                            onContentChange={this.handleContentChange}
                            formValues={formValues}
                            beginDateErrorMsg={beginDateErrorMsg}
                            endDateErrorMsg={endDateErrorMsg}
                            onValidationError={this.onValidationError}
                            submit={this.handleSubmit}
                            onCancel={this.handleCancel}
                            onRemove={this.handleDeleteNotification}
                            onDelete={this.handleDeleteContent}
                            onSelectChange={this.handleCheckChange}
                            selectedLanguages={name}
                            loading={loading}
                            beginRef={this.beginRef}
                            endRef={this.endRef}/>

                    </div>

                );
            }

        }));