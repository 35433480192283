import {GenericAjaxService, adminAPI, privateAPI, publicAPI} from 'tds_shared_ui';

export default class UnauthorizedService extends GenericAjaxService {

  static handleUnauthorizedErrors(handler) {
    super.addResponseErrorInterceptor(adminAPI, (error) => {
      if (error.response) {
        if (401 === error.response.status) {
          handler(error.response);
        }
      }
      return Promise.reject(error);
    });
  }

  static addAuthTokenHeader(token) {
    if (token != null) {
      privateAPI.defaults.headers.common['rest-auth-token'] = token;
      adminAPI.defaults.headers.common['rest-auth-token'] = token;
      publicAPI.defaults.headers.common['rest-auth-token'] = token;
    }
  }

  static removeAuthTokenHeader() {
    privateAPI.defaults.headers.common['rest-auth-token'] = '';
    adminAPI.defaults.headers.common['rest-auth-token'] = '';
    publicAPI.defaults.headers.common['rest-auth-token'] = '';
  }

  static addAuthTokenResponseInterceptor() {
    UnauthorizedService.removeAuthTokenInterceptors();
    UnauthorizedService.authTokenResponseInterceptor = super.addResponseErrorInterceptor(
        adminAPI,
        error => {
          if (error.response) {
            if (401 === error.response.status) {
              UnauthorizedService.removeAuthTokenHeader();
            }
          }
          return Promise.reject(error);
        });
  };

  static removeAuthTokenInterceptors() {
    if (UnauthorizedService.authTokenResponseInterceptor) {
      super.removeResponseInterceptor(adminAPI,
          UnauthorizedService.authTokenResponseInterceptor);
      UnauthorizedService.authTokenResponseInterceptor = null;
    }
  }
}