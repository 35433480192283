import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import {
    Button,
    Header,
    TranslationMixin,
    AlertMixin,
    Alert,
    WithCommonTranslations, HelperUtils,
} from 'tds_shared_ui';
import {PromoCodeProperties, PromoCodeTable} from '../components/PromoCodes';
import {CreatePromoCodePage} from './CreatePromoCodePage';
import {WithAdminTranslations} from "../components/hocs";
import {PromoCodeService} from "../services";

import './PromoCodePage.css';

export const PromoCodePage = (WithAdminTranslations(WithCommonTranslations(
    class PromoCodePage extends AlertMixin(TranslationMixin(React.Component)) {

        constructor(props) {
            super(props);

            this.alertRef = React.createRef();
            this.beginRef = React.createRef();
            this.endRef = React.createRef();

            this.state = {
                alert: {
                    display: false,
                    type: 'default',
                    message: '',
                },
                showCreatePage: false,
                tableHeaders: [],
                tableAttributes: [],
                tableData: [],
                loading: false,
                promoCodeData: null,
                exportURL: '',
                formValues: {
                    regions:'',
                    codeType: '',
                    productId: '',
                    status: '',
                    code: '',
                    startDate: '',
                    endDate: '',
                },
            };
            this.handleShowCreate = this.handleShowCreate.bind(this);
            this.handleCancelCreate = this.handleCancelCreate.bind(this);
            this.handleChange = this.handleChange.bind(this);
            this.scrollToAlert = this.scrollToAlert.bind(this);
            this.handleReset = this.handleReset.bind(this);
            this.validateTimes = this.validateTimes.bind(this);
            this.onValidationError = this.onValidationError.bind(this);
            this.fetchTable = this.fetchTable.bind(this);
            this.handleError = this.handleError.bind(this);
            this.handleSearch = this.handleSearch.bind(this);
        }

        getInput(ref) {
            const dom = ReactDOM.findDOMNode(ref.current);
            return dom.querySelector('input');
        }

        scrollToAlert() {
            HelperUtils.scrollToAlert(this.alertRef);
        }

        onValidationError() {
            this.displayAlert(this.getTranslation('ERROR_FORM_VALIDATION'), 'error',
                this.scrollToAlert);
        }

        handleShowCreate() {
            this.clearError();
            this.setState({
                showCreatePage: true,
            });
        }

        handleCancelCreate() {
            this.setState({
                showCreatePage: false,
            });
        }

        handleReset() {
            this.clearError();
            this.setState({
                formValues: {
                    codeType: '',
                    status: '',
                    subType: '',
                    startDate: '',
                    endDate: '',
                },
                tableHeaders: [],
                tableData: [],
                tableAttributes: null,
                showTable: false,
            });
        }

        validateTimes() {
            let beginInput = this.getInput(this.beginRef);
            let endInput = this.getInput(this.endRef);

            let beginDate = moment(this.state.formValues.startDate);
            let endDate = moment(this.state.formValues.endDate);

            let beginErrorMsg = '';
            let endErrorMsg = '';

            if (beginDate.isValid() && endDate.isValid()) {
                if (beginDate.isAfter(endDate)) {
                    beginErrorMsg = this.getTranslation('ERROR_START_AFTER_END');
                    endErrorMsg = this.getTranslation('ERROR_END_BEFORE_START');
                }
            }

            beginInput.setCustomValidity(beginErrorMsg);
            endInput.setCustomValidity(endErrorMsg);

            this.setState({
                beginDateErrorMsg: beginErrorMsg,
                endDateErrorMsg: endErrorMsg,
            });
        }

        handleChange(name, value) {
            this.setState((prevState) => {
                let formValues = {...prevState.formValues};
                formValues[name] = value;
                if (name === 'regions') {
                    formValues.productId = null;
                    formValues.codeType = null;
                } else if (name === 'productId') {
                    formValues.codeType = null;
                }
                return {formValues: formValues};
            }, () => {
                if (name === 'startDate' || name === 'endDate') {
                    this.validateTimes();
                }
            });
        }

        handleError(e) {
            this.displayError(e, this.scrollToAlert);
        }

        getPromoCodeData(data) {
            let {startDate, endDate} = data;
            if (startDate) {
                data.startDate = moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ');
            }
            if (endDate) {
                data.endDate = moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ');
            }

            return data;
        }

        handleSearch() {
            this.clearError();
            let {
                codeType,
                productId,
                status,
                code,
                startDate,
                endDate,
            } = this.state.formValues;

            code = code ? code.toUpperCase() : '';

            if (status) {
                if (!codeType && !productId && !code && !startDate && !endDate) {
                    this.displayAlert(this.getTranslation("ERROR_NEED_ADDITIONAL_CRITERIA"), 'error', this.scrollToAlert);
                    return;
                }
            }

            this.fetchTable();
        }

        fetchTable() {
            let saveData = this.getPromoCodeData({...this.state.formValues});
            saveData.code = saveData.code ? saveData.code.toUpperCase() : '';

            let exportURL = PromoCodeService.getPromoCodeDownloadUrl(saveData);

            this.setState({
                loading: true,
                exportURL: exportURL,
                tableHeaders: [],
                tableData: [],
                tableAttributes: null,
                showTable: false,
            });

            this.clearError();
            PromoCodeService.searchPromoCode(saveData).then((data) => {
                this.setState({
                    tableHeaders: data.headers,
                    tableData: data.data,
                    tableAttributes: data.attributes,
                    loading: true,
                    showTable: true,
                });
            }).catch((e) => {
                this.displayError(e);
                this.setState({
                    tableHeaders: null,
                    tableData: null,
                    tableAttributes: null,
                    loading: false,
                    showTable: false,
                });
            }).finally(() => {
                this.setState({
                    loading: false,
                });
            });
        }

        renderCreate() {
            return <CreatePromoCodePage
                onCancel={this.handleCancelCreate}
                onConfirmVoid={this.handleOpen}
                promoCodeData={this.state.promoCodeData}/>;
        }

        renderSearch() {
            return (
                <div className="promoCodesPage">
                    <div className="clearfix">
                        {this.state.alert.display &&
                        <Alert ref={this.alertRef}
                               type={this.state.alert.type}>
                            {this.state.alert.message}</Alert>}
                        <Header level={1}>{this.getTranslation(
                            'HEADER_PROMO_CODES')}</Header>
                        <p>{this.getTranslation('MESSAGE_PROMO_CODES')}</p>

                        <div className="float-md-right">
                            <Button onClick={this.handleShowCreate}
                                    variant='primary'
                                    type='button'>
                                {this.getTranslation('BUTTON_CREATE_PROMO_CODES')}
                            </Button>
                        </div>
                    </div>

                    <Header level={3} className="itemHeader">
                        {this.getTranslation('HEADER_MANAGE_PROMO')}</Header>

                    <PromoCodeProperties
                        onChange={this.handleChange}
                        onError={this.handleError}
                        onValidationError={this.onValidationError}
                        formValues={this.state.formValues}
                        beginDateErrorMsg={this.state.beginDateErrorMsg}
                        endDateErrorMsg={this.state.endDateErrorMsg}
                        beginRef={this.beginRef}
                        endRef={this.endRef}
                        loading={this.state.loading}
                        submit={this.handleSearch}
                        onReset={this.handleReset}/>


                    {this.state.showTable &&
                    <PromoCodeTable
                        ref={this.tableRef}
                        onError={this.handleError}
                        onSetTableData={d => this.setState({tableData: d})}
                        exportURL={this.state.exportURL}
                        tableAttributes={this.state.tableAttributes}
                        tableData={this.state.tableData}
                        tableHeaders={this.state.tableHeaders}/>}
                </div>
            );
        }

        render() {
            const {showCreatePage} = this.state;

            if (showCreatePage) {
                return this.renderCreate();
            } else {
                return this.renderSearch();
            }
        }

    })));