import React from 'react';
import {
    Button,
    DatePicker, LoadingIcon,
    Select,
    TranslationMixin,
    WithCommonTranslations, WithFormValidation,
} from 'tds_shared_ui';
import {PromoCodeService} from "../../services";
import {WithAdminTranslations} from "../hocs";

import './FinancialProperties.css';


export const FinancialProperties = (WithAdminTranslations(
    WithCommonTranslations(
        WithFormValidation(class extends TranslationMixin(React.Component) {
            constructor(props) {
                super(props);


                this.state = {
                    regionOptions: [],
                    disabled: false,
                    beginDate: null,
                    endDate: null,
                };
                this.handleSelectChange = this.handleSelectChange.bind(this);
                this.fetchOptions = this.fetchOptions.bind(this);
                this.checkRegions = this.checkRegions.bind(this);
            }

            componentDidMount() {
                this.fetchOptions();
            }

            static getDerivedStateFromProps(props) {
                let beginDate = null;
                let endDate = null;
                let fv = props.formValues;
                if (fv) {
                    if (fv.startDate) {
                        beginDate = fv.startDate;
                    }
                    if (fv.endDate) {
                        endDate = fv.endDate;
                    }
                }

                return {
                    beginDate: beginDate,
                    endDate: endDate,
                };
            }

            checkRegions() {
                if (this.props.formValues.regions.length === 0) {
                    return true;
                }
            }

            fetchOptions() {
                PromoCodeService.getDefaultOptions().then((data) => {
                    this.setState({
                        regionOptions: data.regionOptionItemList,
                    });
                }).catch((e) => {
                    this.props.onError(e);
                });
            }

            handleSelectChange = (name) => (value) => {
                this.props.onChange(name, value);
            };

            getFinancialOptions() {
                let options = [
                    {
                        id: 'Sales',
                        value: 'Sales',
                        label: this.getTranslation('LABEL_SALES'),
                    },
                    {
                        id: 'Subscription',
                        value: 'Subscription',
                        label: this.getTranslation('LABEL_SUBSCRIPTION'),
                    }];

                return options;
            }

            getSalesOptions() {
                let options = [
                    {
                        id: 'All',
                        value: 'All',
                        label: this.getTranslation('LABEL_ALL'),
                    },
                    {
                        id: 'Subscription',
                        value: 'subscriptions',
                        label: this.getTranslation('LABEL_SUBSCRIPTION'),
                    },
                    {
                        id: 'Keycodes',
                        value: 'keycodes',
                        label: this.getTranslation('LABEL_KEYCODES'),
                    }];

                return options;
            }

            render() {
                const {formValues, readOnly, isValidated, onCancel, onChange, loading, beginRef, endRef,} = this.props;
                const {regionOptions, beginDate, endDate, endDateErrorMsg, beginDateErrorMsg} = this.state;
                return (
                    <div className="searchFinancialReports">
                        <div className="d-flex flex-row flex-wrap align-items-center">
                            <div className="tds-financial-select">
                                <Select
                                    label={this.getTranslation('HEADER_SELECT_FINANCIAL')}
                                    name="whichReport"
                                    id="whichReport"
                                    required={true}
                                    options={this.getFinancialOptions()}
                                    value={formValues.whichReport}
                                    readOnly={readOnly}
                                    error={isValidated && !formValues.whichReport}
                                    errorMessage={this.getTranslation(
                                        'ERROR_REQUIRED')}
                                    onChange={this.handleSelectChange('whichReport')}/>
                            </div>


                            {formValues.whichReport === 'Sales' &&
                            <div className="tds-sales-select">
                                <Select label={this.getTranslation('HEADER_SALES_TYPE')}
                                        name="reportType"
                                        id="reportType"
                                        required={true}
                                        removable={true}
                                        options={this.getSalesOptions()}
                                        value={formValues.reportType}
                                        readOnly={readOnly}
                                        error={isValidated && !formValues.reportType}
                                        errorMessage={this.getTranslation(
                                            'ERROR_REQUIRED')}
                                        onChange={this.handleSelectChange('reportType')}/>

                            </div>}

                            <div className="tds-region-select">
                                <Select label={this.getTranslation('HEADER_REGION')}
                                        name="regions"
                                        id="regions"
                                        required={true}
                                        removable={true}
                                        options={regionOptions}
                                        value={formValues.regions}
                                        readOnly={readOnly}
                                        error={isValidated && !formValues.regions}
                                        errorMessage={this.getTranslation(
                                            'ERROR_REQUIRED')}
                                        onChange={this.handleSelectChange('regions')}/>
                            </div>

                            <div className="tds-datepicker-option">
                                <div className="col-tds-datepicker">
                                    <DatePicker
                                        label={this.getTranslation('HEADER_START_DATE')}
                                        dateFormat="MM/dd/yyyy"
                                        autoComplete="off"
                                        className="tds-date-width"
                                        startDate={beginDate}
                                        endDate={endDate}
                                        ref={beginRef}
                                        invalidDateMsg={this.getTranslation(
                                            'ERROR_INVALID_DATE')}
                                        required={true}
                                        id={'startDate'}
                                        displayError={isValidated}
                                        customError={beginDateErrorMsg}
                                        onChange={(startDate) => onChange('startDate', startDate)}
                                        selected={beginDate}/>
                                </div>

                                <div className="col-tds-datepicker ml-1">
                                    <DatePicker label={this.getTranslation('HEADER_END_DATE')}
                                                dateFormat="MM/dd/yyyy"
                                                autoComplete="off"
                                                className="tds-date-width"
                                                startDate={beginDate}
                                                endDate={endDate}
                                                ref={endRef}
                                                invalidDateMsg={this.getTranslation(
                                                    'ERROR_INVALID_DATE')}
                                                required={true}
                                                id={'endDate'}
                                                displayError={isValidated}
                                                customError={endDateErrorMsg}
                                                onChange={(endDate) => onChange('endDate', endDate)}
                                                selected={endDate}/>
                                </div>
                            </div>

                            <div className="col-auto">
                                <div className="float-right">
                                    <Button onClick={onCancel}
                                            variant='link'
                                            type='button'>
                                        {this.getTranslation('BUTTON_RESET')}</Button>

                                    <Button variant='primary'
                                            disabled={loading}
                                            type='submit'>
                                        <LoadingIcon display={loading}/>
                                        {this.getTranslation('BUTTON_SEARCH')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }))));