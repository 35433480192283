import {GenericAjaxService, adminAPI} from 'tds_shared_ui';


export default class PromoCodeService extends GenericAjaxService {

    static getDefaultOptions(){
        return super.getAPI(adminAPI, `promocodes/defaultOptions`);
    }
    static getSubOptions(region){
        return super.getAPI(adminAPI, `promocodes/subscriptionOptions/?&region=${region}`);
    }
    static getSkuOptions(region, type){
        return super.getAPI(adminAPI, `promocodes/skuOptions/?&region=${region}&subscription=${type}`);
    }

    static createPromoCode(data){
        return super.postAPI(adminAPI, `promocodes`, data);
    }

    static invalidatePromoCode(data){
        return super.postAPI(adminAPI, `promocodes/invalidate`, data);
    }

    static searchPromoCode(criteria){
        let params = PromoCodeService.getPromoCodeURLParams(criteria);
        return super.getAPI(adminAPI, `reports/promoCode?${params}`);
    }

    static getPromoCodeDownloadUrl(criteria){
        let url = process.env.REACT_APP_API_HOST +
        '/api/admin/v1/reports/promoCodeCSV?' +
        PromoCodeService.getPromoCodeURLParams(criteria);
        return url;
    }

    static getCreatedPromoCodeDownloadUrl(criteria){
        let url = process.env.REACT_APP_API_HOST +
        '/api/admin/v1/reports/createdPromoCodeCSV?' +
        PromoCodeService.getPromoCodeURLParams(criteria);
    return url;
    }

    static getPromoCodeURLParams (criteria){
        let params = "";
        if(criteria.regions){
            params += "&region=" + criteria.regions
        }
        if(criteria.code){
            params += "&code=" + criteria.code
        }
        if(criteria.codeType){
            params += "&codeType=" + criteria.codeType
        }
        if(criteria.productId){
            params += "&productId=" + criteria.productId
        }
        if(criteria.startDate){
            params += "&startDate=" + criteria.startDate
        }
        if(criteria.endDate){
            params += "&endDate=" + criteria.endDate
        }
        if(criteria.status){
            params += "&status=" + criteria.status
        }
        if(criteria.reportId){
            params += "&reportId=" + criteria.reportId
        }
        return encodeURLCharacters(params);
    }

}

function encodeURLCharacters(urlString) {
    return urlString.replaceAll("+", "%2B");
}