import 'react-app-polyfill/ie11'; // For IE 11 support
import 'babel-polyfill';
import 'custom-event-polyfill';
// eslint-disable-next-line
//Do not remove captcha import used by tds-shared-ui
//DO NOT UPGRADE REACT SCRIPTS UNLESS YOU HAVE A REPLACEMENT IN MIND
import React from 'react';
import {createRoot} from "react-dom/client";
import {unregister} from './registerServiceWorker';

import App from './App';

const root = createRoot(document.getElementById("root"));
root.render(<App />);
unregister();