import React from 'react';

import {
    AlertMixin,
    WithCommonTranslations,
    WithFormValidation,
    TranslationMixin,
    Validators, Button,
    LoadingIcon,
} from 'tds_shared_ui';
import {WithAdminTranslations} from "../../hocs";

import './AddSubscriptionDetailsForm.css';

export const AddSubscriptionDetailsForm =
    WithFormValidation(WithAdminTranslations(WithCommonTranslations(
        class AddSubscriptionDetailsForm extends AlertMixin(
            TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);

            this.state = {
              sku: null,
              skuOptions: null,
              quantity: 1,
              loading: false,
              alert: {
                display: false,
                type: 'default',
                message: '',
              },
            };

            this.handleChange = this.handleChange.bind(this);
            this.onlyNumbersOnKeyPress = Validators.onlyNumbersOnKeyPress.bind(
                this);
          }

          handleChange = (value) => () => {
            this.props.onChange('sku', value);
          };

          handleChangeEvent = (label) => (e) => {
            this.props.onChange(label, e.target.value);
          };

          render() {
            const {
              product, skuOptions, onCancel, username,
            } = this.props;
            let productType = product ? product.productType : "";

            let displayQuantity = productType && productType.toLowerCase() !== 'download';

            return (
                <div className="add-sub-details-form">
                  <div className="form-group row">
                    <div className="col-sm-4 tds-label">
                      {this.getTranslation('LABEL_SUBSCRIPTION_NAME')}
                    </div>

                    <div className="col-sm-8">
                      {product && product.productName}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-4 tds-label">
                      {this.getTranslation('LABEL_LOGIN_NAME')}
                    </div>

                    <div className="col-sm-8">
                      {username}
                    </div>
                  </div>

                  <fieldset className="form-group">
                    <div className="row">
                      <legend
                          className="col-form-label col-sm-4 pt-0 tds-label">
                        {this.getTranslation('LABEL_SKU')}<span
                          className="asterisk">*</span>
                      </legend>
                      <div className="col-sm-8">
                        {skuOptions && skuOptions.map((option, i) => (
                            <div className="form-check" key={option.skuId}>
                              <input className="form-check-input"
                                     type="radio"
                                     name="radio" id={option.skuId}
                                     onChange={this.handleChange(
                                         option.skuId)}
                                     value={option.id}
                                     required={!this.props.formValues.sku}
                                     checked={this.props.formValues.sku ===
                                     option.skuId}/>
                              <label className="form-check-label"
                                     htmlFor={option.skuId}>
                                {option.name}
                              </label>
                              {i === (skuOptions.length - 1) &&
                              <div className="invalid-feedback">
                                {this.getTranslation('ERROR_REQUIRED')}
                              </div>}
                            </div>
                        ))}
                      </div>
                    </div>
                  </fieldset>

                  {displayQuantity &&
                  <div className="form-group row">
                    <label htmlFor="quantity"
                           className="col-sm-4 col-form-label tds-label">
                      {this.getTranslation('LABEL_QUANTITY')}<span
                        className="asterisk">*</span></label>
                    <div className="col-sm-8">
                      <input type="text"
                             className="form-control w-auto"
                             size="2"
                             required
                             id="quantity"
                             value={this.props.formValues.quantity}
                             onChange={this.handleChangeEvent('quantity')}
                             onKeyPress={this.onlyNumbersOnKeyPress}
                             maxLength="2"
                             pattern="^[0-9]{1,2}$"/>
                      <div className="invalid-feedback">
                        {this.getTranslation('ERROR_REQUIRED')}
                      </div>
                    </div>
                  </div>}

                  <div className="form-group row">
                    <label htmlFor="comments"
                           className="col-sm-4 col-form-label tds-label">
                      {this.getTranslation('TITLE_COMMENTS')}</label>
                    <div className="col-sm-8">
                      <textarea
                          className="form-control w-100"
                          id="comments"
                          placeholder={this.getTranslation(
                              'MESSAGE_OPTIONAL_COMMENT')}
                          value={this.props.formValues.comments}
                          onChange={this.handleChangeEvent('comments')}
                          maxLength="200"/>
                    </div>
                  </div>

                  <div className="float-right modal-btns">
                    <Button type="button"
                            variant="link"
                            onClick={onCancel}>{this.getTranslation(
                        'BUTTON_CANCEL')}</Button>

                    <Button type="submit"
                            disabled={this.props.loading}
                            variant="primary">{this.getTranslation(
                        'BUTTON_ADD')} <LoadingIcon
                        display={this.props.loading}/>
                    </Button>
                  </div>
                </div>
            );
          }
        })));