import React from 'react';

import {
    Button,
    Header,
    Modal,
    TranslationMixin, WithCommonTranslations,
} from 'tds_shared_ui';
import {WithAdminTranslations} from "../hocs";
import {ReportsTable} from './ReportsTable';

export const FinancialReportModal =
    WithAdminTranslations(WithCommonTranslations(
        class extends TranslationMixin(React.Component) {
            constructor(props) {
                super(props);
                this.handleClose = this.handleClose.bind(this);
            }

            handleClose() {
                this.props.onClose();
            }

            render() {
                const {productName, showModal, headers, exportUrl, tableData, attributes} = this.props;

                return (
                    <Modal header={
                        <Header level={1}>
                            {this.getTranslation(
                                'HEADER_SALES_REPORT')} - {productName}
                        </Header>}
                           onClose={this.handleClose}
                           open={showModal}
                           onBackdropClick={this.handleClose}>

                        <div>
                            <ReportsTable
                                headers={headers}
                                attributes={attributes}
                                exportUrl={exportUrl}
                                tableData={tableData}/>

                            <div className="clearfix mt-4">
                                <Button variant="primary"
                                        className="float-right"
                                        onClick={this.handleClose}>
                                    {this.getTranslation('BUTTON_CLOSE')}</Button>
                            </div>
                        </div>
                    </Modal>
                );
            }
        },
    ));