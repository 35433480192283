import React from 'react';
import {OrdersService} from "../../../services";
import {
    Alert,
    AlertMixin, Button,
    LoadingIcon,
    Table,
    TranslationMixin,
    WithCommonTranslations,
} from 'tds_shared_ui';
import {OrderInvoiceModal} from './OrderInvoiceModal';
import {OrdersSearch} from './OrdersSearch';

export const Orders = WithCommonTranslations(
    class Orders extends AlertMixin(TranslationMixin(React.Component)) {
      constructor(props) {
        super(props);

        this.cancelToken = OrdersService.getCancelToken().source();

        this.state = {
          ordersData: null,
          orderDetails: null,
          showOrderDetails: false,
          loading: false,
          searching: false,
          formValues: {
            orderNumber: '',
            beginDate: null,
            endDate: null,
            productType: '',
          },
          alert: {
            display: false,
            type: 'default',
            message: '',
          },
        };

        this.handleCloseOrderDetails = this.handleCloseOrderDetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClear = this.handleClear.bind(this);
      }

      componentDidMount() {
        if (this.props.username){
          this.init();
        }
      }

      componentDidUpdate(prevProps){
        if (prevProps.username !== this.props.username){
          this.init();
        }
      }

      componentWillUnmount() {
        if (this.cancelToken) {
          this.cancelToken.cancel({unmounted: true});
        }
      }

      init(){
        this.setState({loading: true});
        this.search({username: this.props.username})
            .then(() => {
              this.setState({loading: false});
            }).catch(e => {
          if (!e.unmounted) {
            this.setState({loading: false});
          }
        });
      }

      handleViewRow = (rowData) => () => {
        return OrdersService.get(rowData.orderNumber).then(data => {
          this.setState({
            orderDetails: data,
            showOrderDetails: true,
          });
        }).catch(e => this.displayError(e));
      };

      handleCloseOrderDetails() {
        this.setState({
          showOrderDetails: false,
        });
      }

      handleChange(name, value, callback) {
        this.setState((prevState) => {
          let newState = {};
          let formValues = Object.assign({}, prevState.formValues);
          formValues[name] = value;
          newState.formValues = formValues;
          return newState;
        }, callback);
      }

      handleSearch() {
        let formValues = Object.assign({}, this.state.formValues);
        formValues.username = this.props.username;
        this.search(formValues);
      }

      handleClear() {
        this.clearError();
        this.setState({
          formValues: {
            orderNumber: '',
            beginDate: null,
            endDate: null,
            productType: '',
          },
        });
      }

      search(formValues) {
        this.clearError();
        this.setState({searching: true, ordersData: null});

        return OrdersService.search(formValues,
            {cancelToken: this.cancelToken.token}).then((data) => {
          this.setState({
            ordersData: data,
            searching: false,
          });
        }).catch(e => {
          if (!e.unmounted) {
            this.displayError(e);
            this.setState({
              searching: false,
            });
          }
        });
      }

      getColumns() {
        return [
          {
            header: this.getTranslation('LABEL_ORDER_NUMBER'),
            accessor: 'orderNumber',
          }, {
            header: this.getTranslation('LABEL_ORDER_TYPE'),
            accessor: 'orderType',
          }, {
            header: this.getTranslation('LABEL_ORDER_STATUS'),
            accessor: 'status',
          }, {
            header: this.getTranslation('LABEL_ORDER_DATE'),
            accessor: 'orderDate',
          }, {
            header: this.getTranslation('LABEL_ORDER_TOTAL'),
            accessor: 'grandTotal',
            render: (rowData) => {
              if (rowData.grandTotalAfterRefunds){
                return (
                    <React.Fragment>
                      <s>{rowData.grandTotal}</s> {rowData.grandTotalAfterRefunds}
                    </React.Fragment>
                )
              } else {
                return rowData.grandTotal;
              }
            }
          }, {
            header: this.getTranslation('LABEL_ACTIONS'),
            accessor: 'actions',
            render: (rowData) => {
              return (
                  <Button variant="link" type="button"
                          onClick={this.handleViewRow(rowData)}>
                    {this.getTranslation('LABEL_VIEW')}
                  </Button>
              );
            },
          }];
      }

      render() {
        const {
          ordersData, loading, orderDetails, showOrderDetails,
          formValues, searching,
        } = this.state;
        const columns = this.getColumns();

        return (
            <div>
              {this.state.alert.display &&
              <Alert ref={this.alertRef}
                     type={this.state.alert.type}>{this.state.alert.message}
              </Alert>}

              <OrdersSearch formValues={formValues}
                            loading={searching}
                            onClear={this.handleClear}
                            onChange={this.handleChange}
                            onSubmit={this.handleSearch}/>

              <div className="mt-2">
                <LoadingIcon display={loading}
                             text={this.getTranslation('MESSAGE_LOADING')}/>
              </div>

              {!searching && !loading &&
              <Table columns={columns} rows={ordersData}
                     filterId="ordersTable"/>
              }

              {showOrderDetails &&
              <OrderInvoiceModal open={showOrderDetails}
                                 orderDetails={orderDetails}
                                 onClose={this.handleCloseOrderDetails}/>}
            </div>
        );
      }
    });
