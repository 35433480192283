import React from 'react';

import {
    AlertMixin,
    Button,
    Header,
    Modal,
    WithCommonTranslations,
    TranslationMixin, Alert,
} from 'tds_shared_ui';
import {WithAdminTranslations} from "../../hocs";

import './AddSubscriptionModal.css';

export const AddSubscriptionModal =
    WithAdminTranslations(WithCommonTranslations(
        class AddSubscriptionModal extends AlertMixin(
            TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);

            this.state = {
              selectedValue: null,
              alert: {
                display: false,
                type: 'default',
                message: '',
              },
            };
            this.handleSuccess = this.handleSuccess.bind(this);
            this.handleCancel = this.handleCancel.bind(this);
          }

          componentDidUpdate(prevProps) {
            //reset values when closed
            if (prevProps.open !== this.props.open) {
              this.setState({selectedValue: null});
            }
          }

          handleCancel() {
            this.clearError();
            this.props.onCancel();
          }

          handleChange = (value) => () => {
            this.clearError();
            this.setState({selectedValue: value});
          };

          handleSuccess() {
            this.clearError();
            if (this.state.selectedValue) {
              return this.props.onSuccess(this.state.selectedValue);
            } else {
              this.displayAlert(this.getTranslation('ERROR_SELECT_SUB'),
                  'error');
            }
          }

          render() {
            const {
              open, skuOptions
            } = this.props;

            return (
                <Modal header={<Header level={1}>
                  {this.getTranslation('TITLE_ADD_SUBSCRIPTION')}</Header>}
                       className="add-sub-modal"
                       open={open}>

                  <div className="modal-container">
                    {this.state.alert.display &&
                    <Alert ref={this.alertRef}
                           type={this.state.alert.type}>{this.state.alert.message}</Alert>}

                    <p>{this.getTranslation('MESSAGE_ADD_SUB_INSTRUCTIONS')}</p>

                    {skuOptions && skuOptions.map((option) => (
                        <div className="form-check" key={option.id}>
                          <input className="form-check-input" type="radio"
                                 name="radio" id={option.id}
                                 onChange={this.handleChange(option)}
                                 value={option.id}
                                 checked={this.state.selectedValue ===
                                 option}/>
                          <label className="form-check-label"
                                 htmlFor={option.id}>
                            {option.productName}
                          </label>
                        </div>
                    ))}

                    <div className="float-right modal-btns">
                      <Button type="button"
                              variant="link"
                              onClick={this.handleCancel}>{this.getTranslation(
                          'BUTTON_CANCEL')}</Button>

                      {skuOptions &&
                      <Button type="submit"
                              variant="primary"
                              onClick={this.handleSuccess}>{this.getTranslation(
                          'BUTTON_NEXT')}</Button>
                      }
                    </div>
                  </div>
                </Modal>
            );
          }
        }));