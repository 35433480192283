import React from 'react';
import {Navigate, NavLink, Route, Routes,} from 'react-router-dom';

import {
    Alert,
    AlertMixin,
    Header,
    LoadingIcon,
    TranslationMixin,
    WithCommonTranslations,
    WithUserTranslations,
    withRouter,
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../hocs';
import {Profile} from './Profile';
import {Orders} from './orders/Orders';
import {Subscriptions} from './subscriptions/Subscriptions';
import {UserCard} from './UserCard';
import {Comments} from './Comments';
import {AdminUserService} from "../../services";

import './ManageUser.css';

export const ManageUser = withRouter(
    WithCommonTranslations(WithAdminTranslations(
        WithUserTranslations(
            class ManageUser extends AlertMixin(
                TranslationMixin(React.Component)) {

              constructor(props) {
                super(props);

                this.cancelToken = AdminUserService.getCancelToken().source();

                this.state = {
                  username: null,
                  user: null,
                  countries: null,
                  countryStateMap: null,
                  roles: null,
                  languages: null,
                  regionData: null,
                  loading: false,
                  alert: {
                    display: false,
                    type: 'default',
                    message: '',
                  },
                };

                this.handleUpdateUser = this.handleUpdateUser.bind(this);
              }

              componentDidMount() {
                let {location} = this.props;
                let username = null;
                if (location.state) {
                  username = location.state.user.username;
                  this.setState({
                    user: location.state.user,
                    username: username,
                  });
                } else {
                  const queryString = 'username=';
                  let query = location.search;
                  let rest = query.substring(
                      query.indexOf(queryString) + queryString.length);
                  username = rest.split('&')[0];
                  this.setState({
                    username: username,
                  });
                  this.fetchUserData(username);
                }

                AdminUserService.getOptions(username).then((data) => {
                  this.setState({
                    roles: data.roleOptions,
                    languages: data.languageOptions,
                    regionData: data.region
                  });
                  this.setupCountryData(data.region.countries);
                }).catch((e) => {
                  //Adding empty catch to prevent webpack unhandled exception msg
                });
              }

              componentWillUnmount() {
                if (this.cancelToken) {
                  this.cancelToken.cancel({unmounted: true});
                }
              }

              handleUpdateUser(user) {
                this.setState({
                  user: user,
                });
              }

              fetchUserData(username) {
                this.setState({loading: true});
                AdminUserService.get(username,
                    {cancelToken: this.cancelToken.token},
                    Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .then(user => {
                  this.setState({
                    user: user,
                    loading: false,
                  });
                }).catch(e => {
                  if (!e.unmounted) {
                    this.displayError(e);
                    this.setState({loading: false});
                  }
                });
              }

              setupCountryData(data) {
                let countries = [];
                let countryStateMap = {};
                for (let country of data) {
                  let code = country.countryCode;
                  countries.push({label: country.name, value: code});
                  countryStateMap[code] = this.getStates(country.states);
                }

                this.setState({
                  countries: countries,
                  countryStateMap: countryStateMap,
                });
              }

              getStates(data) {
                let states = [];
                for (let state of data) {
                  states.push({label: state.name, value: state.stateCode});
                }
                return states.sort(function(a, b) {
                  if (a.label < b.label) {
                    return -1;
                  }

                  if (a.label > b.label) {
                    return 1;
                  }

                  return 0;
                });
              }

              render() {
                const {location} = this.props;
                const {user, username, loading, alert} = this.state;

                if (alert.display){
                  return (
                      <div className="manageUser">
                        {this.state.alert.display &&
                        <Alert ref={this.alertRef}
                               type={this.state.alert.type}>
                          {this.state.alert.message}</Alert>}
                      </div>
                  )
                }

                return (
                    <div className="manageUser">

                      <LoadingIcon display={loading} text={this.getTranslation(
                          'MESSAGE_LOADING')}/>

                      <div className="row user-info">
                        <div className="col-auto mr-auto">
                          {user &&
                          <React.Fragment>
                            <Header level={2}>{this.getTranslation(
                                'TITLE_MANAGE_USER')}</Header>
                            <UserCard profileUser={user}
                                      roles={this.state.roles}
                                      onUpdate={this.handleUpdateUser}/>
                          </React.Fragment>
                          }
                        </div>
                        <div className="col-sm-6">
                          <Comments username={username}/>
                        </div>
                      </div>

                      <ul className="tds-tabs">
                        <li className="tds-tab">
                          <NavLink to={{
                            pathname: '/admin/user/profile',
                            search: location.search,
                          }} className={(navData) => navData.isActive ? "active" : "none"} style={{textDecoration: 'none'}}>
                            {this.getTranslation('LABEL_PROFILE')}</NavLink>
                        </li>
                        <li className="tds-tab">
                          <NavLink to={{
                            pathname: '/admin/user/subscriptions',
                            search: location.search,
                          }} className={(navData) => navData.isActive ? "active" : "none"} style={{textDecoration: 'none'}}>
                            {this.getTranslation(
                                'LABEL_SUBSCRIPTIONS')}</NavLink>
                        </li>
                        <li className="tds-tab">
                          <NavLink to={{
                            pathname: '/admin/user/orders',
                            search: location.search,
                          }} className={(navData) => navData.isActive ? "active" : "none"} style={{textDecoration: 'none'}}>
                            {this.getTranslation(
                                'LABEL_ORDER_HISTORY')}</NavLink>
                        </li>
                      </ul>

                      <Routes>
                        <Route path="/" element={
                          <Navigate to={'/admin/user/profile' +
                            this.props.location.search} replace={true}/>
                        }/>

                        <Route path="/profile" element={
                          <React.Fragment>
                            {!this.state.countries || !user ?
                              <div>
                                <LoadingIcon
                                  display={true}></LoadingIcon>&nbsp;
                                {this.getTranslation('MESSAGE_LOADING')}
                              </div>
                              :
                              <Profile profileUser={user}
                                       onUpdate={this.handleUpdateUser}
                                       languages={this.state.languages}
                                       regionData={this.state.regionData}
                                       countries={this.state.countries}
                                       countryStateMap={this.state.countryStateMap}/>
                            }
                          </React.Fragment>
                        }/>
                        <Route path="/subscriptions"
                               element={
                                 <React.Fragment>
                                   { !user ?
                                     <div>
                                       <LoadingIcon
                                         display={true}></LoadingIcon>&nbsp;
                                       {this.getTranslation('MESSAGE_LOADING')}
                                     </div>
                                     :
                                     <Subscriptions username={username}
                                                    profileUser={user}
                                                    onUpdate={this.handleUpdateUser}/>
                                   }
                                 </React.Fragment>
                               }/>
                        <Route path="/orders" element={
                          <Orders username={username}/>
                        }/>
                      </Routes>

                    </div>
                );
              }
            }))));