import React from "react";
import {Button, Header, Alert, TranslationMixin,WithCommonTranslations,withRouter,} from 'tds_shared_ui'
import {NotificationAdminItem} from "./NotificationAdminItem";
import './NotificationAdminIcon.css';
import {WithUser} from "../hocs";

export const NotificationAdminList = WithUser(withRouter(WithCommonTranslations(
    class extends TranslationMixin(React.Component) {
        constructor(props) {
            super(props);
            this.state = {
                alert: {
                    display: false,
                    type: 'default',
                    message: '',
                }
            };

            this.handleMarkAsRead = this.handleMarkAsRead.bind(this);
        }


        handleMarkAsRead(){
            this.props.onClick();
        }

        render() {
            const {alert} = this.state;
            const {notificationData,readOnly, markAsRead} = this.props;
            let smallArray = notificationData.length > 0 ? notificationData.slice() : [];
            let RenderItem = readOnly && NotificationAdminItem;

            return (
                <div className="cart-list notification-list-page">
                    {alert.display &&
                    <Alert ref={this.alertRef}
                           type={alert.type}
                           className="mt-3">
                        {alert.message}
                    </Alert>}


                    <Header level={2}>{this.getTranslation('HEADER_NOTIFICATION_CENTER')}</Header>
                    {markAsRead === false && notificationData.length > 0 &&
                    <div className="clearfix">
                        <Button className="btn-mark-read float-right"
                                onClick={this.handleMarkAsRead}
                                type="button"
                                variant="link">
                            {this.getTranslation('BUTTON_MARK_AS_READ')}
                        </Button>
                    </div>}

                    {notificationData.length > 0 ?
                        <div className="notification-list">
                            {smallArray.map((notification, i) => <RenderItem item={notification} key={i} onRemove={this.handleRemove}/>)}
                        </div> : <strong className="text-center">{this.getTranslation('MESSAGE_NO_NEW_NOTIFICATIONS')}</strong>}

                </div>

            )
        }
    })));