import React from 'react';

import {
    Button,
    DatePicker, FormInput, LoadingIcon,
    Select,
    TranslationMixin,
    WithCommonTranslations, WithFormValidation,
} from 'tds_shared_ui';
import {PromoCodeService} from "../../services";
import {WithAdminTranslations} from '../hocs';
import {promoCodeUtilities} from './utility'

import './PromoCodeProperties.css';

export const PromoCodeProperties = WithFormValidation(WithAdminTranslations(
    WithCommonTranslations(
        class PromoCodeProperties extends TranslationMixin(React.Component) {
            constructor(props) {
                super(props);

                this.state = {
                    regionOptions: [],
                    statusOptions: [],
                    typeLoading: false,
                    skuLoading: false,
                    subOptions: [],
                    skuOptions: [],
                    beginDate: null,
                    endDate: null,
                };
                this.handleSelectChange = this.handleSelectChange.bind(this);
                this.handleInputChange = this.handleInputChange.bind(this);
                this.handleRegionChange = this.handleRegionChange.bind(this);
                this.handleProductIdChange = this.handleProductIdChange.bind(this);
                this.handleCodeTypeChange = this.handleCodeTypeChange.bind(this);
            }

            componentDidMount() {
                PromoCodeService.getDefaultOptions().then((data) => {
                    this.setState({
                        regionOptions: data.regionOptionItemList,
                        statusOptions: data.statusOptionItemList,
                    });
                }).catch((e) => {
                  //Adding empty catch to prevent webpack unhandled exception msg
                });
            }

            static getDerivedStateFromProps(props) {
                let beginDate = null;
                let endDate = null;
                let fv = props.formValues;
                if (fv) {
                    if (fv.startDate) {
                        beginDate = fv.startDate;
                    }
                    if (fv.endDate) {
                        endDate = fv.endDate;
                    }
                }

                return {
                    beginDate: beginDate,
                    endDate: endDate,
                };
            }

            handleRegionChange(value) {
                this.setState({
                    subOptions: [],
                    skuOptions: [],
                });
                if (value) {
                    this.setState({
                        skuLoading: true,
                    });
                    PromoCodeService.getSubOptions(value)
                        .then((data) => {
                            if(this.props.formValues.regions === 'AU'){
                                this.setState({
                                    subOptions: promoCodeUtilities.removeUnusedSubs(data),

                                });
                            } else {
                                this.setState({
                                    subOptions: data,
                                });
                            }
                        })
                        .catch(e => this.props.onError(e))
                        .finally(() => {
                            this.setState({
                                skuLoading: false,
                            });
                        });
                }
                this.props.onChange('regions', value);
            }


            handleProductIdChange(value) {
                this.setState({
                    skuOptions: [],
                });
                if (value) {
                    this.setState({
                        typeLoading: true,
                    });
                    PromoCodeService.getSkuOptions(this.props.formValues.regions,
                        value)
                        .then((data) => {
                            this.setState({
                                skuOptions: data,
                            });

                        })
                        .catch(e => this.props.onError(e))
                        .finally(() => {
                            this.setState({
                                typeLoading: false,
                            });
                        });
                }
                this.props.onChange('productId', value);
            }

            handleCodeTypeChange(value) {
                let skuOption = this.state.skuOptions.filter(
                    obj => obj.value === value);
                if (skuOption.length > 0) {
                    this.setState({
                        maxDiscount: skuOption[0].price,
                    });
                }
                this.props.onChange('codeType', value);
            }

            handleInputChange = (event) => {
                const target = event.target;
                const value = target.type === 'checkbox' ?
                    target.checked :
                    target.value;
                const name = target.name;

                this.props.onChange(name, value);
            };

            handleSelectChange = (name) => (value) => {
                this.props.onChange(name, value);
            };

            render() {
                const {subOptions, skuOptions, regionOptions, skuLoading, typeLoading, statusOptions, beginDate, endDate, beginDateErrorMsg, endDateErrorMsg} = this.state;
                const {isValidated, formValues, loading, onChange, onReset, beginRef, endRef} = this.props;
                return (
                    <div className="searchPromoCode">
                        <div className="d-flex flex-row flex-wrap align-items-center">

                            <div className="tds-promo-code">
                                <FormInput
                                    label={this.getTranslation('HEADER_PROMO_CODE')}
                                    value={formValues.code ? formValues.code.toUpperCase() : ''}
                                    name="code"
                                    id="code"
                                    maxLength={16}
                                    displayError={isValidated}
                                    onChange={this.handleInputChange}/>
                            </div>

                            <div className="tds-promo-select">
                                <Select label={this.getTranslation('HEADER_REGION')}
                                        name="regions"
                                        id="regions"
                                        removable={true}
                                        options={regionOptions}
                                        value={formValues.regions}
                                        onChange={this.handleRegionChange}/>

                            </div>

                            <div className="tds-promo-select">
                                <Select label={
                                    <React.Fragment>
                                        {this.getTranslation('HEADER_SUBSCRIPTION_TYPE')}
                                        &nbsp;<LoadingIcon display={skuLoading}/>
                                    </React.Fragment>}
                                        name="productId"
                                        id="productId"
                                        readOnly={!subOptions || subOptions.length === 0}
                                        removable={true}
                                        options={subOptions}
                                        value={formValues.productId}
                                        onChange={this.handleProductIdChange}/>
                            </div>

                            <div className="tds-promo-sku-select">
                                <Select label={
                                    <React.Fragment>
                                        {this.getTranslation('HEADER_SKU_TYPE')}
                                        &nbsp;<LoadingIcon display={typeLoading}/>
                                    </React.Fragment>}
                                        name="codeType"
                                        id="codeType"
                                        removable={true}
                                        options={skuOptions}
                                        readOnly={!skuOptions || skuOptions.length === 0}
                                        value={formValues.codeType}
                                         onChange={this.handleCodeTypeChange}/>
                            </div>

                            <div className="tds-promo-select">
                                <Select label={this.getTranslation('HEADER_STATUS')}
                                        name="status"
                                        id="status"
                                        removable={true}
                                        options={statusOptions}
                                        value={formValues.status}
                                        onChange={this.handleSelectChange('status')}/>
                            </div>

                            <div className="tds-datepicker-option">
                                <div className="col-tds-datepicker">
                                    <DatePicker
                                        label={this.getTranslation('HEADER_START_DATE')}
                                        dateFormat="MM/dd/yyyy"
                                        autoComplete="off"
                                        className="tds-date-width"
                                        startDate={beginDate}
                                        endDate={endDate}
                                        ref={beginRef}
                                        invalidDateMsg={this.getTranslation('ERROR_INVALID_DATE')}
                                        id={'startDate'}
                                        required={endDate}
                                        displayError={isValidated}
                                        customError={beginDateErrorMsg}
                                        onChange={(startDate) => onChange('startDate', startDate)}
                                        selected={beginDate}/>
                                </div>

                                <div className="col-tds-datepicker">
                                    <DatePicker label={this.getTranslation('HEADER_END_DATE')}
                                                dateFormat="MM/dd/yyyy"
                                                autoComplete="off"
                                                className="tds-date-width"
                                                startDate={beginDate}
                                                endDate={endDate}
                                                ref={endRef}
                                                required={beginDate}
                                                invalidDateMsg={this.getTranslation('ERROR_INVALID_DATE')}
                                                id={'endDate'}
                                                displayError={isValidated}
                                                customError={endDateErrorMsg}
                                                onChange={(endDate) => onChange('endDate', endDate)}
                                                selected={endDate}/>
                                </div>
                            </div>

                            <div className="col-auto">
                                <div className="float-right">
                                    <Button onClick={onReset}
                                            variant='link'
                                            type='button'>{this.getTranslation('BUTTON_RESET')} </Button>

                                    <Button variant='primary'
                                            disabled={loading}
                                            type='submit'>
                                        <LoadingIcon display={loading}/>
                                        {this.getTranslation('BUTTON_SEARCH')} </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        })));