import React from "react";
import {
    Alert,
    TranslationMixin,
    WithCommonTranslations,
} from 'tds_shared_ui';
import {WithUser} from "../hocs";


export const NotificationAdminAlerts = WithUser(WithCommonTranslations(
    class extends TranslationMixin(React.Component) {
        constructor(props) {
            super(props);
            this.state = {
                showNotification: false,
                loading: false,
                displayAlert: false,
            };
            this.handleDismiss = this.handleDismiss.bind(this);
        }

        componentDidMount() {
            this.setState({
                displayAlert: true,
            })
        }


        handleDismiss(notification) {
            this.props.onDismiss(notification);
        }


        render() {
            const {displayAlert} = this.state;
            const notificationData = this.props.notificationData.length > 0 ? this.props.notificationData.filter(data => data.type !== "info") : [];
            return (
                <React.Fragment>
                    {notificationData.filter(data => data.dismissed !== true).map((notification) =>
                        <Alert ref={this.alertRef}
                               key={notification.id}
                               type={notification.type}
                               display={displayAlert}
                               dismissible={true}
                               onDismiss={() => this.handleDismiss(notification)}
                               dismissComponent={<button type="button" onClick={this.onDismiss}
                               ><i className="fa fa-times"></i></button>}>
                            <strong>{notification.title} </strong> {notification.message}
                        </Alert>)}
                </React.Fragment>
            )
        }
    }));