import React from 'react';

import {TranslationService} from 'tds_shared_ui';

const Context = React.createContext();

export class AdminTranslationsProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminTranslations: null
    }
  }

  componentDidMount(){
    this.fetchTranslations(this.props.locale)
  }

  componentDidUpdate(prevProps){
    if (prevProps.locale !== this.props.locale){
      this.fetchTranslations(this.props.locale);
    }
  }

  fetchTranslations(locale){
    TranslationService.getBundle("admin", locale).then((data) => {
      this.setState({adminTranslations: data});
    }).catch((e) => {
      console.log(e);
    });
  }

  render(){
    return (
        <Context.Provider value={this.state}>
          {this.props.children}
        </Context.Provider>
    )
  }
}

export const AdminTranslationsConsumer = Context.Consumer;