import React from 'react';
import {
    Button,
    LoadingIcon,
    TranslationMixin,
    WithCommonTranslations,
    WithFormValidation,
} from "tds_shared_ui";
import {WithAdminTranslations} from "../hocs";

import './AdminStatusProperties.css'

export const AdminStatusProperties = WithAdminTranslations(WithFormValidation(
    WithCommonTranslations(class extends TranslationMixin(React.Component){
        constructor(props) {
            super(props);
        }

        render() {
            const {loading, onCancel} = this.props;

            return(
                <div className="adminStatusSearch">
                    <div className="d-flex flex-row flex-wrap align-items-center">
                        <div className="col-auto">
                            <div className="float-left">
                                <Button onClick={onCancel}
                                        variant='link'
                                        type='button'>
                                    {this.getTranslation('BUTTON_RESET')}
                                </Button>

                                <Button variant='primary'
                                        disabled={loading}
                                        type='submit'>
                                    <LoadingIcon display={loading}/>
                                    {this.getTranslation('BUTTON_SEARCH')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    })
));