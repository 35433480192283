import {GenericAjaxService, adminAPI} from 'tds_shared_ui';

export default class OrdersService extends GenericAjaxService {

  static search(formValues, opts) {
    return super.postAPI(adminAPI, `orders/search`, formValues, opts);
  }

  static get(orderNumber, opts){
    return super.getAPI(adminAPI, `orders/${orderNumber}`, opts);
  }

  static prepareRefund(data, opts){
    return super.postAPI(adminAPI, `orders/prepareRefund`, data, opts)
  }

  static processRefund(orderNumber, isSendEmailChecked, comment, opts){
    return super.postAPI(adminAPI, `orders/refund?orderId=${orderNumber}&sendEmail=${isSendEmailChecked}`, {'comment': comment}, opts);
  }
}