import React from 'react';
import {AdminTranslationsConsumer} from '../../context';

export const WithAdminTranslations = WrappedComponent => {
  class WithAdminTranslations extends React.Component {
    render() {
      return (
          <AdminTranslationsConsumer>
            {({adminTranslations}) => (
                <WrappedComponent
                    {...this.props}
                    translations={{...this.props.translations, ...adminTranslations}}
                />
            )}
          </AdminTranslationsConsumer>
      );
    }
  };

  WithAdminTranslations.displayName = `WithAdminTranslations(${getDisplayName(
      WrappedComponent)})`;

  return WithAdminTranslations;

};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}