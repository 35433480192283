import React from 'react';
import {
  Button, LoadingIcon,
  Select,
  TranslationMixin,
  WithCommonTranslations,
  WithFormValidation,
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../hocs';
import {AuditLogs} from '../user/auditlog/AuditLog';

import './SelectRegion.css';

export const SelectRegion = WithFormValidation(
    WithAdminTranslations(WithCommonTranslations(
        class SelectRegion extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);

            this.state = {
              regions: [],
              selectedRegion: '',
              displayAudit: false,
            };

            this.handleRegionChange = this.handleRegionChange.bind(this);
            this.handleSelect = this.handleSelect.bind(this);
            this.handleCancelViewAudit = this.handleCancelViewAudit.bind(this);
            this.showAudit = this.showAudit.bind(this);
          }

          handleRegionChange = (name) => (value) => {
            this.setState({selectedRegion: value});
            this.props.onChange(name, value);
          };

          handleSelect() {
            //select the region and send that info to right place
            return this.props.getSettings(this.state.selectedRegion);
          }

          showAudit() {
            this.setState({
              displayAudit: true,
            });
          }

          handleCancelViewAudit() {
            this.setState({
              displayAudit: false,
            });
          }

          render() {
            const {regions, selectedRegion, readOnly, isValidated, loading} = this.props;
            return (
                <div className="regionSelect pt-3">
                  <div className="d-flex flex-row flex-wrap align-items-center">
                    <div className="col-region">
                      <Select options={regions}
                              id='selectedRegion'
                              name='regionCode'
                              required={true}
                              label={
                                <React.Fragment>
                                  {this.getTranslation('HEADER_REGION')}
                                  &nbsp;<LoadingIcon display={loading}/>
                                </React.Fragment>}
                              value={selectedRegion}
                              readOnly={readOnly || loading}
                              displayError={isValidated}
                              onChange={this.handleRegionChange('regionCode')}/>
                    </div>
                    <div className="col-auto">
                      <div className="float-right">
                        <Button type="submit" variant="primary"
                                onClick={this.handleSelect}>
                          {this.getTranslation('BUTTON_SELECT')}
                        </Button>
                      </div>
                    </div>
                    <div>
                      <Button type="button" variant="link"
                              onClick={this.showAudit}>{this.getTranslation(
                          'BUTTON_AUDIT_LOG')}</Button>

                    </div>

                  </div>
                  <AuditLogs
                      open={this.state.displayAudit}
                      username="regionconfigaudituser"
                      onCancel={this.handleCancelViewAudit}/>
                </div>
            );
          }
        })));