import {adminAPI, GenericAjaxService} from 'tds_shared_ui';

export default class ReportsService extends GenericAjaxService {

  static getRegionOptions() {
    return super.getAPI(adminAPI, `reports/regionOptions/`);
  }

  static getDisabledUsers(region) {
      let params = '';
      if (region) {
          params += 'region=' + region;
      }
    return super.getAPI(adminAPI, `reports/disableUsers?${params}`);
  }


  static getWatchedUsers(region) {
      let params = '';
      if (region) {
          params += 'region=' + region;
      }
    return super.getAPI(adminAPI, `reports/watchedUsers?${params}`);
  }

  static getAdminUsers(){
    return super.getAPI(adminAPI, 'reports/adminUsers')
  }

  static getDisabledUsersCSVUrl(region) {
      let params = '';
      if (region) {
          params += 'region=' + region;
      }
    return process.env.REACT_APP_API_HOST +
        '/api/admin/v1/reports/disableUsersCSV?' + params;
  }

  static getWatchedUsersCSVUrl(region) {
      let params = '';
      if (region) {
          params += 'region=' + region;
      }
    return process.env.REACT_APP_API_HOST +
        '/api/admin/v1/reports/watchedUsersCSV?'+ params;
  }

  static getAdminUsersCSVUrl(){
    return process.env.REACT_APP_API_HOST + '/api/admin/v1/reports/adminUsersCSV'
  }

  static getAVGKeyCodeReportCSVUrl(criteria) {
    return process.env.REACT_APP_API_HOST +
        '/api/admin/v1/reports/keyCodeAVGCSV?' +
        ReportsService.getAVGKeycodeReportQueryString(criteria);
  }

  static getKeyCodeReportCSVUrl(criteria) {
    return process.env.REACT_APP_API_HOST +
        '/api/admin/v1/reports/keyCodeCSV?' +
        ReportsService.getKeycodeReportQueryString(criteria);
  }

  static getKeycodeReport(criteria) {
    let params = ReportsService.getKeycodeReportQueryString(criteria);
    return super.getAPI(adminAPI, `reports/keyCode/?${params}`);
  }

  static getKeycodeReportQueryString(criteria) {
    let params = '';

    if (criteria.reportType) {
      params += '&reportType=' + criteria.reportType;
    }
    if (criteria.username) {
      params += '&userName=' + criteria.username;
    }
    if (criteria.vsc) {
      params += '&vsc=' + criteria.vsc;
    }
    if (criteria.vin) {
      params += '&vin=' + criteria.vin;
    }
    if (criteria.startDate) {
      params += '&startDate=' + criteria.startDate;
    }
    if (criteria.endDate) {
      params += '&endDate=' + criteria.endDate;
    }

    return encodeURLCharacters(params);
  }

  static getAVGKeycodeReport(criteria) {
    let params = ReportsService.getAVGKeycodeReportQueryString(criteria);
    return super.getAPI(adminAPI, `reports/keyCodeAVG/?${params}`);
  }

  static getAVGKeycodeReportQueryString(criteria) {
    let params = '';
    if (!criteria) {
      params += '';
    }
    if (criteria.regions) {
      params += 'region=' + criteria.regions;
    }
    if (criteria.startDate) {
      params += '&startDate=' + criteria.startDate;
    }
    if (criteria.endDate) {
      params += '&endDate=' + criteria.endDate;
    }

    return encodeURLCharacters(params);
  }

  static getTisSubscriptionsReport(criteria){
    let params = ReportsService.getTisSubscriptionsReportQueryString(criteria);
    return super.getAPI(adminAPI, `reports/tisSubscriptions/?${params}`);
  }

  static getTisSubscriptionsReportQueryString(criteria) {
    let params = '';
    if (!criteria) {
      params += '';
    }
    if (criteria.reportType) {
      params += '&tisSubscription=' + criteria.reportType;
    }
    if (criteria.startDate) {
      params += '&startDate=' + criteria.startDate;
    }
    if (criteria.endDate) {
      params += '&endDate=' + criteria.endDate;
    }

    return encodeURLCharacters(params);
  }

  static getTisSubscriptionsReportCSVUrl(criteria) {
    return process.env.REACT_APP_API_HOST +
        '/api/admin/v1/reports/tisSubscriptionsCSV?' +
        ReportsService.getTisSubscriptionsReportQueryString(criteria);
  }

  static getSubandSkuReport(criteria) {
    let params = '';
    if (criteria.regions) {
      params += 'region=' + criteria.regions;
    }
    if (criteria.startDate) {
      params += '&startDate=' + criteria.startDate;
    }
    if (criteria.endDate) {
      params += '&endDate=' + criteria.endDate;
    }
    if (criteria.productName) {
      params += '&productName=' + criteria.productName;
    }

    return super.getAPI(adminAPI, `reports/subscriptionAndSku/?${encodeURLCharacters(params)}`);
  }

  static getSubandSkuReportCSVUrl(criteria) {
    let params = '';
    if (criteria.regions) {
      params += 'region=' + criteria.regions;
    }
    if (criteria.startDate) {
      params += '&startDate=' + criteria.startDate;
    }
    if (criteria.endDate) {
      params += '&endDate=' + criteria.endDate;
    }
    if (criteria.productName) {
      params += '&productName=' + criteria.productName;
    }

    return process.env.REACT_APP_API_HOST +
        '/api/admin/v1/reports/subscriptionAndSkuCSV/?' + encodeURLCharacters(params);
  }

  static getSubscriptionReports(criteria) {
    let params = '';
    if (criteria.regions) {
      params += '&region=' + criteria.regions;
    }
    if (criteria.startDate) {
      params += '&startDate=' + criteria.startDate;
    }
    if (criteria.endDate) {
      params += '&endDate=' + criteria.endDate;
    }

    return super.getAPI(adminAPI, `reports/subscription/?${encodeURLCharacters(params)}`);
  }

  static getSubscriptionReportsExcelUrl(criteria) {
    let params = '';
    if (criteria.regions) {
      params += '&region=' + criteria.regions;
    }
    if (criteria.startDate) {
      params += '&startDate=' + criteria.startDate;
    }
    if (criteria.endDate) {
      params += '&endDate=' + criteria.endDate;
    }

    return process.env.REACT_APP_API_HOST +
        '/api/admin/v1/reports/subscriptionExcel?' + encodeURLCharacters(params);
  }

  static getSalesReport(criteria) {
    let params = '';
    if (criteria.regions) {
      params += 'region=' + criteria.regions;
    }
    if (criteria.startDate) {
      params += '&startDate=' + criteria.startDate;
    }
    if (criteria.endDate) {
      params += '&endDate=' + criteria.endDate;
    }
    if (criteria.reportType) {
      params += '&reportType=' + criteria.reportType;
    }

    return super.getAPI(adminAPI, `reports/sales/?${encodeURLCharacters(params)}`);
  }

  static getSalesReportCSVUrl(criteria) {
    let params = '';
    if (criteria.regions) {
      params += 'region=' + criteria.regions;
    }
    if (criteria.startDate) {
      params += '&startDate=' + criteria.startDate;
    }
    if (criteria.endDate) {
      params += '&endDate=' + criteria.endDate;
    }
    if (criteria.reportType) {
      params += '&reportType=' + criteria.reportType;
    }

    return process.env.REACT_APP_API_HOST +
        '/api/admin/v1/reports/salesCSV/?' + encodeURLCharacters(params);
  }

  static getURLParams(criteria) {
    let params = '';
    if (!criteria) {
      params += '';
    }
    if (criteria.codeType) {
      params += '&codeType=' + criteria.codeType;
    }
    if (criteria.startDate) {
      params += '&startDate=' + criteria.startDate;
    }
    if (criteria.endDate) {
      params += '&endDate=' + criteria.endDate;
    }
    if (criteria.status) {
      params += '&status=' + criteria.status;
    }

    return encodeURLCharacters(params);
  }

  static download(url, createdCodesArray) {
    return new Promise((resolve, reject) => {
      url.indexOf("createdPromoCodeCSV") !== -1
        ? adminAPI.post(url, createdCodesArray, {responseType: "blob"})
          .then((response) => {
            let data = response.data;
            let windowUrl = window.URL || window.webkitURL;
            let filename = 'file';
            //get the filename from the header
            let contentDisposition = response.headers['content-disposition'];
            if (contentDisposition) {
              let contentArray = contentDisposition.split(';');
              let findStr = 'filename=';
              for (let i = 0; i < contentArray.length; i++) {
                let content = contentArray[i];
                let idx = content.indexOf(findStr);
                if (idx > -1) {
                  filename = content.substring(idx + findStr.length);
                  break;
                }
              }
            }

            let blob = data;

            //IE sucks
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, filename);
              resolve();
            } else {
              let url = windowUrl.createObjectURL(blob);
              let anchor = document.createElement('a');
              anchor.setAttribute('href', url);
              anchor.setAttribute('download', filename);
              anchor.click();
              windowUrl.revokeObjectURL(url);
              resolve();
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response) {
              reject(error.response.data);
            } else if (error.message) {
              reject(error.message);
            } else {
              reject(error);
            }
          })
        : adminAPI.get(url, {responseType: "blob"})
          .then((response) => {
            let data = response.data;
            let windowUrl = window.URL || window.webkitURL;
            let filename = 'file';
            //get the filename from the header
            let contentDisposition = response.headers['content-disposition'];
            if (contentDisposition) {
              let contentArray = contentDisposition.split(';');
              let findStr = 'filename=';
              for (let i = 0; i < contentArray.length; i++) {
                let content = contentArray[i];
                let idx = content.indexOf(findStr);
                if (idx > -1) {
                  filename = content.substring(idx + findStr.length);
                  break;
                }
              }
            }

            let blob = data;

            //IE sucks
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, filename);
              resolve();
            } else {
              let url = windowUrl.createObjectURL(blob);
              let anchor = document.createElement('a');
              anchor.setAttribute('href', url);
              anchor.setAttribute('download', filename);
              anchor.click();
              windowUrl.revokeObjectURL(url);
              resolve();
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response) {
              reject(error.response.data);
            } else if (error.message) {
              reject(error.message);
            } else {
              reject(error);
            }
          });
    });

  }

}

function encodeURLCharacters(urlString) {
  return urlString.replaceAll("+", "%2B");
}