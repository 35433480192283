import React from 'react';
import {
  FormInput,
  Button,
  LoadingIcon,
  Radio,
  Header,
  TranslationMixin,
  WithCommonTranslations,
  WithFormValidation,
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../hocs';

export const SettingsForm = WithFormValidation(
    WithAdminTranslations(WithCommonTranslations(
        class SettingsForm extends TranslationMixin(React.Component) {

          render() {
            const {settingsList, subscriptionsSettings, transactionSettings, isValidated, loading} = this.props;

            return (
                <div>

                  <div className="generalSettings">
                    <Header level={3} className="tdsSettingHeader">
                      {this.getTranslation('HEADER_GENERAL_SETTINGS')}</Header>
                    <div className="align-items-center">
                      {settingsList.map((setting) =>
                        <div className="row" key={setting.key}>
                          <div className="tds-settings-label">
                            <div className="font-weight-bold mt-4">{setting.label}</div>
                            {setting.valueType === 'integer' && <i>({this.getTranslation('LABEL_MIN')}: {setting.min}, {this.getTranslation('LABEL_MAX')}: {setting.max})</i>}
                          </div>
                          <div className="col-sm-4">
                            {setting.valueType === 'integer' && <FormInput
                                className='integer-type'
                                value={setting.value}
                                required={true}
                                autoComplete="off"
                                type='number'
                                step={1} min={setting.min} max={setting.max}
                                name={setting.key}
                                id={setting.key}
                                message={setting.helpText}
                                displayError={isValidated}
                                onChange={this.props.handleInputChange}/>}

                            {setting.valueType === 'string' && <FormInput
                                className='string-type'
                                value={setting.value ? setting.value : ''}
                                required={setting.min > 0}
                                autoComplete="off"
                                type='text'
                                maxLength={setting.max}
                                name={setting.key}
                                id={setting.key}
                                message={setting.helpText}
                                displayError={isValidated}
                                onChange={this.props.handleInputChange}/>}

                            {setting.valueType === 'boolean' && <React.Fragment>
                              <Radio label=""
                                     name={setting.key}
                                     required={true}
                                     value={setting.value}
                                     options={[
                                       {
                                         id: 'selectedY',
                                         value: true,
                                         label: this.getTranslation('BUTTON_YES'),
                                       }, {
                                         id: 'selectedN',
                                         value: false,
                                         label: this.getTranslation('BUTTON_NO'),
                                       }]}
                                     onChange={(v) => this.props.handleRadioChange(setting.key, v)}/>
                              <div className="text-gray radio__message">{setting.helpText}</div>
                            </React.Fragment>}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>


                  <div className="subSettings">
                    <Header level={3} className="tdsSettingHeader">
                      {this.getTranslation('HEADER_SUBSCRIPTION_SETTINGS')}</Header>
                    <div className="align-items-center">
                      {subscriptionsSettings.map((setting) =>
                          <div className="row" key={setting.key}>
                            <div className="tds-settings-label">
                              <div className="font-weight-bold mt-4">{setting.label}</div>
                              {setting.valueType === 'integer' && <i>({this.getTranslation('LABEL_MIN')}: {setting.min}, {this.getTranslation('LABEL_MAX')}: {setting.max})</i>}
                            </div>
                            <div className="col-sm-4">
                              {setting.valueType === 'integer' && <FormInput
                                  className='integer-type'
                                  value={setting.value}
                                  required={true}
                                  autoComplete="off"
                                  type='number'
                                  step={1} min={setting.min} max={setting.max}
                                  name={setting.key}
                                  id={setting.key}
                                  message={setting.helpText}
                                  displayError={isValidated}
                                  onChange={this.props.handleInputChange}/>}

                              {setting.valueType === 'string' && <FormInput
                                  className='string-type'
                                  value={setting.value ? setting.value : ''}
                                  required={setting.min > 0}
                                  autoComplete="off"
                                  type='text'
                                  maxLength={setting.max}
                                  name={setting.key}
                                  id={setting.key}
                                  message={setting.helpText}
                                  displayError={isValidated}
                                  onChange={this.props.handleInputChange}/>}

                              {setting.valueType === 'boolean' && <React.Fragment>
                                <Radio label=""
                                       name={setting.key}
                                       required={true}
                                       value={setting.value}
                                       options={[
                                         {
                                           id: 'selectedY',
                                           value: true,
                                           label: this.getTranslation('BUTTON_YES'),
                                         }, {
                                           id: 'selectedN',
                                           value: false,
                                           label: this.getTranslation('BUTTON_NO'),
                                         }]}
                                       onChange={(v) => this.props.handleRadioChange(setting.key, v)}/>
                                <div className="text-gray radio__message">{setting.helpText}</div>
                              </React.Fragment>}
                            </div>
                          </div>
                      )}
                    </div>
                  </div>


                  <div className="transactionSettings">
                    <Header level={3} className="tdsSettingHeader">
                      {this.getTranslation('HEADER_TRANSACTION_SETTINGS')}</Header>
                    <div className="align-items-center">
                      {transactionSettings.map((setting) =>
                          <div className="row" key={setting.key}>
                            <div className="tds-settings-label">
                              <div className="font-weight-bold mt-4">{setting.label}</div>
                              {setting.valueType === 'integer' && <i>({this.getTranslation('LABEL_MIN')}: {setting.min}, {this.getTranslation('LABEL_MAX')}: {setting.max})</i>}
                            </div>
                            <div className="col-sm-4">
                              {setting.valueType === 'integer' && <FormInput
                                  className='integer-type'
                                  value={setting.value}
                                  required={true}
                                  autoComplete="off"
                                  type='number'
                                  step={1} min={setting.min} max={setting.max}
                                  name={setting.key}
                                  id={setting.key}
                                  message={setting.helpText}
                                  displayError={isValidated}
                                  onChange={this.props.handleInputChange}/>}

                              {setting.valueType === 'string' && <FormInput
                                  className='string-type'
                                  value={setting.value ? setting.value : ''}
                                  required={setting.min > 0}
                                  autoComplete="off"
                                  type='text'
                                  maxLength={setting.max}
                                  name={setting.key}
                                  id={setting.key}
                                  message={setting.helpText}
                                  displayError={isValidated}
                                  onChange={this.props.handleInputChange}/>}

                              {setting.valueType === 'boolean' && <React.Fragment>
                                <Radio label=""
                                       name={setting.key}
                                       required={true}
                                       value={setting.value}
                                       options={[
                                         {
                                           id: 'selectedY',
                                           value: true,
                                           label: this.getTranslation('BUTTON_YES'),
                                         }, {
                                           id: 'selectedN',
                                           value: false,
                                           label: this.getTranslation('BUTTON_NO'),
                                         }]}
                                       onChange={(v) => this.props.handleRadioChange(setting.key, v)}/>
                                <div className="text-gray radio__message">{setting.helpText}</div>
                              </React.Fragment>}
                            </div>
                          </div>
                      )}
                    </div>
                  </div>

                  <div className="col-auto">
                    <div className="float-right button-save">
                      <Button type="submit" variant="primary"
                              disabled={loading}>
                        <LoadingIcon display={loading}/>
                        {this.getTranslation('BUTTON_SAVE')}
                      </Button>
                    </div>
                  </div>

              </div>
            );
          }
        })));