import {GenericAjaxService, adminAPI} from 'tds_shared_ui';

export default class ACDelcoAccountService extends GenericAjaxService {

  static search(accountNumber) {
    return super.getAPI(adminAPI,
        `acdelcoaccount/${encodeURIComponent(accountNumber)}`);
  }

  static remove(accountNumber) {
    return super.deleteAPI(adminAPI,
        `acdelcoaccount/${encodeURIComponent(accountNumber)}`);
  }
}