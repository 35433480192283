import React from 'react';
import moment from 'moment';
import {
    Alert,
    AlertMixin,
    Button,
    Header,
    LoadingIcon,
    Select,
    TranslationMixin,
    WithCommonTranslations,
} from 'tds_shared_ui';
import {CommentsService} from "../../services";
import {WithAdminTranslations, WithUser} from '../hocs';

import './Comments.css';

export const Comments = WithUser(WithAdminTranslations(
    WithCommonTranslations(
        class extends AlertMixin(TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);

            this.state = {
              comments: [],
              adding: false,
              deleting: false,
              refreshing: false,
              date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
              formValues: {
                comment: '',
              },
              alert: {
                display: false,
                type: 'default',
                message: '',
              },
            };

            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleAddComment = this.handleAddComment.bind(this);
            this.handleClearComment = this.handleClearComment.bind(this);
            this.handleDateChange = this.handleDateChange.bind(this);
          }

          componentDidMount() {
            if (this.props.username) {
              this.refreshComments();
            }
          }

          componentDidUpdate(prevProps){
            if (prevProps.username !== this.props.username){
              this.refreshComments();
            }
          }

          refreshComments() {
            this.clearError();
            this.setState({
              refreshing: true,
              comments: [],
            });
            CommentsService.get(this.props.username, this.state.date)
                .then((data) => {
                  this.setState({
                    comments: data,
                  });
                }).catch((e) => {
              this.displayError(e);
            }).finally(() => {
              this.setState({
                refreshing: false,
              });
            });
          }

          getTimeOptions() {
            return [
              {
                label: this.getTranslation('LABEL_LAST_30_DAYS'),
                value: moment().subtract(30, 'days').format('YYYY-MM-DD'),
              }, {
                label: this.getTranslation('LABEL_LAST_90_DAYS'),
                value: moment().subtract(90, 'days').format('YYYY-MM-DD'),
              }, {
                label: this.getTranslation('LABEL_LAST_6_MONTHS'),
                value: moment().subtract(6, 'months').format('YYYY-MM-DD'),
              }, {
                label: this.getTranslation('LABEL_LAST_1_YEAR'),
                value: moment().subtract(1, 'year').format('YYYY-MM-DD'),
              }];
          }

          handleInputChange(event, callback) {
            const target = event.target;
            const value = target.type === 'checkbox' ?
                target.checked :
                target.value;

            this.setState({
              formValues: {
                comment: value,
              },
            });
          }

          handleDateChange(date) {
            this.clearError();
            this.setState({
              date: date,
            }, this.refreshComments);
          }

          handleAddComment() {
            this.setState({
              adding: true,
            });
            this.clearError();
            CommentsService.create(this.props.username, this.state.formValues)
                .then((data) => {
                  this.setState((prevState) => {
                    return {
                      formValues: {
                        comment: '',
                      },
                      comments: [data].concat(prevState.comments),
                    };
                  });
                }).catch((e) => {
              this.displayError(e);
            }).finally(() => {
              this.setState({
                adding: false,
              });
            });
          }

          handleClearComment() {
            this.clearError();
            this.setState({
              formValues: {
                comment: '',
              },
            });
          }

          handleDelete = (id) => () => {
            this.clearError();
            this.setState({
              deleting: id,
            });
            CommentsService.delete(id).then((data) => {
              this.setState(prevState => {
                return {
                  comments: prevState.comments.filter((c) => c.id !== id),
                };
              });
            }).catch((e) => {
              this.displayError(e);
            }).finally(() => {
              this.setState({
                deleting: false,
              });
            });
          };

          renderRefreshing() {
            return (
                <div><LoadingIcon
                    display={true}/>
                  {this.getTranslation('MESSAGE_LOADING')}</div>
            );
          }

          renderCommentsList() {
            return (
                <React.Fragment>
                  {this.state.comments && this.state.comments.length > 0 ?
                      this.state.comments.map((comment) => (
                          <div className="comment" key={comment.id}>
                            <div><strong>{comment.author}</strong>
                              {comment.author.toLowerCase() === this.props.user.username.toLowerCase() &&
                              <span className="float-right">
                              <Button variant="link" type="button"
                                      className="btn-del"
                                      disabled={this.state.deleting === comment.id}
                                      onClick={this.handleDelete(comment.id)}>
                                <LoadingIcon display={this.state.deleting === comment.id}/>
                                {this.getTranslation('LABEL_DELETE')}</Button>
                            </span>
                              }
                            </div>
                            <div>{comment.createDate}</div>
                            <p>{comment.comment}</p>
                          </div>

                      ))
                      :
                      <div>{this.getTranslation(
                          'MESSAGE_NO_COMMENTS_FOUND')}</div>
                  }
                </React.Fragment>
            );
          }

          render() {
            const timeOptions = this.getTimeOptions();
            return (
                <div className="tds-comments">
                  <Header level={2} className="clearfix">{this.getTranslation(
                      'TITLE_COMMENTS')} <Select options={timeOptions}
                                                 value={this.state.date}
                                                 onChange={this.handleDateChange}/></Header>
                  {this.state.alert.display &&
                  <Alert ref={this.alertRef}
                         type={this.state.alert.type}>{this.state.alert.message}</Alert>}


                  <div className="comment-list">
                    {this.state.refreshing ? this.renderRefreshing() : this.renderCommentsList()}
                  </div>
                  <div className="mt-3">
                    <textarea value={this.state.formValues.comment}
                              cols={60}
                              rows={3}
                              maxLength="500"
                              placeholder={this.getTranslation(
                                  'MESSAGE_ADD_COMMENT')}
                              onChange={this.handleInputChange}></textarea>
                  </div>
                  <div className="float-right">
                    <Button variant="link" type="button"
                            onClick={this.handleClearComment}>{this.getTranslation(
                        'BUTTON_CANCEL')}</Button>
                    <Button variant="primary" type="button"
                            disabled={this.state.adding || this.state.formValues.comment === ""}
                            onClick={this.handleAddComment}>
                      <LoadingIcon display={this.state.adding}/>
                      {this.getTranslation(
                          'BUTTON_ADD')}</Button>
                  </div>

                </div>
            );
          }
        })));