import React from 'react';
import {Button, TranslationMixin, Table, LoadingIcon, Alert, WithCommonTranslations, AlertMixin} from 'tds_shared_ui';
import NotificationsService from '../../services/NotificationsService';
import {WithAdminTranslations} from "../hocs";

export const NotificationsTable = WithAdminTranslations(WithCommonTranslations(class extends AlertMixin(TranslationMixin(
    React.Component)) {
    constructor(props) {
        super(props);

        this.cancelToken = null;

        this.state = {
            columns: '',
            myTableData: [],
            notificationData: '',
            loading: '',
            alert: {
                display: false,
                type: '',
                message: ''
            }

        };
        this.handleClick = this.handleClick.bind(this);
        this.fetchTable = this.fetchTable.bind(this);
        this.handleView = this.handleView.bind(this);
    }

    handleClick() {
        this.setState({
            showCreatePage: true,
        });
    }

    componentDidMount() {
        if (this.props.date) {
            this.fetchTable(this.props.date);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            this.fetchTable(this.props.date);
        }
    }

    componentWillUnmount(){
        if (this.cancelToken) {
            this.cancelToken.cancel({unmounted: true});
        }
    }

    fetchTable(date) {
        //TODO show error message on errors
        this.setState({
            loading: true,
        });

        if (this.cancelToken) {
            this.cancelToken.cancel({cancelled: true});
        }

        this.cancelToken = NotificationsService.getCancelToken().source();

        NotificationsService.getListOfNotifications(date, {cancelToken: this.cancelToken.token}).then((data) => {
            this.setState({
                myTableData: data,
                loading: false,
            });
        }, (e) => {
            if (!e.cancelled) {
                this.displayError(e);
                this.setState({
                    loading: false,
                });
            }
        }).catch((e) => {
          //Adding empty catch to prevent webpack unhandled exception msg
        });
    }

    handleView(id) {
        NotificationsService.getNotificationsbyID(id).then((data) => {
            this.setState({
                notificationData: data,
            }, () => {
                //Adding callback for React 18 otherwise it will not work due to async nature of setState
                this.props.onEdit(this.state.notificationData);
            });
        }).catch((e) => {
            this.displayError(e);
        });

    }

    render() {

        const {loading, myTableData, alert} = this.state;
        const {message} = this.props;

        const cols = [
            {
                header: this.getTranslation('COLUMN_STATUS'),
                accessor: 'status',
            },
            {
                header: this.getTranslation('COLUMN_AUDIENCE'),
                accessor: 'audience',
            }, {
                header: this.getTranslation('COLUMN_REGIONS'),
                accessor: 'regions',
            }, {
                header: this.getTranslation('COLUMN_LANGUAGES'),
                accessor: 'languages',
            },
            {
                header: this.getTranslation('COLUMN_MESSAGE'),
                accessor: 'message',
                render: (rowData) => {
                    let {message, title} = rowData.messageSample;

                    return (
                        <React.Fragment>
                            <div><strong>{title}</strong></div>
                            <div>{message}</div>
                        </React.Fragment>
                    );
                },
            },
            {
                header: this.getTranslation('COLUMN_TYPE'),
                accessor: 'type',
            },
            {
                header: this.getTranslation('COLUMN_BEGIN'),
                accessor: 'begin',
            },
            {
                header: this.getTranslation('COLUMN_END'),
                accessor: 'end',
            }, {
                header: this.getTranslation('COLUMN_ACTION'),
                accessor: 'action',
                render: (rowData) => {
                    return <Button variant="link"
                                   onClick={() => this.handleView(rowData.id)}>
                        {this.getTranslation('BUTTON_VIEW')}</Button>;
                },
            },
        ];

        return (
            <div>
                {alert.display && <Alert ref={this.alertRef} type={alert.type}>
                    {alert.message}</Alert>}
                {loading ?
                    <LoadingIcon text={this.getTranslation('MESSAGE_LOADING')}
                                 display={loading}/> :
                    <Table filterId="notificationsFilter"
                           columns={cols}
                           tableMessage={message}
                           rows={myTableData}
                    />
                }
            </div>
        );
    }
}));