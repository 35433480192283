import React from 'react';

import {
    Button,
    DatePicker,
    Select,
    TranslationMixin,
    WithCommonTranslations,
    FormInput, Radio, LoadingIcon,
    WithFormValidation,
} from 'tds_shared_ui';
import {PromoCodeService} from "../../services";
import {WithAdminTranslations} from '../hocs'
import {promoCodeUtilities} from './utility'

import './CreatePromoCodeProperties.css';

export const CreatePromoCodeProperties = (WithFormValidation(
    WithAdminTranslations(WithCommonTranslations(class CreatePromoCodeProperties
        extends TranslationMixin(React.Component) {
        constructor(props) {
            super(props);

            this.state = {
              maxUses: 1,
              maxCodes: 1,
              maxDiscount: 0,
              regionOptions: [],
              subOptions: [],
              skuOptions: [],
              showModal: false,
              typeLoading: false,
              skuLoading: false,
              beginDate: null,
              endDate: null,
            };
            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleRadioChange = this.handleRadioChange.bind(this);
            this.handleRegionChange = this.handleRegionChange.bind(this);
            this.handleProductIdChange = this.handleProductIdChange.bind(this);
            this.handleCodeTypeChange = this.handleCodeTypeChange.bind(this);
          }

          componentDidMount() {
            PromoCodeService.getDefaultOptions()
                .then((data) => {
                  this.setState({
                    regionOptions: data.regionOptionItemList,
                    maxCodes: data.maxCodes,
                    maxUses: data.maxUses,
                  });
                })
                .catch(e => this.props.onError(e));
          }

          static getDerivedStateFromProps(props) {
            let beginDate = null;
            let endDate = null;
            let fv = props.formValues;
            if (fv) {
              if (fv.startDate) {
                beginDate = fv.startDate;
              }
              if (fv.endDate) {
                endDate = fv.endDate;
              }
            }

            return {
              beginDate: beginDate,
              endDate: endDate,
            };
          }

          handleRegionChange(value) {
            this.setState({
              skuLoading: true,
              subOptions: [],
              skuOptions: [],
            });
            PromoCodeService.getSubOptions(value)
                .then((data) => {
                    if(this.props.formValues.regions === 'AU'){
                        this.setState({
                            subOptions: promoCodeUtilities.removeUnusedSubs(data),
                        });
                    } else {
                        this.setState({
                            subOptions: data,
                        });
                    }
                })
                .catch(e => this.props.onError(e))
                .finally(() => {
                  this.setState({
                    skuLoading: false,
                  });
                });

            this.props.onChange('regions', value);
          }


          handleProductIdChange(value) {
            this.setState({
              typeLoading: true,
              skuOptions: [],
            });
            PromoCodeService.getSkuOptions(this.props.formValues.regions, value)
                .then((data) => {
                  this.setState({
                    skuOptions: data,
                  });

                })
                .catch(e => this.props.onError(e))
                .finally(() => {
                  this.setState({
                    typeLoading: false,
                  });
                });
            this.props.onChange('productId', value);
          }

          handleCodeTypeChange(value) {
            let skuOption = this.state.skuOptions.filter(
                obj => obj.value === value);
            if (skuOption.length > 0) {
              this.setState({
                maxDiscount: skuOption[0].price,
              });
            }
            this.props.onChange('codeType', value);
          }

          handleRadioChange(name, strValue) {
            let value = strValue;
            if (strValue === 'true') {
              value = true;
            } else if (strValue === 'false') {
              value = false;
            }
            this.props.onChange(name, value);
          }

          handleInputChange = (event) => {
            const target = event.target;
            const value = target.type === 'checkbox' ?
                target.checked :
                target.value;
            const name = target.name;

            this.props.onChange(name, value);
          };

          render() {
            const {subOptions, skuOptions, maxUses, maxCodes, maxDiscount, regionOptions, skuLoading, typeLoading, beginDate, endDate} = this.state;
            const {formValues, isValidated, beginRef, endRef, beginDateErrorMsg, endDateErrorMsg, onChange, onCancel, loading, onReset} = this.props;
            let maxValue = formValues.isPercentDiscount ? 100 : maxDiscount;

            return (
                <div className="create-promo-code-properties container">
                  <div className="row">
                    <div>
                      <div>
                        <Select label={this.getTranslation('HEADER_REGION')}
                                name="regions"
                                id="regions"
                                required={true}
                                options={regionOptions}
                                value={formValues.regions}
                                error={isValidated && !formValues.regions}
                                errorMessage={this.getTranslation(
                                    'ERROR_REQUIRED')}
                                onChange={this.handleRegionChange}/>
                      </div>

                      <div className="row">
                        <div className="col">
                          <Select label={
                            <React.Fragment>
                              {this.getTranslation('HEADER_SUBSCRIPTION_TYPE')}
                              &nbsp;<LoadingIcon display={skuLoading}/>
                            </React.Fragment>}
                                  name="productId"
                                  id="productId"
                                  required={true}
                                  options={subOptions}
                                  value={formValues.productId}
                                  readOnly={!subOptions || subOptions.length === 0}
                                  error={isValidated && !formValues.productId}
                                  errorMessage={this.getTranslation(
                                      'ERROR_REQUIRED')}
                                  onChange={this.handleProductIdChange}/>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <Select label={
                            <React.Fragment>
                              {this.getTranslation('HEADER_SKU_TYPE')}
                              &nbsp;<LoadingIcon display={typeLoading}/>
                            </React.Fragment>}
                                  name="codeType"
                                  id="codeType"
                                  required={true}
                                  options={skuOptions}
                                  value={formValues.codeType}
                                  readOnly={!skuOptions || skuOptions.length === 0}
                                  error={isValidated && !formValues.codeType}
                                  errorMessage={this.getTranslation(
                                      'ERROR_REQUIRED')}
                                  onChange={this.handleCodeTypeChange}/>
                        </div>
                      </div>

                      <div className="discount-row">
                        <span className="d-inline-flex align-items-center">
                            <FormInput id="discount" name="discount"
                                       label={this.getTranslation(
                                           'HEADER_DISCOUNT')}
                                       type="number"
                                       step={0.01} min={0.01} max={maxValue}
                                       required={true}
                                       className="refund-value"
                                       autoComplete="off"
                                       pattern="\d+(\.\d{1,2})?"
                                       data-pattern-message={this.getTranslation(
                                           'ERROR_INVALID_DISCOUNT')}
                                       size={10}
                                       displayError={isValidated}
                                       onChange={this.handleInputChange}
                                       value={formValues.discount}></FormInput>

                            <Radio name="isPercentDiscount" label=""
                                   value={formValues.isPercentDiscount}
                                   onChange={(v) => this.handleRadioChange(
                                       'isPercentDiscount', v)}
                                   options={[
                                     {
                                       id: `discountAmount`,
                                       value: false,
                                       label: this.getTranslation(
                                           'LABEL_AMOUNT'),
                                     },
                                     {
                                       id: `discountPercent`,
                                       value: true,
                                       label: this.getTranslation(
                                           'LABEL_PERCENT'),
                                     }]}/>
                            </span>
                      </div>
                      <div className="d-flex flex-row align-items-center">
                        <div className="tds-num-field">
                          <FormInput
                              label={this.getTranslation('HEADER_NUM_OF_USES')}
                              value={formValues.numUses}
                              autoComplete="off"
                              type="number"
                              step={1} min={1} max={maxUses}
                              name="numUses"
                              id="numUses"
                              required={true}
                              displayError={isValidated}
                              onChange={this.handleInputChange}/>

                        </div>
                        <i>{this.getTranslation('LABEL_MAX')}: {maxUses}</i>
                      </div>
                      <div className="d-flex flex-row align-items-center">
                        <div className="tds-num-field">
                          <FormInput
                              label={this.getTranslation('HEADER_NUM_OF_CODES')}
                              value={formValues.numCodes}
                              autoComplete="off"
                              type="number"
                              step={1} min={1} max={maxCodes}
                              name="numCodes"
                              id="numCodes"
                              required={true}
                              displayError={isValidated}
                              onChange={this.handleInputChange}/>
                        </div>
                        <i>{this.getTranslation('LABEL_MAX')}: {maxCodes}</i>
                      </div>

                      <div>
                        <div className="d-flex flex-row align-items-start">
                          <div className="mr-2">
                            <DatePicker
                                label={this.getTranslation('HEADER_START_DATE')}
                                dateFormat="MM/dd/yyyy"
                                className="tds-date-width"
                                autoComplete="off"
                                startDate={beginDate}
                                endDate={endDate}
                                ref={beginRef}
                                minDate={new Date()}
                                invalidDateMsg={this.getTranslation(
                                    'ERROR_INVALID_DATE')}
                                required={true}
                                id={'startDate'}
                                displayError={isValidated}
                                customError={beginDateErrorMsg}
                                onChange={(startDate) => onChange('startDate', startDate)}
                                selected={beginDate}
                                popperPlacement="top-end"/>
                          </div>
                          <div className="col-6">
                            <DatePicker
                                label={this.getTranslation('HEADER_END_DATE')}
                                dateFormat="MM/dd/yyyy"
                                className="tds-date-width"
                                autoComplete="off"
                                startDate={beginDate}
                                endDate={endDate}
                                ref={endRef}
                                invalidDateMsg={this.getTranslation('ERROR_INVALID_DATE')}
                                required={true}
                                id={'endDate'}
                                displayError={isValidated}
                                customError={endDateErrorMsg}
                                onChange={(endDate) => onChange('endDate', endDate)}
                                selected={endDate}/>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix mt-3">
                        <Button variant="link" type="button"
                                className="pl-0"
                                onClick={onCancel}>
                          {this.getTranslation('BUTTON_BACK')}</Button>

                        <div className="float-right">
                          <Button variant="link" type="button"
                                  onClick={onReset}>
                            {this.getTranslation('BUTTON_RESET')}</Button>

                          <Button variant="primary" type="submit"
                                  disabled={loading}>
                            <LoadingIcon display={loading}/>
                            {this.getTranslation('BUTTON_CREATE')}</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            );
          }
        }))));