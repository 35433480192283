import React from 'react';


import {Button, Header, Modal, TranslationMixin, WithCommonTranslations} from 'tds_shared_ui';
import {WithAdminTranslations} from '../hocs';
import {Documents} from './Documents';

export const DocumentsModal =
    WithAdminTranslations(WithCommonTranslations(class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
      }

      handleClose(){
        return this.props.onClose();
      }

      render() {
        const {open, username} = this.props;

        return (
            <Modal header={<Header level={1}>
              {this.getTranslation("TITLE_MANAGE_DOCUMENTS")}
            </Header>}
                   onClose={this.handleClose}
                   open={open}
                   onBackdropClick={this.handleClose}>

              <div className="modal-container">

                <div>
                  <Documents username={username}/>
                </div>

                <div className="float-right">
                  <Button type="submit"
                          variant="primary"
                          onClick={this.handleClose}>
                    {this.getTranslation("BUTTON_CLOSE")}</Button>
                </div>
              </div>
            </Modal>
        );
      }
    }));