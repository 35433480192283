import React from 'react';
import {UserConsumer} from '../../context';

export const WithUser = WrappedComponent => {
  class WithUser extends React.Component {
    render() {
      return (
          <UserConsumer>
            {({user, setUser, notificationData, dismissNotification}) => (
                <WrappedComponent
                    {...this.props}
                    user={user}
                    setUser={setUser}
                    notificationData={notificationData}
                    dismissNotification={dismissNotification}
                />
            )}
          </UserConsumer>
      );
    }
  };

  WithUser.displayName = `WithUser(${getDisplayName(
      WrappedComponent)})`;

  return WithUser;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
