import React from 'react';
import PropTypes from 'prop-types';
import {WithUser} from '../hocs';
import {TranslationMixin} from 'tds_shared_ui'
import './LanguageCheckBox.css';

export const LanguageCheckBox = WithUser(class extends TranslationMixin(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            checked: props.checked
        };
    }

    render() {
        const {disabled, onChange, id, htmlFor, checked, checkBoxLabel} = this.props;
        return (
            <div className="tds-checkbox form-check form-check-inline">
                <input className="form-check-input"
                       type="checkbox"
                       id={id}
                       checked={checked}
                       disabled={disabled}
                       onChange={onChange}/>
                <label className="form-check-label" htmlFor={htmlFor}>{checkBoxLabel}</label>
            </div>
        );
    }
});

LanguageCheckBox.propTypes = {
    checkBoxHeader: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    checkBoxLabel: PropTypes.string,
    onChange: PropTypes.func,
};