import React from 'react';
import moment from 'moment';
import {
    Alert,
    Button,
    ConfirmationModal,
    HelperUtils,
    ProfileForm,
    TranslationMixin,
    UserService,
    WithCommonTranslations,
    WithUserTranslations,
} from 'tds_shared_ui';
import {WithAdminTranslations, WithUser} from '../hocs';
import {ProfileExtraFields} from './ProfileExtraFields';
import {AdminUserService} from "../../services";

export const Profile = WithUser(WithAdminTranslations(WithCommonTranslations(
    WithUserTranslations(class extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);

            this.alertRef = React.createRef();

            this.state = {
              superAdmin: false,
              displayConfirm: false,
              states: [],
              readOnly: true,
              loading: false,
              alert: {
                display: false,
                type: 'default',
                message: '',
              },
              formValues: {
                username: '',
                password: '',
                confirmPassword: '',
                firstName: '',
                middleName: '',
                lastName: '',
                email: '',
                phone: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                locksmithID: '',
                locksmithPasscode: '',
                acdelcoAcctNum: '',
                companyEmail: '',
                vat: '',
                companyName: '',
                preferredLanguage: ''
              },
            };

            this.testbool = UserService.isSuperAdmin(props.user);
            this.handleEditUser = this.handleEditUser.bind(this);
            this.handleDeleteLocksmithID = this.handleDeleteLocksmithID.bind(this);
            this.handleCancel = this.handleCancel.bind(this);
            this.handleCancelDelete = this.handleCancelDelete.bind(this);
            this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
          }

          componentDidMount() {
            if (this.props.profileUser) {
              this.initializeUser(this.props.profileUser);
            }
          }

          static getDerivedStateFromProps(props) {
            return {
              superAdmin: UserService.isSuperAdmin(props.user),
            };
          }

          handleEditUser() {
            this.setState({
              readOnly: false,
            });
          }

          handleCancelDelete() {
            this.setState({
              displayConfirm: false,
            });
          }

          showDeleteConfirm() {
            this.setState({
              displayConfirm: true,
            });
          }

      handleDeleteLocksmithID() {
        this.setState({
          displayConfirm: false,
          loadingDelete: true,
        });
        AdminUserService.deleteLocksmithID(this.props.profileUser.username)
            .then((data) => {
              this.props.onUpdate(data);
              this.initializeUser(data);
              this.setState({
                alert: {
                      display: true,
                      message: this.getTranslation(
                          'MESSAGE_SUCCESS_DELETE_VSC'),
                      type: 'success',
                    },
                  }, this.scrollToAlert);
                })
                .catch((error) => {
                  //TODO change to translatedMessage when not null
                  const msg = error.translatedMessage || this.getTranslation("ERROR_GENERIC");
                  this.setState({
                    alert: {
                      display: true,
                      message: msg,
                      type: 'error',
                    },
                  }, this.scrollToAlert);
                })
                .finally(() => {
                  this.setState({
                    loadingDelete: false,
                  });

                });
          }

          initializeUser(user) {
            let states = [];
            if (user === null) {
              user = {};
            }
            let address = user.address;
            if (address == null) {
              address = {};
            } else {
              if (address.country && this.props.countryStateMap) {
                states = this.props.countryStateMap[address.country];
              }
            }

            this.setState({
              states: states,
              formValues: {
                username: user.username || '',
                password: '',
                confirmPassword: '',
                firstName: user.firstName || '',
                middleName: user.middleName || '',
                lastName: user.lastName || '',
                email: user.email || '',
                phone: user.phone || '',
                addressLine1: address.addressLine1 || '',
                addressLine2: address.addressLine2 || '',
                city: address.city || '',
                state: address.state || '',
                zipCode: address.zipCode || '',
                country: address.country || '',
                locksmithID: user.locksmithID || '',
                locksmithPasscode: '',
                acdelcoAcctNum: user.acdelcoAcctNum || '',
                taxExempt: user.taxExempt || false,
                enabledTechLineCache: user.enabledTechLineCache || false,
                taxExpirationDate: user.taxExpirationDate,
                preferredLanguage: user.preferredLanguage,
                companyEmail: user.companyEmail || '',
                vat: user.vat || '',
                companyName: user.companyName || '',
                keycodeOverride: user.keycodeOverride || false,
                keycodeLimit: user.keycodeLimit || 0,
              },
            });
          }

          componentDidUpdate(prevProps, prevState) {
            let {profileUser} = this.props;
            if (profileUser) {
              if (!prevProps || !prevProps.profileUser) {
                this.initializeUser(profileUser);
              } else if (prevProps.profileUser == null ||
                  prevProps.profileUser.address.country !==
                  profileUser.address.country) {
                this.setState({
                  states: this.props.countryStateMap[this.props.profileUser.address.country],
                });
              }
            }
          }

          handleChange = (name, value, callback) => {
            this.setState((prevState) => {
              let newState = {};
              let formValues = Object.assign({}, prevState.formValues);
              formValues[name] = value;
              newState.formValues = formValues;
              if (name === 'country' && prevState.formValues.country !== value) {
                //reset state if country is changed
                newState.formValues.state = '';
                if (value != null) {
                  newState.states = this.props.countryStateMap[value];
                } else {
                  newState.states = [];
                }
              }
              return newState;
            }, callback);
          };

          handleCancel = () => {
            this.initializeUser(this.props.profileUser);
            this.setState({
              readOnly: true,
              alert: {
                display: false,
              },
            });
          };

          scrollToAlert() {
            HelperUtils.scrollToAlert(this.alertRef);
          }

          getUserObject(formValues) {
            let {
              addressLine1,
              addressLine2,
              city,
              state,
              zipCode,
              country,
              confirmPassword,
              locksmithID,
              locksmithPasscode,
              taxExpirationDate,
              ...otherProps
            } = formValues;

            let userUpdate = Object.assign({}, this.props.profileUser, otherProps);

            //Password isn't updated through Edit Profile but appears on the
            //user object from props, remove it
            delete userUpdate.password;

            userUpdate.taxExpirationDate = moment(taxExpirationDate)
                .format('YYYY-MM-DD');
            userUpdate.locksmithID = locksmithID;
            userUpdate.locksmithPasscode = locksmithPasscode;
            let address = {
              addressLine1: addressLine1,
              addressLine2: addressLine2,
              city: city,
              state: state,
              zipCode: zipCode,
              country: country,
            };
            userUpdate.address = Object.assign(userUpdate.address, address);
            return userUpdate;
          }

          onValidationError = () => {
            this.setState({
              alert: {
                display: true,
                message: this.getTranslation('ERROR_FORM_VALIDATION'),
                type: 'error',
              },
            }, this.scrollToAlert);
          };

          handleSubmit = () => {
            this.setState({
              loading: true,
              alert: {
                display: false,
              },
            });

            AdminUserService.update(this.getUserObject(this.state.formValues))
                .then((data) => {
                  this.props.onUpdate(data);
                  this.initializeUser(data);
                  this.setState({
                    loading: false,
                    readOnly: true,
                    alert: {
                      display: true,
                      message: this.getTranslation(
                          'MESSAGE_SUCCESS_UPDATE_USER_PROFILE'),
                      type: 'success',
                    },
                  }, this.scrollToAlert);
                })
                .catch((error) => {
                  const msg = error.translatedMessage || this.getTranslation("ERROR_GENERIC");
                  this.setState({
                    loading: false,
                    alert: {
                      display: true,
                      message: msg,
                      type: 'error',
                    },
                  }, this.scrollToAlert);
                });
          };

          render() {
            let showVATField = false;
            let requireState = false;
            let requireVAT = false;
            let taxIdLabelId = "";
            let showLocksmithFields = false;
            let regionCode = this.props.regionData.regionCode;
            if (this.props.regionData) {
              let {regionConfigs} = this.props.regionData;
              showVATField = regionConfigs.showVATField === 'true';
              showLocksmithFields = regionConfigs.showLocksmithFields === 'true';
              requireState = regionConfigs.stateRequired === 'true';
              requireVAT = regionConfigs.requireVAT === 'true';
              taxIdLabelId = regionConfigs.taxIdLabelId;
            }

            return (
                <div className="registrationPage">

                  {this.state.alert.display &&
                  <Alert ref={this.alertRef}
                         type={this.state.alert.type}>{this.state.alert.message}</Alert>}

                  {this.state.readOnly &&
                  <div className="clearfix">
                    <Button variant="link" className="float-right"
                            onClick={this.handleEditUser}>
                      <i className="fas fa-pencil-alt"></i>&nbsp;{this.getTranslation(
                        'LABEL_EDIT_USER')}</Button>
                  </div>}

                  <ProfileForm submit={this.handleSubmit}
                               onValidationError={this.onValidationError}
                               readOnly={this.state.readOnly}
                               locksmithIDReadOnly={!this.state.superAdmin}
                               hidePasscode={true}
                               regionCode={regionCode}
                               showLocksmithFields={showLocksmithFields}
                               showVATField={showVATField}
                               taxIdLabelId={taxIdLabelId}
                               requireVAT={requireVAT}
                               showDeleteLocksmithID={!this.state.readOnly}
                               onDeleteLocksmithID={this.showDeleteConfirm}
                               loading={this.state.loading}
                               formValues={this.state.formValues}
                               countries={this.props.countries}
                               states={this.state.states}
                               requireState={requireState}
                               languages={this.props.languages}
                               onChange={this.handleChange}
                               onCancel={this.handleCancel}
                               showTerms={false} showLogin={false}
                               renderExtraFields={(props) => (<ProfileExtraFields
                                   formValues={this.state.formValues}
                                   readOnly={this.state.readOnly}
                                   username={this.props.profileUser.username}
                                   superAdmin={this.testbool ? true: false}
                                   onChange={this.handleChange} {...props}/>)}/>

                  <ConfirmationModal
                      title={this.getTranslation('HEADER_CONFIRM')}
                      content={
                        <p>{this.getTranslation(
                            'MESSAGE_CONFIRM_DELETE_VSC')}</p>
                      }
                      open={this.state.displayConfirm}
                      cancelText={this.getTranslation('BUTTON_CANCEL')}
                      okText={this.getTranslation('BUTTON_YES')}
                      onCancel={this.handleCancelDelete}
                      onSuccess={this.handleDeleteLocksmithID}/>

                </div>
            );
          }
        },
    ))));