import React from 'react';
import {Route, Routes} from 'react-router-dom';

import {
  Button,
  Header,
  TranslationMixin,
  WithCommonTranslations,
  withRouter,
} from 'tds_shared_ui';
import {Search, ManageUser} from '../components/user';
import {NotificationAdminAlerts} from '../components/Notifications/NotificationAdminAlerts';
import {WithAdminTranslations, WithUser} from '../components/hocs';

import './AdminUserPage.css';

export const AdminUserPage = withRouter(WithUser(
  WithAdminTranslations(WithCommonTranslations(
    class extends (TranslationMixin(React.Component)) {
      constructor(props) {
        super(props);

        this.state = {
          selectedUser: '',
        };

        this.handleSelectUser = this.handleSelectUser.bind(this);
        this.handleNewSearch = this.handleNewSearch.bind(this);
        this.handleUpdateUser = this.handleUpdateUser.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
      }

      handleSelectUser(user) {
        this.props.navigate('/admin/user?username=' + user.username,
          {user: user});
      }

      handleNewSearch() {
        this.props.navigate('/admin');
      }

      handleUpdateUser(user) {
        this.setState({
          selectedUser: user,
        });
      }

      handleDismiss(notification) {
        this.props.dismissNotification(notification);
      }

      render() {
        const {selectedUser} = this.state;
        return (
          <div className="adminUserPage">
            <Header level={1}>{this.getTranslation(
              'LABEL_USER_ADMIN')}</Header>

            <p>{this.getTranslation(
              'MESSAGE_USER_ADMIN_INSTRUCTIONS')}</p>

            <Routes>
                  <Route exact path="/" element={
                      <React.Fragment>
                        <NotificationAdminAlerts
                            onDismiss={this.handleDismiss}/>

                        <Search onSelect={this.handleSelectUser}/>
                      </React.Fragment>
                  }/>
                  <Route path="/user/*" element={
                      <div>
                        <Button variant="link" className="p-0 mb-4"
                                onClick={this.handleNewSearch}>
                          <i className="fas fa-search"></i>&nbsp;
                          {this.getTranslation('LABEL_NEW_SEARCH')}</Button>


                        <ManageUser user={selectedUser}
                                    onUpdate={this.handleUpdateUser}/>
                      </div>
                  }/>
            </Routes>

          </div>
        );
      }
    }))));