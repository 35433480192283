import React from 'react';

import {
  Header,
  Alert,
  AlertMixin,
  TranslationMixin,
  WithCommonTranslations,
  withRouter,
  HelperUtils,
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../components/hocs';

import {
  SelectRegion,
  SettingSections,
} from '../components/TDSSettings';

import {RegionConfigService} from "../services";

import './TDSSettings.css';

export const TDSSettings = withRouter(WithCommonTranslations(
    WithAdminTranslations(
        class extends AlertMixin(TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);
            this.alertRef = React.createRef();

            this.state = {
              loading: false,
              selectLoading: false,
              showSettings: false,
              regions: [],
              regionCode: '',
              regionName: '',
              alert: {
                display: false,
                type: 'default',
                message: '',
              },
              formValues: {
                configs: [],
                regionCode: '',
              },
            };

            this.handleRegionChange = this.handleRegionChange.bind(this);
            this.handleSettingsChange = this.handleSettingsChange.bind(this);
            this.getSavedSettings = this.getSavedSettings.bind(this);
            this.setSettings = this.setSettings.bind(this);
            this.onValidationError = this.onValidationError.bind(this);
          }

          componentDidMount() {
            this.setState({
              selectLoading: true,
            });
            RegionConfigService.getRegionList().then((data) => {
              const {regionConfigRegions} = data;
              this.setState({
                regions: regionConfigRegions,
                selectLoading: false,
              });
            }).catch((e) => {
              //Adding empty catch to prevent webpack unhandled exception msg
            });
          }

          getSavedSettings(region) {
            //grabbed the saved settings based on region
            this.setState({
              showSettings: false,
              regionName: "",
              regionCode: ""
            });
            return RegionConfigService.getRegionConfig(region).then((data) => {
              const {configs} = data;
              this.setState({
                showSettings: true,
                alert: {
                  display: false,
                },
                regionName: this.getRegionName(region),
                regionCode: region,
                formValues: {
                  configs: configs,
                  regionCode: region,
                },
              });
            }).catch(e => {
              this.setState({
                alert: {
                  display: true,
                  message: this.getTranslation(
                      'MESSAGE_SELECT_REGION_INSTRUCTION'),
                  type: 'error',
                },
              }, () => {
                //Adding callback for React 18 otherwise it will not work due to async nature of setState
                this.scrollToAlert();
              });
            });
          }

          getRegionName(regionCode) {
            const {regions} = this.state;
            for (let i = 0; i < regions.length; i++) {
              let region = regions[i];
              if (region.value === regionCode) {
                return region.label;
              }
            }
            return '';
          }

          setSettings() {
            //post new updated settings
            const {formValues, regionCode} = this.state;
            this.setState({
              loading: true,
            });
            let newConfig = {...formValues};
            newConfig.regionCode = regionCode;
            RegionConfigService.setRegionConfig(regionCode, newConfig)
                .then((data) => {
                  this.setState({
                    loading: false,
                    alert: {
                      display: true,
                      message: this.getTranslation(
                          'MESSAGE_SUCCESS_SAVE_TDS_SETTINGS'),
                      type: 'success',
                    },
                  });
                  this.scrollToAlert();
                })
                .catch((e) => {
                  this.displayError(e);
                  this.scrollToAlert();
                  this.setState({
                    loading: false,
                  });
                });
          }

          onValidationError = () => {
            this.setState({
              alert: {
                display: true,
                message: this.getTranslation('ERROR_FORM_VALIDATION'),
                type: 'error',
              },
            }, this.scrollToAlert);
          };

          handleRegionChange(name, value) {
            this.setState((prevState) => {
              let newState = {...prevState};
              newState.formValues[name] = value;
              return newState;
            });
          }

          handleSettingsChange(index, value) {
            let array = this.state.formValues.configs.slice();
            array[index].value = value;
            this.setState({
              formValues: {
                configs: array,
                regionCode: this.state.formValues.regionCode,
              },
            });
          }

          scrollToAlert() {
            HelperUtils.scrollToAlert(this.alertRef);
          }

          render() {
            const {regionCode, configs} = this.state.formValues;
            const {loading, selectLoading, regions, showSettings, regionName} = this.state;
            return (
                <div className='tdsSettingsPage'>
                  {this.state.alert.display &&
                  <Alert ref={this.alertRef}
                         type={this.state.alert.type}>{this.state.alert.message}</Alert>}
                  <Header level={1}>{this.getTranslation(
                      'LABEL_TDS_SETTINGS')}</Header>

                  <div className='pt-3 pb-sm-3 text-gray'>{this.getTranslation(
                      'MESSAGE_TDS_SETTINGS_INSTRUCTIONS')}</div>

                  <SelectRegion selectedRegion={regionCode}
                                regions={regions}
                                loading={selectLoading}
                                getSettings={this.getSavedSettings}
                                onChange={this.handleRegionChange}/>

                  {regionCode &&
                  <React.Fragment>
                    <Header level={2} className="region-name">{regionName}</Header>
                    <SettingSections
                        regionConfig={configs}
                        submit={this.setSettings}
                        loading={loading}
                        onValidationError={this.onValidationError}
                        showSettings={showSettings}
                        onChange={this.handleSettingsChange}/>

                  </React.Fragment>}


                </div>
            );
          }
        })));