import React from 'react';
import {
  TranslationMixin,
  WithCommonTranslations,
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../hocs';
import {SettingsForm} from "./SettingsForm";

import './SettingSections.css';

export const SettingSections = WithAdminTranslations(WithCommonTranslations(
        class SettingSections extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);

            this.state = {
              generalSettings: [],
              subscriptionsSettings: [],
              transactionSettings: [],
            };

            this.handleChange = this.handleChange.bind(this);
            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleRadioChange = this.handleRadioChange.bind(this);
            this.createGeneralSettings = this.createGeneralSettings.bind(this);
            this.createSubscriptionsSettings = this.createSubscriptionsSettings.bind(this);
            this.createTransactionSettings = this.createTransactionSettings.bind(this);
          }

          componentDidMount() {
          }

          componentDidUpdate(prevProps) {
            if(this.props.regionConfig !== prevProps.regionConfig){
              this.createGeneralSettings();
              this.createSubscriptionsSettings();
              this.createTransactionSettings();
            }
          }

          handleChange = (name) => (value) => {
            let index = this.getChangeIndex(name);
            this.props.onChange(index, value);
          };

          handleInputChange = (event) => {
            const target = event.target;
            const value = target.value;
            const key = target.name;
            let index = this.getChangeIndex(key);

            this.props.onChange(index, value);
          };

          handleRadioChange(key, strValue) {
            let index = this.getChangeIndex(key);
            let value = strValue;

            if (strValue === 'true') {
              value = true;
            } else if (strValue === 'false') {
              value = false;
            }
            this.props.onChange(index, value);
          }

          getChangeIndex(key){
            const {regionConfig} = this.props;
            let i;
            regionConfig.forEach(function(setting, index) {
              if(setting.key === key){
                i = index;
              }
            })
            return i;
          }

          createGeneralSettings(){
            const {regionConfig} = this.props;
            let array = this.state.generalSettings.slice();
            regionConfig.forEach(function(setting) {
              if(setting.displaySection === 'general'){
                let index = setting.displayOrder - 1;
                array[index] = setting;
              }
            })
            this.setState({generalSettings: array});
          }

          createSubscriptionsSettings(){
            const {regionConfig} = this.props;
            let array = this.state.subscriptionsSettings.slice();
            regionConfig.forEach(function(setting) {
              if(setting.displaySection === 'subscriptions'){
                let index = setting.displayOrder - 1;
                array[index] = setting;
              }
            })
            this.setState({subscriptionsSettings: array});
          }

          createTransactionSettings(){
            const {regionConfig} = this.props;
            let array = this.state.transactionSettings.slice();
            regionConfig.forEach(function(setting) {
              if(setting.displaySection === 'transactional'){
                let index = setting.displayOrder - 1;
                array[index] = setting;
              }
            })
            this.setState({transactionSettings: array});
          }

          render() {
            const {submit, loading, showSettings, onValidationError} = this.props;
            const {generalSettings, subscriptionsSettings, transactionSettings} = this.state;

            return (
                <div>
                  {showSettings &&
                    <SettingsForm settingsList={generalSettings}
                                  subscriptionsSettings={subscriptionsSettings}
                                  transactionSettings={transactionSettings}
                                  handleInputChange={this.handleInputChange}
                                  handleRadioChange={this.handleRadioChange}
                                  submit={submit}
                                  onValidationError={onValidationError}
                                  loading={loading}
                                  showSettings={showSettings}
                    />}

                </div>
            );
          }
        }));