import React from 'react';
import {
  DatePicker,
  Select,
  TranslationMixin,
  WithCommonTranslations,
  WithFormValidation,
  FormInput, Button, LoadingIcon,
} from 'tds_shared_ui';
import {WithAdminTranslations} from "../hocs";

import './KeycodeProperties.css';

export const KeycodeProperties = WithAdminTranslations(WithFormValidation(
    WithCommonTranslations(
        class KeycodeProperties extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);

            this.state = {
              beginDate: null,
              endDate: null,
            };
            this.handleSelectChange = this.handleSelectChange.bind(this);
            this.handleInputChange = this.handleInputChange.bind(this);
          }

          static getDerivedStateFromProps(props) {
            let beginDate = null;
            let endDate = null;
            let fv = props.formValues;
            if (fv) {
              if (fv.startDate) {
                beginDate = fv.startDate;
              }
              if (fv.endDate) {
                endDate = fv.endDate;
              }
            }

            return {
              beginDate: beginDate,
              endDate: endDate,
            };
          }

          handleInputChange = (event) => {
            const target = event.target;
            const value = target.type === 'checkbox' ?
                target.checked :
                target.value;
            const name = target.name;

            this.props.onChange(name, value);
          };

          handleSelectChange = (name) => (value) => {
            this.props.onChange(name, value);
          };

          getKeycodeOptions() {
            let options = [
              {
                id: 'keycodelog',
                value: 'Keycode Log',
                label: this.getTranslation('LABEL_KEYCODE_LOG'),
              },
              {
                id: 'avgkeycodelog',
                value: 'AVG Keycode Log',
                label: this.getTranslation('LABEL_AVG_KEYCODE_LOG'),
              }];

            return options;
          }

          getDestinationOptions() {
            let options = [
              {
                id: 'all',
                value: 'all',
                label: this.getTranslation('LABEL_ALL'),
              },
              {
                id: 'ALOA',
                value: 'ALOA',
                label: this.getTranslation('LABEL_ALOA'),
              },
              {
                id: 'NICB',
                value: 'NICB',
                label: this.getTranslation('LABEL_NICB'),
              },
              {
                id: 'AASRA',
                value: 'AASRA',
                label: this.getTranslation('LABEL_AASRA'),
              }];

            return options;
          }

          render() {
            const isKeyCodeLog = this.props.formValues.whichReport ===
                'Keycode Log';

            return (
                <div className="keycodeSearch">
                  <div className="d-flex flex-row flex-wrap align-items-center">
                    <div className="tds-keycode-type-select">
                      <Select
                          label={this.getTranslation('HEADER_SELECT_KEYCODE')}
                          name="whichReport"
                          id="whichReport"
                          required={true}
                          options={this.getKeycodeOptions()}
                          value={this.props.formValues.whichReport}
                          readOnly={this.props.readOnly}
                          error={this.props.isValidated &&
                          !this.props.formValues.whichReport}
                          errorMessage={this.getTranslation(
                              'ERROR_REQUIRED')}
                          onChange={this.handleSelectChange('whichReport')}/>
                    </div>

                    {isKeyCodeLog &&
                    <React.Fragment>
                      <div className="tds-destination-select">
                        <Select
                            label={this.getTranslation(
                                'HEADER_DESTINATION_TYPE')}
                            name="reportType"
                            id="reportType"
                            removable={true}
                            options={this.getDestinationOptions()}
                            value={this.props.formValues.reportType}
                            readOnly={this.props.readOnly}
                            required={true}
                            error={this.props.isValidated &&
                            !this.props.formValues.reportType}
                            errorMessage={this.getTranslation(
                                'ERROR_REQUIRED')}
                            onChange={this.handleSelectChange('reportType')}/>

                      </div>

                      <div className="tds-keycode-input">
                        <FormInput
                            label={this.getTranslation('HEADER_USERNAME')}
                            value={this.props.formValues.username}
                            name="username"
                            id="username"
                            maxLength={50}
                            required={false}
                            displayError={this.props.isValidated}
                            onChange={this.handleInputChange}/>
                      </div>

                      <div className="tds-keycode-input">
                        <FormInput label={this.getTranslation('HEADER_LOCKSMITH')}
                                   value={this.props.formValues.vsc}
                                   name="vsc"
                                   id="vsc"
                                   maxLength={100}
                                   displayError={this.props.isValidated}
                                   onChange={this.handleInputChange}/>
                      </div>

                      <div className="tds-keycode-input">
                        <FormInput label={this.getTranslation('LABEL_VIN')}
                                   value={this.props.formValues.vin}
                                   name="vin"
                                   id="vin"
                                   maxLength={17}
                                   displayError={this.props.isValidated}
                                   onChange={this.handleInputChange}/>

                      </div>
                    </React.Fragment>}

                    <div className="tds-datepicker-option">
                      <div className="col-tds-datepicker">
                        <DatePicker
                            label={this.getTranslation('HEADER_START_DATE')}
                            dateFormat="MM/dd/yyyy"
                            autoComplete="off"
                            className="tds-date-width"
                            startDate={this.state.beginDate}
                            endDate={this.state.endDate}
                            ref={this.props.beginRef}
                            invalidDateMsg={this.getTranslation(
                                'ERROR_INVALID_DATE')}
                            required={true}
                            id={'startDate'}
                            displayError={this.props.isValidated}
                            customError={this.props.beginDateErrorMsg}
                            onChange={(startDate) =>
                                this.props.onChange('startDate', startDate)}
                            selected={this.state.beginDate}/>
                      </div>
                      <div className="col-tds-datepicker">
                        <DatePicker label={this.getTranslation('HEADER_END_DATE')}
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    className="tds-date-width"
                                    startDate={this.state.beginDate}
                                    endDate={this.state.endDate}
                                    ref={this.props.endRef}
                                    invalidDateMsg={this.getTranslation(
                                        'ERROR_INVALID_DATE')}
                                    required={true}
                                    id={'endDate'}
                                    displayError={this.props.isValidated}
                                    customError={this.props.endDateErrorMsg}
                                    onChange={(endDate) =>
                                        this.props.onChange('endDate', endDate)}
                                    selected={this.state.endDate}/>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="float-left">
                        <Button variant="link" type="button"
                                onClick={this.props.onCancel}>
                          {this.getTranslation('BUTTON_RESET')}</Button>

                        <Button className="float-right"
                                variant="primary" type='submit'
                                disabled={this.props.loading}>
                          <LoadingIcon display={this.props.loading}/>
                          {this.getTranslation('BUTTON_SEARCH')}</Button>
                      </div>
                    </div>
                  </div>
                </div>
            );
          }
        })));