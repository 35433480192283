import React from 'react';
import PropTypes from 'prop-types';

import {
    Button,
    TranslationMixin,
    Table,
    WithCommonTranslations,
} from 'tds_shared_ui';
import {WithAdminTranslations} from "../hocs";
import {ReportsService} from "../../services";

export const ReportsTable = WithAdminTranslations(
    WithCommonTranslations(class ReportsTable extends TranslationMixin(
        React.Component) {

        constructor(props) {
            super(props);

            this.handleExportClick = this.handleExportClick.bind(this);
        }

      getColumns(data) {
        const {decorateColumns} = this.props;
        let cols = [];

        for (let i = 0; i < data.length; i++) {
          cols.push({
            header: data[i],
            accessor: i,
          });
        }

        if (decorateColumns) {
          cols = decorateColumns(cols);
        }

        return cols;

      }

      getAttributes(attributes) {
        let jsx = [];
        for (let prop in attributes) {
          jsx.push(<div key={prop}><strong>{prop}</strong>: {attributes[prop]}
          </div>);
        }
        return jsx;
      }

      handleExportClick() {
        return ReportsService.download(this.props.exportUrl, this.props.tableData.map(obj => obj[0]))
            .catch(e => {
              let {onError} = this.props;
              if (onError) {
                let msg = this.getTranslation('ERROR_GENERIC');
                if (e != null) {
                  if (typeof e === 'string') {
                    msg = e;
                  } else if (e.hasOwnProperty('translatedMessage')) {
                    if (e.translatedMessage) {
                      msg = e.translatedMessage;
                    }
                  }
                }
                onError(msg);
              }
            });
      }

      render() {
        const {exportUrl, tableData, headers} = this.props;
        const cols = this.getColumns(headers);
        const attributes = this.getAttributes(this.props.attributes);
        let url = null;
        if (exportUrl) {
          url = <Button type="button"
                        variant="link"
                        onClick={this.handleExportClick}>
            <i className="fas fa-download"></i>&nbsp;
            {this.getTranslation('BUTTON_EXPORT')}</Button>;
        }

        return (
            <div>
              <Table filterId="tableFilter"
                     columns={cols}
                     tableMessage={
                       <React.Fragment>{attributes}{url}</React.Fragment>}
                     rows={tableData}
              />
            </div>
        );
      }
    }));

ReportsTable.propTypes = {
  headers: PropTypes.array.isRequired,
  attributes: PropTypes.object,
  exportUrl: PropTypes.string,
  tableData: PropTypes.array.isRequired,
  decorateColumns: PropTypes.func,
};