import React from 'react';

import {
  Alert,
  AlertMixin,
  Button,
  ConfirmationModal,
  LoadingIcon,
  Table,
  TranslationMixin,
  WithCommonTranslations,
} from 'tds_shared_ui';
import {WithAdminTranslations} from "../../hocs";
import {SubscriptionsService, OrdersService} from "../../../services";
import {AddSubscriptionDetailsModal} from './AddSubscriptionDetailsModal';

import './SubscriptionDetail.css';
import {OrderInvoiceModal} from '../orders';

export const SubscriptionDetail = WithCommonTranslations(
    WithAdminTranslations(
        class SubscriptionDetail extends AlertMixin(
            TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);

            this.cancelToken = SubscriptionsService.getCancelToken().source();

            this.state = {
              data: null,
              rowData: null,
              loading: false,
              displayConfirm: false,
              confirmComment: '',
              showAddSubDetailModal: false,
              showOrderDetails: false,
              orderDetails: null,
              handleConfirm: () => {
              },
              alert: {
                display: false,
                type: 'default',
                message: '',
              },
            };

            this.handleCancel = this.handleCancel.bind(this);
            this.handleCommentChange = this.handleCommentChange.bind(this);
            this.handleAddSuccess = this.handleAddSuccess.bind(this);
            this.handleCloseAddSubDetail = this.handleCloseAddSubDetail.bind(
                this);
            this.handleShowAddSubDetail = this.handleShowAddSubDetail.bind(
                this);
            this.handleShowOrderDetails = this.handleShowOrderDetails.bind(this);
            this.handleCloseOrderDetails = this.handleCloseOrderDetails.bind(this);
          }

          componentDidMount() {
            this.setState({
              loading: true,
            });

            this.fetchSubs();
          }

          componentWillUnmount() {
            if (this.cancelToken) {
              this.cancelToken.cancel({unmounted: true});
            }
          }

          fetchSubs() {
            SubscriptionsService.get(this.props.username,
                this.props.subData, {cancelToken: this.cancelToken.token})
                .then((data) => {
                  let {subList, slots, message} = data;
                  if(message){
                    this.displayAlert(message, 'warning');
                  }
                  this.setState({
                    data: data,
                    rowData: subList || slots,
                  });
                }).catch((e) => {
              this.displayError(e);
            }).finally(() => {
              this.setState({
                loading: false,
              });
            });
          }

          handleCancel() {
            this.setState({
              displayConfirm: false,
            });
          }

          handleCommentChange(event) {
            const target = event.target;

            this.setState({
              confirmComment: target.value,
            });
          }

          handleDelete = (id, type) => () => {
            this.setState({
              displayConfirm: true,
              confirmComment: '',
              handleConfirm: () => {
                //TODO add comment this.state.confirmComment
                return SubscriptionsService.delete(id, type,
                    this.state.confirmComment,
                    {cancelToken: this.cancelToken.token}).then((data) => {
                  this.setState({
                    data: data,
                    rowData: data.subList || data.slots,
                  });
                  this.props.onUpdate();
                }).catch(e => {
                  this.displayError(e);
                }).finally(() => {
                  this.setState({
                    displayConfirm: false,
                  });
                });
              },
            });
          };

          handleAddSuccess(data) {
            let {subList, slots, message} = data;
            if(message){
              this.displayAlert(message, 'warning');
            }
            this.setState({
              data: data,
              rowData: subList || slots,
              showAddSubDetailModal: false,
            });
            this.props.onUpdate();
          }

          handleShowAddSubDetail() {
            this.setState({
              showAddSubDetailModal: true,
            });
          }

          handleCloseAddSubDetail() {
            this.setState({
              showAddSubDetailModal: false,
            });
          }

          handleShowOrderDetails(orderNumber){
            return OrdersService.get(orderNumber).then(d => {
              this.setState({
                showOrderDetails: true,
                orderDetails: d
              })
            }).catch(e => this.displayError(e))
          }

          handleCloseOrderDetails() {
            this.setState({
              showOrderDetails: false,
            });
          }

          getTimeColumns() {
            return [
              {
                header: this.getTranslation('LABEL_ORDER_TYPE'),
                accessor: 'type',
              }, {
                header: this.getTranslation('LABEL_ORDER_NUMBER'),
                accessor: 'orderId',
                render: (rowData) => {
                  let jsx = [];

                  if (rowData.orderId) {
                    jsx.push(<Button key="order" variant="link"
                                     onClick={() => this.handleShowOrderDetails(rowData.orderId)}
                                     type="button">{rowData.orderId}</Button>);
                  }

                  return jsx;
                },
              },{
                header: this.getTranslation('LABEL_DURATION'),
                accessor: 'duration',
              }, {
                header: this.getTranslation('LABEL_START_DATE'),
                accessor: 'startDate',
              }, {
                header: this.getTranslation('LABEL_END_DATE'),
                accessor: 'endDate',
              }, {
                header: this.getTranslation('LABEL_NOTES'),
                accessor: 'adminNotes',
                render: (rowData) => {
                  let {adminNotes, sysNotes} = rowData;
                  if (adminNotes && sysNotes) {
                    return <React.Fragment>{adminNotes}<br/>{sysNotes}
                    </React.Fragment>;
                  } else if (adminNotes) {
                    return adminNotes;
                  } else if (sysNotes) {
                    return sysNotes;
                  } else {
                    return '';
                  }
                },
              }, {
                header: this.getTranslation('LABEL_ACTIONS'),
                accessor: 'actions',
                render: (rowData) => {
                  let jsx = [];

                  if (['Current', 'Future'].includes(rowData.status)) {
                    jsx.push(<Button key="cancel" variant="link"
                                     onClick={this.handleDelete(rowData.id,
                                         'time')}
                                     type="button">
                      {this.getTranslation('BUTTON_CANCEL')}</Button>);
                  }
                  return jsx;
                },
              }];

          }

          getVinColumns() {
            return [
              {
                header: this.getTranslation('LABEL_STATUS'),
                accessor: 'displayStatus',
              }, {
                header: this.getTranslation('LABEL_VIN'),
                accessor: 'vin',
              }, {
                header: this.getTranslation('LABEL_EVENTS_LEFT'),
                accessor: 'eventsLeft',
              }, {
                header: this.getTranslation('LABEL_ORDER_TYPE'),
                accessor: 'type',
              }, {
                header: this.getTranslation('LABEL_ORDER_NUMBER'),
                accessor: 'orderId',
                render: (rowData) => {
                  let jsx = [];

                  if (rowData.orderId) {
                    jsx.push(<Button key="order" variant="link"
                                     onClick={() => this.handleShowOrderDetails(rowData.orderId)}
                                     type="button">{rowData.orderId}</Button>);
                  }

                  return jsx;
                },
              }, {
                header: this.getTranslation('LABEL_ACTIONS'),
                accessor: 'actions',
                render: (rowData) => {
                  let jsx = [];

                  if (['Available', 'Active'].includes(rowData.status)) {
                    jsx.push(<Button key="cancel" variant="link"
                                     onClick={this.handleDelete(rowData.id,
                                         'vin\\slot')}
                                     type="button">
                      {this.getTranslation('LABEL_DELETE')}</Button>);
                  }
                  return jsx;
                },
              }];
          }

          getDownloadColumns() {
            return [
              {
                header: this.getTranslation('LABEL_STATUS'),
                accessor: 'statusDesc'
              }, {
                header: this.getTranslation('LABEL_ORDER_TYPE'),
                accessor: 'type',
              }, {
                header: this.getTranslation('LABEL_ORDER_DATE'),
                accessor: 'subscribedDate',
              }, {
                header: this.getTranslation('LABEL_ORDER_NUMBER'),
                accessor: 'orderId',
                render: (rowData) => {
                  let jsx = [];

                  if (rowData.orderId) {
                    jsx.push(<Button key="order" variant="link"
                                     onClick={() => this.handleShowOrderDetails(rowData.orderId)}
                                     type="button">{rowData.orderId}</Button>);
                  }

                  return jsx;
                },
              },{
                header: this.getTranslation('LABEL_ACTIONS'),
                accessor: 'actions',
                render: (rowData) => {
                  let jsx = [];

                  if (['Available', 'Active'].includes(rowData.status)) {
                    jsx.push(<Button key="cancel" variant="link"
                                    onClick={this.handleDelete(rowData.id,
                                        'download')}
                                    type="button">
                      {this.getTranslation('LABEL_DELETE')}</Button>);
                  }
                  return jsx;
                },
              }];
          }

          getRowClass(rowData) {
            if (['Current'].includes(rowData.status)) {
              return 'row-current';
            } else if (rowData.status === 'Past') {
              return 'row-past';
            }
          }

          renderTimeSummary(data) {
            if (data) {
              let summaryData = [
                {
                  label: this.getTranslation('LABEL_TOTAL_FREE_DAYS'),
                  value: data.totalFreeTimeGiven,
                }, {
                  label: this.getTranslation('LABEL_REMAINING_DAYS'),
                  value: data.totalRemainingTime,
                }, {
                  label: this.getTranslation('LABEL_EXPIRATION_DATE'),
                  value: data.expirationDate,
                }];
              return this.renderSummaryTable(summaryData);
            }
          }

          renderSummaryTable(summaryData) {
            return (
                <table className="summary-table">
                  <tbody>
                  {summaryData.map((item) => (
                      <tr key={item.label}>
                        <td>{item.label}:</td>
                        <td className="text-right">{item.value}</td>
                      </tr>
                  ))}
                  </tbody>
                </table>
            );
          }

          render() {
            let {subData} = this.props;
            const {showOrderDetails, orderDetails} = this.state;
            let columns = null;
            let summaryData = null;
            let tableMessage = null;
            if (subData) {
              switch (subData.productType) {
                case 'TIME':
                  columns = this.getTimeColumns();
                  summaryData = this.renderTimeSummary(this.state.data);
                  break;
                case 'VIN':
                  columns = this.getVinColumns();
                  tableMessage = this.state.data ?
                      this.getTranslation('LABEL_NUMBER_AVAILABLE') + ': ' +
                      this.state.data.numberAvailable :
                      '';
                  break;
                case 'DOWNLOAD':
                  columns = this.getDownloadColumns();
                  break;
                default:
                  columns = null;
              }
            }
            return (
                <div className="subscription-detail">
                  {this.state.alert.display &&
                  <Alert ref={this.alertRef}
                         type={this.state.alert.type}>{this.state.alert.message}</Alert>}
                  {this.state.loading ? <div>
                        <LoadingIcon display={true}/> {this.getTranslation(
                      'MESSAGE_LOADING')}
                      </div>
                      :
                      <React.Fragment>
                        <Table columns={columns} rows={this.state.rowData}
                               getRowClass={this.getRowClass}
                               tableMessage={tableMessage}
                               filterId="subDataFilter"/>
                        <Button variant="link"
                                onClick={this.handleShowAddSubDetail}>
                          {this.getTranslation(
                              'BUTTON_ADD_SUBSCRIPTION')}</Button>
                        {summaryData}
                        <ConfirmationModal
                            title={this.getTranslation('HEADER_CONFIRM')}
                            content={<React.Fragment><p>{this.getTranslation(
                                'MESSAGE_CONFIRM_DELETE_SUB')}</p>
                              <div className="mb-1">
                              <textarea className="w-100"
                                        onChange={this.handleCommentChange}
                                        placeholder={this.getTranslation(
                                            'MESSAGE_OPTIONAL_COMMENT')}
                                        maxLength="200"
                                        value={this.state.confirmComment}/>
                              </div>
                            </React.Fragment>
                            }
                            open={this.state.displayConfirm}
                            cancelText={this.getTranslation('BUTTON_NO')}
                            okText={this.getTranslation('BUTTON_YES')}
                            onCancel={this.handleCancel}
                            onSuccess={this.state.handleConfirm}/>
                        <AddSubscriptionDetailsModal
                            product={subData}
                            refreshType={subData.productType}
                            username={this.props.username}
                            open={this.state.showAddSubDetailModal}
                            onCancel={this.handleCloseAddSubDetail}
                            onSuccess={this.handleAddSuccess}/>
                        {showOrderDetails &&
                        <OrderInvoiceModal open={showOrderDetails}
                                           orderDetails={orderDetails}
                                           onClose={this.handleCloseOrderDetails}/>}
                      </React.Fragment>
                  }

                </div>
            );
          }
        }));