import React from 'react';
import {
    Alert, AlertMixin,
    Button,
    Header,
    LoadingIcon,
    TranslationMixin,
    WithCommonTranslations,
} from 'tds_shared_ui';
import {DocumentService} from "../../services";
import {WithAdminTranslations} from '../hocs';

import './Documents.css';

export const Documents = WithAdminTranslations(WithCommonTranslations(
    class Documents extends AlertMixin(TranslationMixin(React.Component)) {
        constructor(props) {
            super(props);

            this.fileInput = React.createRef();

        this.acceptedFileTypes = "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,image/jpeg,image/png";

        this.state = {
          documents: null,
          file: '',
          loading: true,
          alert: {
            display: false,
            type: 'default',
            message: '',
          },
        };

        this.getUrl = this.getUrl.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
      }

      componentDidMount() {
        const {username} = this.props;
        if (username) {
          DocumentService.getDocuments(username).then((data) => {
            this.setState({
              documents: data,
              loading: false,
            });
          }).catch(e => {
            this.displayError(e);
            this.setState({
              loading: false,
            });
          });
        }
      }

      handleClick(doc) {
        DocumentService.getDocument(doc.key);
      }

      handleUpload() {
        const {username} = this.props;
        let file = this.fileInput.current.files[0];
        this.clearError();

        if (file) {
          //limit to 10MB
          if (file.size > 10000000) {
            this.displayAlert(this.getTranslation("ERROR_FILE_TOO_BIG"),
                "error")
          } else {
            let name = file.name;
            return DocumentService.upload(username, name, file)
                .then(data => {
                  this.setState({
                    documents: data,
                  });
                  this.fileInput.current.value = '';
                })
                .catch(e => {
                  this.displayError(e);
                });
          }
        }
      }

      handleDelete(key) {
        const {username} = this.props;
        this.clearError();

        return DocumentService.delete(username, key).then(data => {
          this.setState({
            documents: data,
          });
        }).catch(e => {
          this.displayError(e);
        });
      }

      getUrl(key) {
        return DocumentService.getDocumentUrl(key);
      }

      render() {
        const {documents, loading} = this.state;
        return (
            <div className="tds-documents d-flex flex-column">

              <LoadingIcon display={loading}
                           size="lg"
                           text={this.getTranslation('MESSAGE_LOADING')}/>

              {this.state.alert.display &&
              <Alert ref={this.alertRef}
                     type={this.state.alert.type}>{this.state.alert.message}</Alert>}

              {documents &&
              <div className="documents-list flex-grow-1">
                <table className="responsive-table">
                  <thead>
                  <tr>
                    <th>{this.getTranslation('LABEL_DOCUMENT')}</th>
                    <th>{this.getTranslation('LABEL_UPLOADED_BY')}</th>
                    <th>{this.getTranslation('LABEL_UPLOADED_DATE')}</th>
                    <th>{this.getTranslation('LABEL_ACTIONS')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {documents.map((doc) => (
                      <tr key={doc.key}>
                        <td>
                          <Button variant="link"
                                  onClick={() => this.handleClick(doc)}>
                            {doc.documentTitle}</Button>
                        </td>
                        <td>{doc.uploadedBy}</td>
                        <td>{doc.uploadedDate}</td>
                        <td><Button type="button"
                                    className="pl-0"
                                    variant="link"
                                    onClick={() => this.handleDelete(
                                        doc.key)}>
                          {this.getTranslation('BUTTON_DELETE')}</Button></td>
                      </tr>
                  ))}
                  </tbody>
                </table>
              </div>
              }

              <div className="">
                <Header level={3}>{this.getTranslation(
                    'TITLE_ADD_DOCUMENTS')}</Header>
                <input type="file" ref={this.fileInput}
                       accept={this.acceptedFileTypes}/>
                <Button type="button" variant="link"
                        onClick={this.handleUpload}>
                  {this.getTranslation('LABEL_UPLOAD')}</Button>
              </div>
            </div>
        );
      }
    }));