import React from 'react';
import {
    Button, LoadingIcon,
    Select,
    TranslationMixin,
    WithCommonTranslations,
    WithFormValidation,
} from 'tds_shared_ui';
import {PromoCodeService} from "../../services";
import {WithAdminTranslations} from '../hocs';

import './UserStatusProperties.css';

export const UserStatusProperties = WithAdminTranslations(WithFormValidation(
    WithCommonTranslations(class extends TranslationMixin(React.Component) {
        constructor(props) {
            super(props);

            this.state = {
                regionOptions: [],
            };
            this.handleSelectChange = this.handleSelectChange.bind(this);
            this.fetchOptions = this.fetchOptions.bind(this);
        }

        componentDidMount() {
            this.fetchOptions();
        }

        fetchOptions() {
            PromoCodeService.getDefaultOptions().then((data) => {
                this.setState({
                    regionOptions: data.regionOptionItemList,
                });
            }).catch((e) => {
                this.props.onError(e);
            });
        }

        handleSelectChange = (name) => (value) => {
            this.props.onChange(name, value);
        };

        getUserOptions() {
            let options = [
                {
                    id: 'Disabled',
                    value: 'Disabled',
                    label: this.getTranslation('LABEL_DISABLED_USERS_REPORT'),
                },
                {
                    id: 'Watch List',
                    value: 'Watch List',
                    label: this.getTranslation('LABEL_WATCH_LIST'),
                }];

            return options;
        }

        render() {
            const {regionOptions} = this.state;
            const {isValidated, formValues, loading, onCancel, readOnly} = this.props;
            return (
                <div className="userStatusSearch">
                    <div className="d-flex flex-row flex-wrap align-items-center">
                        <div className="tds-user-status-select">
                            <Select label={this.getTranslation('HEADER_SELECT_USER')}
                                    name="whichReport"
                                    id="whichReport"
                                    required={true}
                                    options={this.getUserOptions()}
                                    value={formValues.whichReport}
                                    readOnly={readOnly}
                                    error={isValidated && !formValues.whichReport}
                                    errorMessage={this.getTranslation(
                                        'ERROR_REQUIRED')}
                                    onChange={this.handleSelectChange('whichReport')}/>
                        </div>

                        <div className="tds-region-select">
                            <Select label={this.getTranslation('HEADER_REGION')}
                                    name="regions"
                                    id="regions"
                                    required={true}
                                    removable={true}
                                    options={regionOptions}
                                    value={formValues.regions}
                                    readOnly={readOnly}
                                    error={isValidated && !formValues.regions}
                                    errorMessage={this.getTranslation(
                                        'ERROR_REQUIRED')}
                                    onChange={this.handleSelectChange('regions')}/>
                        </div>


                        <div className="col-auto">
                            <div className="float-left">
                                <Button onClick={onCancel}
                                        variant='link'
                                        type='button'>
                                    {this.getTranslation('BUTTON_RESET')} </Button>

                                <Button variant='primary'
                                        disabled={loading}
                                        type='submit'>
                                    <LoadingIcon display={loading}/>
                                    {this.getTranslation('BUTTON_SEARCH')} </Button>
                            </div>
                        </div>
                    </div>
                </div>

            );
        }
    })));