import React from 'react';

import {
    Button,
    OrderInvoiceModal as CommonOrderInvoiceModal,
    TranslationMixin,
    withRouter,
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../../hocs';

import './OrderInvoiceModal.css';

export const OrderInvoiceModal = withRouter(
    WithAdminTranslations(
        class extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);

            this.handleClose = this.handleClose.bind(this);
            this.handleRefund = this.handleRefund.bind(this);
          }

          handleClose() {
            this.props.onClose();
          }

          handleRefund() {
            const {orderDetails} = this.props;
            const orderNumber = orderDetails.orderNumber;

            this.props.navigate('/order/' + orderNumber,
                {orderDetails: orderDetails});
          }

          render() {
            const {orderDetails, open, onClose} = this.props;
            let leftButton = null;
            if (orderDetails.refundable) {
              leftButton =
                  <Button variant="link" type="button"
                          onClick={this.handleRefund}>
                    {this.getTranslation('BUTTON_REFUND')}
                  </Button>;
            }

            return (
                <CommonOrderInvoiceModal open={open}
                                         onClose={onClose}
                                         orderDetails={orderDetails}
                                         leftButton={leftButton}/>
            );
          }
        },
    ));