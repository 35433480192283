import React from 'react';
import {
    Alert,
    AlertMixin,
    Button,
    Checkbox,
    Header,
    HelperUtils,
    TranslationMixin,
    WithCommonTranslations,
    withRouter,
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../../hocs';
import {OrdersService} from "../../../services";

import './RefundConfirmation.css';

export const RefundConfirmation = withRouter(
    WithAdminTranslations(WithCommonTranslations(
        class Refund extends AlertMixin(TranslationMixin(React.Component)) {

            constructor(props) {
                super(props);
                this.alertRef = React.createRef();

            this.state = {
              comment: '',
              isComplete: false,
              isSendEmailChecked: true,
              alert: {
                display: false,
                type: 'default',
                message: '',
              },
            };

            this.handleRefund = this.handleRefund.bind(this);
            this.handleGoToOrders = this.handleGoToOrders.bind(this);
            this.handleCommentChange = this.handleCommentChange.bind(this);
            this.handleEmailCheckbox = this.handleEmailCheckbox.bind(this);
          }

          scrollToAlert() {
            HelperUtils.scrollToAlert(this.alertRef);
          }

          handleCommentChange(event) {
            const target = event.target;

            this.setState({
              comment: target.value,
            });
          }

          handleGoToOrders() {
            const {refundDetails} = this.props;
            this.props.navigate(
                '/admin/user/orders?username=' + refundDetails.username);
          }

          handleRefund() {
            const orderNumber = this.props.refundDetails.orderNumber;
            return OrdersService.processRefund(orderNumber, this.state.isSendEmailChecked, this.state.comment)
                .then(d => {
                  this.setState({
                    isComplete: true,
                  });
                  this.displayAlert(
                      this.getTranslation('MESSAGE_REFUND_SUCCESS'),
                      'success', this.scrollToAlert);
                })
                .catch(e => this.displayError(e));
          }

          handleEmailCheckbox() {
              this.setState(prevState => ({
                  isSendEmailChecked: !prevState.isSendEmailChecked
              }));
          }

          render() {
            const {isComplete, isSendEmailChecked} = this.state;
            const {refundDetails, onGoToOrderHistory, onBack} = this.props;
            let pendingRefund = refundDetails.receiptRefunds[0] || {};
            return (
                <div className="refund-confirmation">
                  {this.state.alert.display &&
                  <Alert ref={this.alertRef}
                         type={this.state.alert.type}>{this.state.alert.message}
                  </Alert>}

                  <div className="row mt-3">
                    <div className="col-lg-8  col-xl-6">
                      <div className="border-bottom mb-3">
                        <Header level={2}>{this.getTranslation(
                            'HEADER_REFUND_TOTAL')}</Header>
                      </div>
                      <div className="mb-5">
                        <table className="total-table">
                          <tbody>
                          <tr>
                            <td>{this.getTranslation(
                                'LABEL_ORDER_SUBTOTAL_REFUNDED')}</td>
                            <td className="text-right">-{pendingRefund.subtotalRefunded}</td>
                          </tr>
                          <tr>
                            <td>{this.getTranslation('LABEL_TAX_REFUNDED')}</td>
                            <td className="text-right">-{pendingRefund.taxRefunded}</td>
                          </tr>
                          <tr className="border-top refund-row">
                            <td>{this.getTranslation('LABEL_TOTAL_REFUND')}</td>
                            <td className="text-right">-{pendingRefund.totalRefunded}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="border-bottom mb-3">
                        <Header level={2}>{this.getTranslation(
                            'LABEL_PAYMENT_METHOD')}</Header>
                      </div>
                      <div className="mb-5">
                        {refundDetails.cardBrand} {this.getTranslation(
                          'LABEL_ENDING_IN')} {refundDetails.cardLast4Digits}
                      </div>

                      <div className="border-bottom mb-3">
                        <Header level={2}>{this.getTranslation(
                            'HEADER_EMAIL_CONFIRMATION')}</Header>
                      </div>
                      <div className="mb-5">
                        {refundDetails.email}

                        {!isComplete &&
                          <div className="float-right">
                            <Checkbox
                              checked={isSendEmailChecked}
                              onChange={this.handleEmailCheckbox}
                              checkboxLabel={this.getTranslation(
                                'CHECKBOX_SEND_EMAIL')}
                            />
                          </div>
                        }
                      </div>


                      <div className="border-bottom mb-3">
                        <Header level={2}>{this.getTranslation(
                            'TITLE_COMMENTS')}</Header>
                      </div>

                      <textarea className="w-100"
                                readOnly={isComplete}
                                onChange={this.handleCommentChange}
                                placeholder={this.getTranslation(
                                    'MESSAGE_OPTIONAL_COMMENT')}
                                maxLength="200"
                                value={this.state.comment}/>

                      <div className="mt-3">
                        <Button variant="link" type="button" className="pl-0"
                                onClick={onGoToOrderHistory}>
                          {this.getTranslation('BUTTON_GO_TO_ORDER_HISTORY')}
                        </Button>

                        {!isComplete &&
                        <div className="float-right">
                          <Button variant="outline-secondary" type="button"
                                  onClick={onBack}>
                            {this.getTranslation('BUTTON_BACK')}
                          </Button>

                          <Button variant="primary" onClick={this.handleRefund}>
                            {this.getTranslation(
                                'BUTTON_CONFIRM_REFUND')}</Button>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
            );
          }
        })));