import React from 'react';

import {
    AlertMixin,
    Header,
    LoadingIcon,
    Modal,
    WithCommonTranslations,
    TranslationMixin, Alert,
} from 'tds_shared_ui';
import {WithAdminTranslations} from "../../hocs";
import {SubscriptionsService} from "../../../services";
import {AddSubscriptionDetailsForm} from './AddSubscriptionDetailsForm';

import './AddSubscriptionDetailsModal.css';

export const AddSubscriptionDetailsModal =
    WithAdminTranslations(WithCommonTranslations(
        class AddSubscriptionDetailsModal extends AlertMixin(
            TranslationMixin(React.Component)) {
            constructor(props) {
                super(props);

            this.cancelToken = SubscriptionsService.getCancelToken().source();

            this.state = {
              skuOptions: null,
              loading: false,
              saving: false,
              formValues: {
                sku: null,
                quantity: 1,
                comments: ""
              },
              alert: {
                display: false,
                type: 'default',
                message: '',
              },
            };

            this.handleChange = this.handleChange.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
            this.handleQuantityChange = this.handleQuantityChange.bind(this);
          }

          componentDidMount() {
            let {product, username} = this.props;
            if (product && product.id && username) {
              this.fetchSkuOptions();
            }
          }

          componentWillUnmount() {
            if (this.cancelToken) {
              this.cancelToken.cancel({unmounted: true});
            }
          }

          componentDidUpdate(prevProps) {
            let {product, open} = this.props;
            let prevProduct = prevProps.product;

            if (open !== prevProps.open){
              this.clearError();
            }

            if (product && product.id) {
              if (!(prevProduct && (prevProduct.id === product.id))) {
                this.fetchSkuOptions();
              }
            }
          }

          fetchSkuOptions() {
            let {product, username} = this.props;

            this.setState({
              loading: true,
            });
            SubscriptionsService.getFreeSkuOptions(product.id, username,
                {cancelToken: this.cancelToken.token})
                .then((data) => {
                  let sku = null;
                  if (data && data.length === 1) {
                    sku = data[0].skuId;
                  }
                  this.setState({
                    loading: false,
                    skuOptions: data,
                    formValues: {
                      sku: sku,
                      quantity: 1,
                    },
                  });
                })
                .catch(e => !e.unmounted && this.displayError(e) && this.setState({loading: false}))
          }

          handleChange(name, value) {
            this.clearError();
            this.setState(prevState => {
              let formValues = Object.assign({}, prevState.formValues);
              formValues[name] = value;
              return {
                formValues: formValues,
              };
            });
          };

          handleQuantityChange(e) {
            this.setState({
              quantity: e.target.value,
            });
          };

          handleSubmit() {
            let {product, username, refreshType} = this.props;
            let {sku, quantity, comments} = this.state.formValues;
            this.setState({saving: true});

            //TODO do something with comments
            SubscriptionsService.grantFreeSubscription(product.id, sku,
                quantity, username, refreshType, comments, {cancelToken: this.cancelToken.token})
                .then((data) => {
                  this.props.onSuccess(data);
                })
                .catch(e => !e.unmounted && this.displayError(e))
                .finally(() => this.setState({saving: false}));

          }

          onValidationError = () => {
            this.setState({
              alert: {
                display: true,
                message: this.getTranslation('ERROR_FORM_VALIDATION'),
                type: 'error',
              },
            });
          };

          render() {
            const {
              open, onCancel, product, username,
            } = this.props;

            const {skuOptions, formValues} = this.state;

            return (
                <Modal header={<Header level={1}>
                  {this.getTranslation('TITLE_ADD_SUBSCRIPTION')}</Header>}
                       onClose={this.handleCancel}
                       className="add-sub-details-modal"
                       open={open}
                       onBackdropClick={this.handleCancel}>

                  <div className="modal-container">
                    {this.state.alert.display &&
                    <Alert ref={this.alertRef}
                           type={this.state.alert.type}>
                      {this.state.alert.message}</Alert>}

                    <LoadingIcon display={this.state.loading}
                                 text={this.getTranslation('MESSAGE_LOADING')}/>

                    {!this.state.loading &&
                    <AddSubscriptionDetailsForm
                        username={username}
                        loading={this.state.saving}
                        onValidationError={this.onValidationError}
                        submit={this.handleSubmit}
                        onCancel={onCancel}
                        onChange={this.handleChange}
                        skuOptions={skuOptions}
                        product={product}
                        formValues={formValues}/>
                    }

                  </div>
                </Modal>
            );
          }
        }));