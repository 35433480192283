import React from 'react';
import {Button, TranslationMixin, Header, FormInput, ConfirmationModal, WithCommonTranslations} from 'tds_shared_ui'
import './Notifications.css';
import {WithAdminTranslations} from "../hocs";


export const NotificationContent = WithAdminTranslations(WithCommonTranslations(class extends TranslationMixin(React.Component) {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,

        };

        this.handleDelete = this.handleDelete.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
    }

    handleDelete() {
        this.props.onDelete(this.props.formValues.language);
    };

    handleCancel() {
        this.setState({
            showModal: false
        });
    };


    handleShowModal() {
        this.setState({
            showModal: true
        });
    };


    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ?
            target.checked :
            target.value;
        const name = target.name;

        this.props.onChange(name, value, this.props.formValues.language);
    };

    render() {
        const {showModal} = this.state;
        const {isValidated, formValues} = this.props;
        let header = formValues.language;
        return (
            <div className="notification-content mt-4">
                <div className="row">
                    <div className="col-12 col-sm-8">
                        <div className="message-card">
                            <Header level={3}
                                    className="mb-3">{header || "English"}</Header>
                            <FormInput label={this.getTranslation(
                                'HEADER_TITLE')}
                                       value={formValues.title}
                                       name="title"
                                       id="title"
                                       maxLength={150}
                                       required={true}
                                       displayError={isValidated}
                                       onChange={this.handleInputChange}/>

                            <b>{this.getTranslation(
                                'HEADER_MESSAGE')}</b><span className="asterisk">*</span>
                            <div className="form-group">
              <textarea className="message form-control"
                        value={formValues.message}
                        name="message"
                        id="message"
                        maxLength={300}
                        required={true}
                        rows={3}
                        cols={60}
                        onChange={this.handleInputChange}/>
                                <div className="invalid-feedback">
                                    {this.getTranslation('ERROR_REQUIRED')}
                                </div>
                            </div>

                            <ConfirmationModal open={showModal}
                                               className="loginConfirmModal"
                                               title={this.getTranslation(
                                                   'CONFIRMATION_TITLE')}
                                               content={<div
                                                   dangerouslySetInnerHTML={{
                                                       __html: this.getTranslation(
                                                           'HTML_CONFIRMATION_BODY_MSG'),
                                                   }}></div>}
                                               okText={this.getTranslation(
                                                   'CONFIRMATION_SUCCESS')}
                                               cancelText={this.getTranslation(
                                                   'CONFIRMATION_CANCEL')}
                                               onCancel={this.handleCancel}
                                               onSuccess={this.handleDelete}/>

                            <div className="text-right">
                                {formValues.languageCode !== 'en' ?
                                    <Button className="p-0" onClick={this.handleShowModal}
                                            variant='link' type='button'>{this.getTranslation(
                                        'BUTTON_DELETE')}</Button> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}));