import {GenericAjaxService, adminAPI} from 'tds_shared_ui';
import {ReportsService} from '../services';

export default class DocumentService extends GenericAjaxService {

  static getDocuments(username, opts){
    return super.getAPI(adminAPI, `document/${encodeURIComponent(username)}`, opts);
  }

  static delete(username, documentKey){
    return super.deleteAPI(adminAPI, `document/${encodeURIComponent(username)}?key=${documentKey}`);
  }

  static upload(username, title, file){
    const formData = new FormData();
    formData.append('file',file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return super.postAPI(adminAPI, `document/${encodeURIComponent(username)}?title=${title}`, formData, config);
  }

  static getDocumentUrl(documentKey){
    return process.env.REACT_APP_API_HOST +
        `/api/admin/v1/document?key=${encodeURIComponent(documentKey)}`;
  }

  static getDocument(key){
    return ReportsService.download(DocumentService.getDocumentUrl(key));
  }
}