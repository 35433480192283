import React from 'react';

import {
    Alert,
    Header,
    TranslationMixin,
    WithCommonTranslations,
    AlertMixin,
} from 'tds_shared_ui';
import {WithAdminTranslations, WithUser} from "../components/hocs";
import {ReportsTable, AdminStatusProperties} from '../components/Reports';
import {ReportsService} from "../services";
import {Link} from "react-router-dom";

export const AdminStatusReportsPage = WithAdminTranslations(
    WithUser(WithCommonTranslations(
        class extends AlertMixin(TranslationMixin(React.Component)) {
            constructor(props) {
                super(props);

                this.state = {
                    alert: {
                        display: false,
                        type: 'default',
                        message: '',
                    },
                    tableHeaders: [],
                    tableAttributes: [],
                    loading: false,
                    tableData: [],
                    showTable: false,
                };

                this.handleError = this.handleError.bind(this);
                this.handleChange = this.handleChange.bind(this);
                this.handleReset = this.handleReset.bind(this);
                this.onValidationError = this.onValidationError.bind(this);
                this.fetchTable = this.fetchTable.bind(this);
                this.decorateColumns = this.decorateColumns.bind(this);
            }

            handleError(message){
                this.displayAlert(message, 'error');
            }

            handleChange(name, value){
                this.setState((prevState) =>{
                    let newState = {...prevState};
                    newState.formValues[name] = value;
                    return newState;
                });
            }

            handleReset(){
                this.clearError();
                this.setState({
                    tableHeaders: [],
                    tableAttributes: [],
                    loading: false,
                    tableData: [],
                    showTable: false,
                });
            }

            onValidationError(){
                this.displayAlert(this.getTranslation('ERROR_FORM_VALIDATION'), "error");
            }

            fetchTable(){
                this.clearError();
                let exportUrl = null;
                let reportFunction;
                exportUrl = ReportsService.getAdminUsersCSVUrl();
                reportFunction = ReportsService.getAdminUsers();

                this.setState({
                    loading: true,
                    exportUrl: exportUrl,
                });

                reportFunction
                    .then((data) =>{
                        this.setState({
                            tableHeaders: data.headers,
                            tableData: data.data,
                            tableAttributes: data.attributes,
                            loading: false,
                            showTable: true,
                        });
                    })
                    .catch(e => this.displayError(e))
                    .finally(() => {
                        this.setState({
                            loading: false,
                        });
                    });
            }

            decorateColumns(cols){
                for(let i = 0; i < cols.length; i++){
                    let col = cols[i];
                    if(col.header === 'User Name'){
                        col.render = (rowData) => {
                            let username = rowData[i];
                          return <Link to={'/admin/user/profile?username=' + username} >{username}</Link>
                        };
                    }
                }

                return cols;
            }

            render(){
                const {showTable, alert, loading, tableHeaders, tableAttributes, tableData, exportUrl} = this.state;

                return(
                  <div className="adminStatusPage">
                      <Header level={1}>{this.getTranslation('HEADER_ADMIN_STATUS_REPORTS_PAGE')}</Header>
                      <p>{this.getTranslation('MESSAGE_REPORTS')}</p>
                      {alert.display &&
                      <Alert ref={this.alertRef} type={alert.type}>{alert.message}</Alert>}

                      <AdminStatusProperties onCancel={this.handleReset}
                                             onError={this.handleError}
                                             loading={loading}
                                             onValidationError={this.onValidationError}
                                             submit={this.fetchTable}/>

                      {showTable &&
                      <ReportsTable headers={tableHeaders}
                                    attributes={tableAttributes}
                                    decorateColumns={this.decorateColumns}
                                    onError={this.handleError}
                                    tableData={tableData}
                                    exportUrl={exportUrl}/>
                      }
                  </div>
                );
            }

        }
    )
    )
);