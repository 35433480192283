import React from 'react';
import {NavLink} from 'react-router-dom';

import {
  ACDelcoLogo,
  Button,
  TranslationMixin,
  WithCommonTranslations,
  withRouter,
  UserService,
  NavItem,
  Menu,
  MenuItem,
  NavItemGroup,
  Navbar,
} from 'tds_shared_ui';

import './AppHeader.css';
import {NotificationAdminIcon} from '../components/Notifications/NotificationAdminIcon';
import {WithAdminTranslations} from '../components/hocs/withAdminTranslations';

const navItems = [
  {label: 'LABEL_USER_ADMIN', link: '/admin'},
  {label: 'LABEL_PROMO_CODES_ADMIN', link: '/promo'},
  {label: 'LABEL_NOTIFICATIONS', link: '/notifications'},
];

export const AppHeader = withRouter(
    WithCommonTranslations(WithAdminTranslations(
        (class extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);
            this.state = {};

            this.iconsMenu = [
              {
                label: 'LABEL_ALERTS', icon: 'bell',
                render: function(props) {
                  return <NotificationAdminIcon/>;
                },
              },
            ];
          }

          handleClick(link, newWindow) {
            if (newWindow) {
              window.open(link, '_blank');
            } else {
              this.props.navigate(link);
            }
          }

          renderNavItem(navItem) {
            let dom =
                <NavItem key={navItem.label}>

                  {navItem.links ?
                      <Menu label={this.getTranslation('LABEL_RESOURCES')}
                            className="tdsMenu">
                        {navItem.links.map((menuItem) =>
                            <MenuItem key={menuItem.link}
                                      onClick={() => this.handleClick(
                                          menuItem.link)}>
                              {this.getTranslation(menuItem.label)}
                            </MenuItem>,
                        )}
                      </Menu> :
                      <NavLink to={navItem.link}
                               className={(navData) => navData.isActive ? "active" : "none"} style={{textDecoration: 'none'}}>
                        {this.getTranslation(navItem.label)}</NavLink>
                  }
                </NavItem>;

            return dom;
          }

          renderReportsMenu() {
              const name = this.getTranslation('LABEL_REPORTS');

              const {user} = this.props;

              if (UserService.isSuperAdmin(user)) {
                  return (
                      <NavItem>
                          <Menu title={name} label={name} className="tdsMenu"
                                menuAlignment='left'>
                              <MenuItem
                                  label={this.getTranslation('LABEL_FINANCIAL_REPORTS')}
                                  title={this.getTranslation('LABEL_FINANCIAL_REPORTS')}
                                  onClick={() => this.handleClick('/financial')}/>
                              <MenuItem
                                  label={this.getTranslation('LABEL_USER_STATUS_REPORTS')}
                                  title={this.getTranslation('LABEL_USER_STATUS_REPORTS')}
                                  onClick={() => this.handleClick('/userstatus')}/>

                              <MenuItem
                                  label={this.getTranslation('LABEL_KEYCODE_REPORTS')}
                                  title={this.getTranslation('LABEL_KEYCODE_REPORTS')}
                                  onClick={() => this.handleClick('/keycodes')}/>


                              <MenuItem
                                  label={this.getTranslation('LABEL_ADMIN_USERS_REPORT')}
                                  title={this.getTranslation('LABEL_ADMIN_USERS_REPORT')}
                                  onClick={() => this.handleClick('/adminstatus')}/>


                              <MenuItem
                                  label='TIS Report Sub'
                                  title='TIS Report Sub'
                                  onClick={() => this.handleClick('/tisreport')}/>
                          </Menu>
                      </NavItem>
                  );

              } else {
                  return (
                      <NavItem>
                          <Menu title={name} label={name} className="tdsMenu"
                                menuAlignment='left'>
                              <MenuItem
                                  label={this.getTranslation('LABEL_FINANCIAL_REPORTS')}
                                  title={this.getTranslation('LABEL_FINANCIAL_REPORTS')}
                                  onClick={() => this.handleClick('/financial')}/>
                              <MenuItem
                                  label={this.getTranslation('LABEL_USER_STATUS_REPORTS')}
                                  title={this.getTranslation('LABEL_USER_STATUS_REPORTS')}
                                  onClick={() => this.handleClick('/userstatus')}/>

                              <MenuItem
                                  label={this.getTranslation('LABEL_KEYCODE_REPORTS')}
                                  title={this.getTranslation('LABEL_KEYCODE_REPORTS')}
                                  onClick={() => this.handleClick('/keycodes')}/>
                          </Menu>
                      </NavItem>
                  );
              }
          }

          renderTDSSettings() {
            const {user} = this.props;

            if (UserService.isSuperAdmin(user)) {
              return [
                (
                    <NavItem key="tdssettings">
                      <NavLink to='/tdssettings'
                               className={(navData) => navData.isActive ? "active" : "none"} style={{textDecoration: 'none'}}>
                        <i className="fas fa-cog" title={this.getTranslation(
                            'LABEL_TDS_SETTINGS')}></i>
                        <span className="sr-only">
                          {this.getTranslation(
                              'LABEL_TDS_SETTINGS')}</span></NavLink>
                    </NavItem>), (
                    <NavItem key="acdelcolookup">
                      <NavLink to='/acdelcolookup'
                               className={(navData) => navData.isActive ? "active" : "none"} style={{textDecoration: 'none'}}>
                        <i className="fas fa-search" title={this.getTranslation(
                            'TITLE_ACDELCO_ACCOUNT_LOOKUP')}></i>
                        <span className="sr-only">
                          {this.getTranslation(
                              'TITLE_ACDELCO_ACCOUNT_LOOKUP')}</span></NavLink>
                    </NavItem>)];
            }

          }

          renderIcons() {
            const {user} = this.props;

            return (
                <NavItemGroup alignment='end'>
                  <span>{user.firstName} {user.lastName}</span>
                  {this.iconsMenu.map((iconMenu) =>
                      <NavItem key={iconMenu.label}
                               title={this.getTranslation(iconMenu.label)}
                               onClick={() => this.handleClick(iconMenu.link)}>

                        {iconMenu.render ? iconMenu.render(this.props) :
                            <i className={'fas fa-' + iconMenu.icon}></i>}
                      </NavItem>,
                  )}
                  <Button variant="link" className="text-white"
                          onClick={this.props.onLogout}>{this.getTranslation(
                      'LABEL_LOGOUT')}</Button>
                </NavItemGroup>);
          }

          render() {
            return (
                <div className="appHeader">
                  <Navbar type="navbar" className="tdsNavbar"
                          appTitle={<ACDelcoLogo
                              imageAlt={this.getTranslation('LABEL_LOGO')}
                              onClick={() => this.handleClick(
                                  "/")}/>}>

                    {this.props.user &&
                    navItems.map((navItem) => this.renderNavItem(navItem))}

                    {this.props.user && this.renderReportsMenu()}

                    {this.props.user && this.renderTDSSettings()}

                    {this.props.user && this.renderIcons()}
                  </Navbar>
                </div>
            );
          }
        }))));