import React from 'react';
import {NotificationsService} from '../services'
import {AuthService, UserService} from 'tds_shared_ui';

const Context = React.createContext();

export class UserProvider extends React.Component {
  constructor(props) {
    super(props);

    this.setUser = (user) => {
        NotificationsService.getNotifications().then((data => {
            this.setState({
                notificationData: data
            });
        })).catch((e) => {
          //Adding empty catch to prevent webpack unhandled exception msg
        });
      this.setState({user: user});
    };
      this.dismissNotification = (notification) =>{
          let newArray = this.state.notificationData.slice();
          let length = newArray.length;
          console.log(newArray);
          console.log(notification.id);

          for(let i = 0; i < length; i++){
              let item = newArray[i];
              if(item.id === notification.id){
                  item.dismissed = true;
                  console.log(newArray);
                  this.setState({
                      notificationData: newArray,
                  });
                  console.log("dismissed :" + notification.id)
              }
          }
      };

    this.state = {
      user: null,
      setUser: this.setUser,
      notificationData:[],
        dismissNotification: this.dismissNotification,
    };
  }

  componentDidMount() {
    const token = AuthService.getAuthToken();

    if (token) {
      AuthService.addAuthTokenHeader(token);
      AuthService.addAuthTokenResponseInterceptor();

      UserService.getUser().then((user) => {
        if (UserService.isAdmin(user)) {
          this.setUser(user);
        } else {
          this.setUser(null);
        }
      }).catch((e) => {
        console.log(e);
        this.setUser(null);
      });

    }
  }


  render() {
    return (
        <Context.Provider value={this.state}>
          {this.props.children}
        </Context.Provider>
    );
  }
}

export const UserConsumer = Context.Consumer;