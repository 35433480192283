import React from 'react';

import {
    Alert,
    AlertMixin,
    Button,
    LoadingIcon,
    Table,
    ToggleSwitch,
    TranslationMixin,
    WithCommonTranslations,
} from 'tds_shared_ui';
import {WithAdminTranslations} from "../../hocs";
import {SubscriptionsService, AdminUserService} from '../../../services';
import {AddSubscriptionModal} from './AddSubscriptionModal';
import {SubscriptionDetail} from './SubscriptionDetail';
import {AddSubscriptionDetailsModal} from './AddSubscriptionDetailsModal';


import './Subscriptions.css';

export const Subscriptions = WithCommonTranslations(
    WithAdminTranslations(
        class Subscriptions extends AlertMixin(
            TranslationMixin(React.Component)) {
            constructor(props) {
                super(props);

                this.cancelToken = SubscriptionsService.getCancelToken().source();

                this.state = {
                    rowData: [],
                    loading: false,
                    showDetails: null,
                    showAddSubModal: false,
                    showAddSubDetailModal: false,
                    addSubSkuOptions: null,
                    addSubProduct: null,
                    skuOptions: null,
                    alert: {
                        display: false,
                        type: 'default',
                        message: '',
                    },
                };

                this.handleShowAddSub = this.handleShowAddSub.bind(this);
                this.handleCloseAddSub = this.handleCloseAddSub.bind(this);
                this.handleChooseSub = this.handleChooseSub.bind(this);
                this.handleCloseAddSubDetail = this.handleCloseAddSubDetail.bind(
                    this);
                this.handleAddSuccess = this.handleAddSuccess.bind(this);
                this.displayError = this.displayError.bind(this);
                this.handleToggleVCI = this.handleToggleVCI.bind(this);
                this.handleDataUpdate = this.handleDataUpdate.bind(this);
            }

            componentDidMount() {
                if (this.props.username) {
                    this.handleDataUpdate();
                }
            }

            componentDidUpdate(prevProps) {
                if (prevProps.username !== this.props.username) {
                    this.handleDataUpdate();
                }
            }

            componentWillUnmount() {
                if (this.cancelToken) {
                    this.cancelToken.cancel({unmounted: true});
                }
            }

            handleDataUpdate() {
                this.refreshList();
                this.refreshSkuList();
            }

            refreshSkuList() {
                SubscriptionsService.getFreeProducts(this.props.username,
                    {cancelToken: this.cancelToken.token})
                    .then((data) => {
                        this.setState({
                            skuOptions: data.sort((a, b) => a.name < b.name ? -1 : 1),
                        });

                    })
                    .catch(e => {
                        this.displayError(e);

                    });
            }

            refreshList() {
                this.setState({
                    loading: true,
                });
                SubscriptionsService.getAll(this.props.username,
                    {cancelToken: this.cancelToken.token})
                    .then((data) => {
                        const {message, subscriptions} = data;
                        if (message) {
                            this.displayAlert(message, 'warning');
                        }
                        this.setState({
                            rowData: subscriptions,
                        });

                    })
                    .catch((e) => this.displayError(e))
                    .finally(() => {
                        this.setState({
                            loading: false,
                        });

                    });
            }

            handleShowAddSub() {
                this.setState({
                    showAddSubModal: true,
                });
            }

            handleCloseAddSub() {
                this.setState({
                    showAddSubModal: false,
                });
            }

            handleCloseAddSubDetail() {
                this.setState({
                    showAddSubDetailModal: false,
                });
            }

            handleChooseSub(sub) {
                this.setState({
                    showAddSubModal: false,
                    addSubProduct: sub,
                    showAddSubDetailModal: true,
                });
            }

            handleAddSuccess(data) {
                this.refreshSkuList();
                const {message, subscriptions} = data;
                if (message) {
                    this.displayAlert(message, 'warning');
                }
                this.setState({
                    rowData: subscriptions,
                    showAddSubDetailModal: false,
                });
            }

            handleViewRow = (rowData) => () => {
                this.setState({
                    rowDetails: rowData,
                });
            };

            handleHideDetails = () => {
                this.setState({
                    rowDetails: null,
                });
            };

            handleToggleVCI() {
                    return AdminUserService.SetVCIStatus(this.props.username, !this.props.profileUser.allowVciPurchase)
                        .then((data) => {
                            this.props.onUpdate(data);
                        })
                        .catch(e => this.displayError(e));
                }


            displayError(e) {
                this.setState({
                    alert: {
                        display: true,
                        type: 'error',
                        message: e.translatedMessage ||
                            this.getTranslation('ERROR_GENERIC'),
                    },
                });
            }

            handleToggleNotifications = (sub, checked) => {
                return SubscriptionsService.setEmailNotification(sub.id,
                    checked, this.props.username)
                    .then((data) => {
                        const {message, subscriptions} = data;
                        if (message) {
                            this.displayAlert(message, 'warning');
                        }
                        this.setState({
                            rowData: subscriptions,
                        });
                    })
                    .catch(e => this.displayError(e));
            };

            getColumns() {
                return [
                    {
                        header: this.getTranslation('LABEL_STATUS'),
                        accessor: 'statusDesc',
                        render: (rowData) => {
                            let {statusDesc} = rowData;
                            let view;
                            if (rowData.statusTime) {
                                view = <React.Fragment>{rowData.statusDesc}&nbsp;<span
                                    className="text-muted">{rowData.statusTime}</span>
                                </React.Fragment>;
                            } else {

                                if (Array.isArray(statusDesc)) {
                                    view = <React.Fragment>
                                        {statusDesc.map((description, index) => <div
                                            key={index}>{description}</div>)}</React.Fragment>;
                                } else {
                                    view = rowData.statusDesc;
                                }
                            }
                            return view;
                        },
                    }, {
                        header: this.getTranslation('LABEL_SUBSCRIPTION_NAME'),
                        accessor: 'productName',
                    }, {
                        header: this.getTranslation('LABEL_EMAIL_NOTIFICATIONS'),
                        accessor: 'emailNotifications',
                        render: (rowData) => {
                            if (rowData && rowData.productType === 'TIME') {
                                return (
                                    <ToggleSwitch on={rowData.emailNotifications}
                                                  ariaLabel={this.getTranslation("LABEL_EMAIL_NOTIFICATIONS")}
                                                  onChange={(checked) => this.handleToggleNotifications(
                                                      rowData, checked)}
                                                  onLabel={this.getTranslation('LABEL_ON')}
                                                  offLabel={this.getTranslation(
                                                      'LABEL_OFF')}
                                                  onError={this.displayError}/>
                                );
                            } else {
                                return '';
                            }
                        },
                    }, {
                        header: this.getTranslation('LABEL_ACTIONS'),
                        accessor: 'actions',
                        render: (rowData) => {
                            return (
                                <Button variant="link" type="button"
                                        onClick={this.handleViewRow(rowData)}>
                                    {this.getTranslation('LABEL_VIEW')}
                                </Button>
                            );
                        },
                    }];

            }

            renderDetails(detail) {
                return (
                    <React.Fragment>
                        <div className="subscriptions-breadcrumb">
                            <Button type="button"
                                    className="btn-p-0"
                                    variant="link"
                                    onClick={this.handleHideDetails}>
                                {this.getTranslation('LABEL_ACTIVE_SUBSCRIPTIONS')}
                            </Button>
                            &nbsp;&gt;&nbsp;{detail.productName}</div>
                        <SubscriptionDetail username={this.props.username}
                                            onUpdate={this.handleDataUpdate}
                                            subData={detail}/>
                    </React.Fragment>
                );
            }

            render() {
                const columns = this.getColumns();
                const {rowData} = this.state;
                const {profileUser} = this.props;

                if (this.state.rowDetails) {
                    return this.renderDetails(this.state.rowDetails);
                }
                else {
                    return (
                        <div className="subscriptions">
                            {this.state.alert.display &&
                            <Alert ref={this.alertRef}
                                   type={this.state.alert.type}>{this.state.alert.message}</Alert>}
                            {this.state.loading ?
                                <div>
                                    <LoadingIcon display={true}/> {this.getTranslation(
                                    'MESSAGE_LOADING')}
                                </div>
                                :
                                <React.Fragment>
                                    <Table columns={columns} rows={rowData}
                                           filterId="subFilter"/>
                                    {this.state.skuOptions &&
                                    this.state.skuOptions.length > 0 &&
                                    <Button variant="link"
                                            onClick={this.handleShowAddSub}>
                                        {this.getTranslation(
                                            'BUTTON_ADD_SUBSCRIPTION')}</Button>}



                                    <Button variant="link"
                                            className="pr-1"
                                            onClick={this.handleToggleVCI}>
                                        {!profileUser.allowVciPurchase ? this.getTranslation('LABEL_ENABLED_PURCHASE') : this.getTranslation('LABEL_DISABLED_PURCHASE')}</Button>

                                    <i>{profileUser.lastVciPurchaseDate && "(" + this.getTranslation('LABEL_LAST_VCI_PURCHASE') + ' ' + profileUser.lastVciPurchaseDate + ")"}</i>

                                </React.Fragment>


                            }
                            <AddSubscriptionModal
                                username={this.props.username}
                                open={this.state.showAddSubModal}
                                skuOptions={this.state.skuOptions}
                                onCancel={this.handleCloseAddSub}
                                onSuccess={this.handleChooseSub}/>
                            <AddSubscriptionDetailsModal
                                product={this.state.addSubProduct}
                                username={this.props.username}
                                open={this.state.showAddSubDetailModal}
                                onCancel={this.handleCloseAddSubDetail}
                                onSuccess={this.handleAddSuccess}/>

                        </div>
                    );
                }
            }
        }));