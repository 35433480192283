import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';

import {
  Header,
  TranslationMixin,
  WithCommonTranslations,
  AlertMixin,
  Alert, Button,
} from 'tds_shared_ui';
import {
  FinancialProperties,
  FinancialReportModal,
  ReportsTable,
} from '../components/Reports';
import {WithAdminTranslations, WithUser} from "../components/hocs";

import {ReportsService, OrdersService} from "../services";
import {OrderInvoiceModal} from '../components/user/orders';

export const FinancialReportsPage = WithAdminTranslations(
    WithUser(WithCommonTranslations(
        class extends AlertMixin(TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);

            this.alertRef = React.createRef();
            this.beginRef = React.createRef();
            this.endRef = React.createRef();

            this.state = {
              formValues: {
                startDate: '',
                reportType: '',
                endDate: '',
                productName: '',
                whichReport: '',
              },
              alert: {
                display: false,
                type: 'default',
                message: '',
              },
              beginDateErrorMsg: '',
              endDateErrorMsg: '',
              exportUrl: '',
              tableHeaders: [],
              tableAttributes: [],
              modalAttributes: [],
              modalURL: '',
              modalHeaders: '',
              modalData: '',
              modalProduct: null,
              loading: false,
              tableData: [],
              showTable: false,
              showFinancialModal: false,
              showOrderDetails: false,
              orderDetails: null,
            };

            this.handleError = this.handleError.bind(this);
            this.handleChange = this.handleChange.bind(this);
            this.handleClose = this.handleClose.bind(this);
            this.handleModal = this.handleModal.bind(this);
            this.handleReset = this.handleReset.bind(this);
            this.handleShowOrderDetails = this.handleShowOrderDetails.bind(
                this);
            this.handleCloseOrderDetails = this.handleCloseOrderDetails.bind(
                this);
            this.validateTimes = this.validateTimes.bind(this);
            this.onValidationError = this.onValidationError.bind(this);
            this.fetchTable = this.fetchTable.bind(this);
            this.fetchSubandSkuReport = this.fetchSubandSkuReport.bind(this);
            this.decorateColumns = this.decorateColumns.bind(this);
          }

          getInput(ref) {
            const dom = ReactDOM.findDOMNode(ref.current);
            return dom.querySelector('input');
          }

          onValidationError() {
            this.displayAlert(this.getTranslation('ERROR_FORM_VALIDATION'),
                'error');
          };

          handleReset() {
            this.setState({
              formValues: {
                beginDate: '',
                salesType: '',
                endDate: '',
                whichReport: '',
              },
              tableHeaders: [],
              tableData: [],
              tableAttributes: null,
              showTable: false,
            });
          }

          validateTimes() {
            let beginInput = this.getInput(this.beginRef);
            let endInput = this.getInput(this.endRef);

            let beginDate = moment(this.state.formValues.startDate);
            let endDate = moment(this.state.formValues.endDate);

            let beginErrorMsg = '';
            let endErrorMsg = '';

            if (beginDate.isValid() && endDate.isValid()) {
              if (beginDate.isAfter(endDate)) {
                beginErrorMsg = this.getTranslation('ERROR_START_AFTER_END');
                endErrorMsg = this.getTranslation('ERROR_END_BEFORE_START');
              }
            }

            beginInput.setCustomValidity(beginErrorMsg);
            endInput.setCustomValidity(endErrorMsg);

            this.setState({
              beginDateErrorMsg: beginErrorMsg,
              endDateErrorMsg: endErrorMsg,
            });
          }

          handleError(message) {
            this.displayAlert(message, 'error');
          }

          handleClose() {
            this.setState({
              showFinancialModal: false,
            });
          }

          fetchSubandSkuReport(productName) {
            let saveData = this.getReportCriteria(
                {...this.state.formValues, productName: productName});
            let modalURL = ReportsService.getSubandSkuReportCSVUrl(saveData);

            this.clearError();
            this.setState({
              modalURL: modalURL,
            });

            return ReportsService.getSubandSkuReport(saveData).then((data) => {
              this.setState({
                modalHeaders: data.headers,
                modalData: data.data,
                modalAttributes: data.attributes,
                modalProduct: productName,
                showFinancialModal: true,
              });
            }).catch((e) => {
              this.handleError(e);
            });
          }

          handleModal(code) {
            return this.fetchSubandSkuReport(code);
          }

          handleChange(name, value) {
            this.setState((prevState) => {
              let newState = {...prevState};
              newState.formValues[name] = value;
              return newState;
            }, () => {
              if (name === 'startDate' || name === 'endDate') {
                this.validateTimes();
              }
            });
          }

          getReportCriteria(data) {
            let {startDate, endDate} = data;

            if (startDate) {
              data.startDate = moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ');
            }
            if (endDate) {
              data.endDate = moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ');
            }

            return data;
          }

          fetchTable() {
            let saveData = this.getReportCriteria({...this.state.formValues});
            let exportUrl = null;
            let reportFunction = null;

            if (this.state.formValues.whichReport === 'Sales') {
              exportUrl = ReportsService.getSalesReportCSVUrl(saveData);
              reportFunction = ReportsService.getSalesReport;
            } else {
              exportUrl = ReportsService.getSubscriptionReportsExcelUrl(saveData);
              reportFunction = ReportsService.getSubscriptionReports;
            }

            this.clearError();
            this.setState({
              loading: true,
              exportUrl: exportUrl,
              tableHeaders: null,
              tableData: null,
              tableAttributes: null,
              showTable: false,
            });

            reportFunction(saveData).then((data) => {
              this.setState({
                tableHeaders: data.headers,
                tableData: data.data,
                tableAttributes: data.attributes,
                loading: false,
                showTable: true,
              });
            }).catch(() => {
              let error = this.getTranslation('ERROR_REPORTS');
              this.handleError(error);
              this.setState({
                loading: false,
                tableHeaders: null,
                tableData: null,
                tableAttributes: null,
                showTable: false,
              });
            });

          }

          handleShowOrderDetails(orderNumber) {
            return OrdersService.get(orderNumber).then(d => {
              this.setState({
                showOrderDetails: true,
                orderDetails: d,
              });
            }).catch(e => this.displayError(e));
          }

          handleCloseOrderDetails() {
            this.setState({
              showOrderDetails: false,
            });
          }

          decorateColumns(cols) {
            for (let i = 0; i < cols.length; i++) {
              let col = cols[i];
              if (col.header === 'Product Name') {
                col.render = (rowData) => {
                  let code = rowData[col.accessor];

                  return (
                      <Button variant="link"
                              type="button"
                              onClick={() => this.handleModal(code)}>
                        {code}</Button>
                  );
                };
              } else if (col.header === 'Order Number') {
                col.render = (rowData) => {
                  let orderId = rowData[col.accessor];
                  return (
                      <Button key="order" variant="link"
                              onClick={() => this.handleShowOrderDetails(
                                  orderId)}
                              type="button">{orderId}</Button>);
                };
              }
            }
            return cols;
          }

          render() {
            const {
              alert, formValues, loading, tableHeaders, exportUrl, tableData, showTable, showFinancialModal, showOrderDetails, orderDetails,
              modalAttributes, modalProduct, modalData, modalHeaders, beginDateErrorMsg, endDateErrorMsg, tableAttributes, modalURL,
            } = this.state;

            return (
                <div className="financialPage">
                  <div className="clearfix">
                    <Header level={1}>{this.getTranslation(
                        'HEADER_FINANCIAL_REPORTS_PAGE')}</Header>
                    <p>{this.getTranslation('MESSAGE_REPORTS')}</p>
                    {alert.display &&
                    <Alert ref={this.alertRef} type={alert.type}>
                      {alert.message}</Alert>}

                    <FinancialProperties formValues={formValues}
                                         onChange={this.handleChange}
                                         onCancel={this.handleReset}
                                         onError={this.handleError}
                                         onValidationError={this.onValidationError}
                                         beginDateErrorMsg={beginDateErrorMsg}
                                         endDateErrorMsg={endDateErrorMsg}
                                         submit={this.fetchTable}
                                         beginRef={this.beginRef}
                                         loading={loading}
                                         endRef={this.endRef}/>

                    {showTable &&
                    <ReportsTable headers={tableHeaders}
                                  onClick={this.handleModal}
                                  exportUrl={exportUrl}
                                  tableData={tableData}
                                  onError={this.handleError}
                                  decorateColumns={this.decorateColumns}
                                  attributes={tableAttributes}/>
                    }

                    {showFinancialModal &&
                    <FinancialReportModal onClose={this.handleClose}
                                          exportUrl={modalURL}
                                          attributes={modalAttributes}
                                          showModal={showFinancialModal}
                                          productName={modalProduct}
                                          tableData={modalData}
                                          headers={modalHeaders}/>}

                    {showOrderDetails &&
                    <OrderInvoiceModal open={showOrderDetails}
                                       orderDetails={orderDetails}
                                       onClose={this.handleCloseOrderDetails}/>}


                  </div>
                </div>
            );
          }
        })));