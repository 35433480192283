import React from 'react';

import {
  Alert,
  AlertMixin,
  Button,
  FormInput,
  LoadingIcon,
  Table,
  TranslationMixin,
  WithCommonTranslations,
  WithUserTranslations,
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../hocs';
import {AdminUserService} from "../../services";

import './Search.css';

export const Search = WithAdminTranslations(
    WithUserTranslations(WithCommonTranslations(
        class extends AlertMixin(TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);

            this.state = {
              searchField: '',
              searchResults: [],
              loading: false,
              hideResults: true,
              tableMessage: '',
              alert: {
                display: false,
                type: 'default',
                message: '',
              },
            };

            this.handleChange = this.handleChange.bind(this);
            this.handleSearch = this.handleSearch.bind(this);
          }

          getCols() {
            return [
              {
                header: this.getTranslation('LABEL_STATUS'),
                accessor: 'disabled',
                render: (rowData) => <span>{this.getTranslation(rowData.disabled
                    ? 'LABEL_DISABLED' : 'LABEL_ACTIVE')}</span>,
              }, {
                header: this.getTranslation('LABEL_LOGIN_NAME'),
                accessor: 'username',
              },
              {
                header: this.getTranslation('LABEL_FIRST_NAME'),
                accessor: 'firstName',
              }, {
                header: this.getTranslation('LABEL_LAST_NAME'),
                accessor: 'lastName',
              },
              {
                header: this.getTranslation('LABEL_EMAIL'),
                accessor: 'email',
              },
              {
                header: this.getTranslation('LABEL_VSC_ID') + "/" + this.getTranslation('LABEL_AASRA_ID'),
                accessor: 'locksmithID',
              },
              {
                header: this.getTranslation('LABEL_ROLE'),
                accessor: 'roleDisplay',
              },
              {
                header: this.getTranslation('LABEL_ACTIONS'),
                accessor: 'action',
                render: (user) =>
                    <Button variant="link"
                            onClick={() => this.props.onSelect(user)}>
                      {this.getTranslation('LABEL_VIEW')}</Button>,

              },
            ];
          }

          handleChange(event) {
            const target = event.target;
            const value = target.type === 'checkbox' ?
                target.checked :
                target.value;

            this.clearError();
            this.setState({
              searchField: value,
            });
          }

          handleSearch(e) {
            e.preventDefault();
            const MAX_SEARCH = 50;

            this.clearError();

            if (this.state.searchField) {
              this.setState({
                loading: true,
                hideResults: true,
              });
              AdminUserService.search(this.state.searchField, MAX_SEARCH + 1,
                  Intl.DateTimeFormat().resolvedOptions().timeZone)
                  .then((data) => {
                    let tableMsg = data.length > MAX_SEARCH ?
                        this.getTranslation('MESSAGE_SEARCH_RESULTS_OVER_50') :
                        '';
                    this.setState({
                      tableMessage: tableMsg,
                      searchResults: data.slice(0, MAX_SEARCH),
                      hideResults: false,
                    });
                  })
                  .catch((e) => {
                    this.displayError(e);
                  })
                  .finally(() => {
                    this.setState({
                      loading: false,
                    });
                  });
            }
          }

          render() {
            const {searchField, searchResults, loading, hideResults, tableMessage} = this.state;
            const cols = this.getCols(); //ensures it updates as translations are fetched
            return (
                <div className="userSearch">
                  {this.state.alert.display &&
                  <Alert ref={this.alertRef}
                         type={this.state.alert.type}>{this.state.alert.message}</Alert>}
                  <form noValidate onSubmit={this.handleSearch}>
                    <div className="row searchRow">
                      <div className="col">
                        <FormInput id="searchField" name="searchField"
                                   placeholder={this.getTranslation("LABEL_USER_SEARCH_PLACEHOLDER")}
                                   autoComplete="off"
                                   maxLength={100}
                                   onChange={this.handleChange}
                                   value={searchField}></FormInput>
                      </div>
                      <div className="col-auto">
                        <Button variant="primary" type="submit"
                                disabled={loading}>
                          <LoadingIcon display={loading}></LoadingIcon>
                          <i className="fas fa-search pull-right"></i>&nbsp;
                          {this.getTranslation('LABEL_SEARCH')}
                        </Button>
                      </div>
                    </div>
                  </form>

                  {!hideResults &&
                  <Table columns={cols} rows={searchResults}
                         tableMessage={tableMessage && <i>{tableMessage}</i>}
                         filterId="mySubFilter"/>
                  }

                </div>
            );
          }
        })));