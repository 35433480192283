import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';

import {
  Button,
  DatePicker,
  FormInput,
  LoadingIcon,
  Select,
  TranslationMixin,
  WithCommonTranslations,
  WithFormValidation,
  Validators,
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../../hocs';
import {CacheableHelperService} from "../../../services";

import './OrdersSearchForm.css';

export const OrdersSearchForm = WithFormValidation(
    WithAdminTranslations(WithCommonTranslations(
        class OrdersSearchForm extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);

            this.beginDateRef = React.createRef();
            this.endDateRef = React.createRef();

            this.state = {
              beginDate: null,
              endDate: null,
              beginDateErrorMsg: '',
              endDateErrorMsg: '',
            };


            this.validateTimes = this.validateTimes.bind(this);
            this.handleChange = this.handleChange.bind(this);
            this.onlyNumbersOnKeyPress = Validators.onlyNumbersOnKeyPress.bind(
                this);
          }

          static getDerivedStateFromProps(props) {
            let beginDate = null;
            let endDate = null;
            let fv = props.formValues;
            if (fv) {
              if (fv.beginDate) {
                beginDate = fv.beginDate;
              }
              if (fv.endDate) {
                endDate = fv.endDate;
              }
            }

            return {
              beginDate: beginDate,
              endDate: endDate,
            };
          }

          componentDidMount(){
            CacheableHelperService.getOrderTypes().then(d => {
              if (d) {
                this.setState({
                  orderTypes: d.map(e => ({label: e, value: e}))
                })
              }
            }).catch((e) => {
              //Adding empty catch to prevent webpack unhandled exception msg
            })
          }

          componentDidUpdate(prevProps) {
            let prevFv = prevProps.formValues;
            let fv = this.props.formValues;
            if (prevFv) {
              if (prevFv.beginDate !== fv.beginDate ||
                  prevFv.endDate !== fv.endDate) {
                this.validateTimes();
              }
            }
          }

          handleChange(event) {
            const target = event.target;
            const name = target.name;
            const value = target.type === 'checkbox' ?
                target.checked :
                target.value;

            this.props.onChange(name, value);
          }

          handleDateChange = (name) => (value) => {
            this.props.onChange(name, value, this.validateTimes);
          };

          getInput(ref) {
            const dom = ReactDOM.findDOMNode(ref.current);
            return dom.querySelector('input');
          }

          validateTimes() {
            let beginInput = this.getInput(this.beginDateRef);
            let endInput = this.getInput(this.endDateRef);

            let beginDate = moment(this.state.beginDate);
            let endDate = moment(this.state.endDate);

            let beginErrorMsg = '';
            let endErrorMsg = '';

            if (beginDate.isValid() && endDate.isValid()) {
              if (beginDate.isAfter(endDate)) {
                beginErrorMsg = this.getTranslation('ERROR_START_AFTER_END');
                endErrorMsg = this.getTranslation('ERROR_END_BEFORE_START');
              }
            }

            beginInput.setCustomValidity(beginErrorMsg);
            endInput.setCustomValidity(endErrorMsg);

            this.setState({
              beginDateErrorMsg: beginErrorMsg,
              endDateErrorMsg: endErrorMsg,
            });
          }

          render() {
            const {orderNumber, orderType} = this.props.formValues;
            const {beginDate, endDate, orderTypes} = this.state;

            return (
                <div className="orders-search-form">
                  <div className="d-flex flex-row flex-wrap align-items-center">
                    <div className="col-order-number">
                      <FormInput id="orderNumber" name="orderNumber"
                                 label={this.getTranslation(
                                     'LABEL_ORDER_NUMBER')}
                                 autoComplete="off"
                                 onKeyPress={this.onlyNumbersOnKeyPress}
                                 pattern="\d+"
                                 maxLength={10}
                                 onChange={this.handleChange}
                                 value={orderNumber}></FormInput>
                    </div>
                    <div className="col-tds-datepicker">
                      <DatePicker
                          ref={this.beginDateRef}
                          label={this.getTranslation('LABEL_START_DATE')}
                          dateFormat="MM/dd/yyyy"
                          autoComplete="off"
                          className="tds-date-width"
                          startDate={beginDate}
                          endDate={endDate}
                          invalidDateMsg={this.getTranslation(
                              'ERROR_INVALID_DATE')}
                          id="startDate"
                          displayError={this.props.isValidated}
                          customError={this.state.beginDateErrorMsg}
                          onChange={this.handleDateChange('beginDate')}
                          selected={beginDate}/>
                    </div>
                    <div className="col-tds-datepicker">
                      <DatePicker
                          ref={this.endDateRef}
                          label={this.getTranslation('LABEL_END_DATE')}
                          dateFormat="MM/dd/yyyy"
                          autoComplete="off"
                          className="tds-date-width"
                          startDate={beginDate}
                          endDate={endDate}
                          invalidDateMsg={this.getTranslation(
                              'ERROR_INVALID_DATE')}
                          id="endDate"
                          displayError={this.props.isValidated}
                          customError={this.state.endDateErrorMsg}
                          onChange={this.handleDateChange('endDate')}
                          selected={endDate}/>
                    </div>
                    <div className="col-order-type">
                      <Select options={orderTypes}
                              label={this.getTranslation('LABEL_ORDER_TYPE')}
                              removable={true}
                              value={orderType}
                              onChange={v => this.props.onChange('orderType',
                                  v)}/>
                    </div>
                    <div className="col-auto">
                      <div className="float-right">
                        <Button type="button" variant="link"
                                onClick={this.props.onClear}>
                          {this.getTranslation('BUTTON_CLEAR')}</Button>
                        <Button type="submit" variant="primary"
                                disabled={this.props.loading}>
                          <LoadingIcon display={this.props.loading}/>
                          {this.getTranslation('LABEL_SEARCH')}
                        </Button>
                      </div>
                    </div>

                  </div>

                </div>
            );
          }
        })));