import React from 'react';

import {
    Alert,
    AlertMixin,
    Button,
    FormInput,
    HelperUtils,
    LoadingIcon,
    Radio,
    TranslationMixin,
    WithCommonTranslations, WithFormValidation,
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../../hocs';

import './RefundDetails.css';

export const RefundDetails = WithFormValidation(
    WithAdminTranslations(WithCommonTranslations(
        class OrderDetails extends AlertMixin(
            TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);

            this.alertRef = React.createRef();

            this.state = {
              alert: {
                display: false,
                type: 'default',
                message: '',
              },
            };

            this.handleChange = this.handleChange.bind(this);
            this.handleRadioChange = this.handleRadioChange.bind(this);
            this.handleChangeRefundType = this.handleChangeRefundType.bind(this);
          }

          handleChange(itemId, name, value) {
            this.props.onChange(itemId, name, value);
          }

          handleChangeRefundType(value){
            this.props.onChange(null, "refundType", value);
          }

          handleRadioChange(itemId, name, strValue) {
            let value = strValue;
            if (strValue === 'true') {
              value = true;
            } else if (strValue === 'false') {
              value = false;
            }
            this.props.onChange(itemId, name, value);
          }

          scrollToAlert() {
            HelperUtils.scrollToAlert(this.alertRef);
          }

          getRefundOptions(orderDetails){
            let options = [
              {
                id: 'fullRefund',
                value: 'full',
                label: this.getTranslation('LABEL_FULL'),
              },
              {
                id: 'partialRefund',
                value: 'partial',
                label: this.getTranslation('LABEL_PARTIAL'),
              }];
            if (orderDetails.taxRefundable) {
              options.push({
                id: 'taxRefund',
                value: 'tax',
                label: this.getTranslation('LABEL_TAX_ONLY'),
              });
            }
            return options;
          }

          renderReceiptItem(item){
            const {refundType} = this.props;
            const enablePartialRefund = refundType === "partial";
            let maxValue = item.refundPercent ? 100 : item.maxRefundAmount;

            return (
                <tr key={item.itemId}>
                  <td>
                    <span className="font-weight-bold">{item.name}
                    </span> {item.skuDescription &&
                  <span>- {item.skuDescription}</span>}
                  </td>
                  <td className="text-right">{item.quantity}</td>
                  <td className="text-right">
                    {item.subtotalAfterRefund}
                  </td>

                  <td className="refund-col">
                    {enablePartialRefund &&
                    <span className="d-inline-flex align-items-center">
                            <FormInput id="" name={`${item.itemId}-refundValue`}
                                       type="number"
                                       step={0.01} min={0} max={maxValue}
                                       className="refund-value"
                                       autoComplete="off"
                                       pattern="\d+(\.\d{1,2})?"
                                       data-pattern-message={this.getTranslation(
                                           'ERROR_INVALID_REFUND_VALUE')}
                                       size={10}
                                       displayError={this.props.isValidated}
                                       maxLength={10}
                                       onChange={(e) => this.handleChange(
                                           item.itemId, 'refundValue',
                                           e.target.value)}
                                       value={item.refundValue}></FormInput>

                            <Radio name={`${item.itemId}-refundType`} label=""
                                   value={item.refundPercent}
                                   onChange={(v) => this.handleRadioChange(
                                       item.itemId,
                                       'refundPercent', v)}
                                   options={[
                                     {
                                       id: `${item.itemId}-amount`,
                                       value: false,
                                       label: this.getTranslation(
                                           'LABEL_AMOUNT'),
                                     },
                                     {
                                       id: `${item.itemId}-percent`,
                                       value: true,
                                       label: this.getTranslation(
                                           'LABEL_PERCENT'),
                                     }]}/>
                            </span>
                    }
                  </td>

                </tr>
            )
          }

          render() {
            const {orderDetails, onGoToOrderHistory, refundType} = this.props;
            const enablePartialRefund = refundType === "partial";
            const refundOptions = this.getRefundOptions(orderDetails);

            if (!orderDetails) {
              return (
                  <Alert type="error">
                    {this.getTranslation('ERROR_NO_ORDER_FOUND')}
                  </Alert>);
            }

            return (
                <div className="refund-details">
                  {this.state.alert.display &&
                  <Alert ref={this.alertRef}
                         type={this.state.alert.type}>{this.state.alert.message}
                  </Alert>}

                  <div className="refund-type-selection">
                    <Radio name="refundType" label={this.getTranslation("LABEL_REFUND_TYPE")}
                         value={refundType}
                         onChange={this.handleChangeRefundType}
                         options={refundOptions}/>
                  </div>

                  <p><span className="font-weight-bold">{this.getTranslation(
                      'LABEL_PURCHASE_DATE')}</span><br/>
                    {orderDetails.orderDate}</p>
                  <p><span className="font-weight-bold">{this.getTranslation(
                      'LABEL_ORDER_NUMBER')}</span><br/>
                    {orderDetails.orderNumber}
                  </p>
                  <p><span className="font-weight-bold">{this.getTranslation(
                      'LABEL_BILL_TO')}</span><br/>
                    {orderDetails.billingFirstName} {orderDetails.billingLastName}<br/>
                    {orderDetails.billingAddress &&
                    <React.Fragment>
                      {orderDetails.billingAddress.addressLine1}<br/>
                      {orderDetails.billingAddress.city},&nbsp;
                      {orderDetails.billingAddress.state}&nbsp;
                      {orderDetails.billingAddress.zipCode}
                    </React.Fragment>
                    }
                  </p>

                  <p><strong>{this.getTranslation(
                      'LABEL_PAYMENT_METHOD')}</strong><br/>
                    {orderDetails.cardBrand} {this.getTranslation(
                        'LABEL_ENDING_IN')} {orderDetails.cardLast4Digits}
                  </p>

                  <table className="table">
                    <thead>
                    <tr>
                      <th>{this.getTranslation('LABEL_DESCRIPTION')}</th>
                      <th className="text-right">{this.getTranslation(
                          'LABEL_QUANTITY')}</th>
                      <th className="text-right">{this.getTranslation(
                          'LABEL_REFUNDABLE_AMOUNT')}</th>
                      <th className="refund-col">{enablePartialRefund && this.getTranslation(
                          'LABEL_REFUND')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orderDetails.receiptItems &&
                    orderDetails.receiptItems.map((item) => (
                        this.renderReceiptItem(item)
                    ))}
                    </tbody>
                  </table>

                  <div className="clearfix">
                    <table className="float-right total-table">
                      <tbody>
                      <tr>
                        <td>{this.getTranslation('LABEL_ORDER_SUBTOTAL')}</td>
                        <td className="text-right">{orderDetails.subtotalAfterRefunds}</td>
                      </tr>
                      <tr>
                        <td>{this.getTranslation('LABEL_ORDER_SALES_TAX')}</td>
                        <td className="text-right">{orderDetails.taxAfterRefunds}</td>
                      </tr>
                      <tr className="border-top">
                        <td>{this.getTranslation('LABEL_ORDER_TOTAL')}</td>
                        <td className="text-right">{orderDetails.grandTotalAfterRefunds}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="clearfix mt-5">
                    <Button variant="link" className="pl-0" type="button" onClick={onGoToOrderHistory}>
                      {this.getTranslation('BUTTON_GO_TO_ORDER_HISTORY')}
                    </Button>

                    <div className="float-right">
                      <Button variant="primary"
                              type="submit"
                              disabled={this.props.saving}>
                        <LoadingIcon display={this.props.saving}/>
                        {this.getTranslation('BUTTON_CONTINUE')}</Button>
                    </div>
                  </div>

                </div>
            );
          }
        })));