import {GenericAjaxService, adminAPI} from 'tds_shared_ui';

export default class CommentsService extends GenericAjaxService {

  static get(username, searchDate) {
    return super.getAPI(adminAPI, `comment?un=${encodeURIComponent(username)}&searchdate=${searchDate}`);
  }

  static create(username, comment){
    return super.postAPI(adminAPI, `comment?un=${encodeURIComponent(username)}`, comment);
  }

  static delete(id){
    return super.deleteAPI(adminAPI, `comment/${id}`);
  }
}