import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';

import {
    Button,
    DatePicker,
    LoadingIcon,
    TranslationMixin,
    WithCommonTranslations,
    WithFormValidation,
    Validators,
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../../hocs';

import './AuditLogSearchForm.css';

export const AuditLogSearchForm = WithFormValidation(
    WithAdminTranslations(WithCommonTranslations(
        class AuditLogSearchForm extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);

            this.beginDateRef = React.createRef();

            this.state = {
              beginDate: null,
              beginDateErrorMsg: '',
            };

            this.validateTimes = this.validateTimes.bind(this);
            this.handleChange = this.handleChange.bind(this);
            this.onlyNumbersOnKeyPress = Validators.onlyNumbersOnKeyPress.bind(
                this);
          }

          static getDerivedStateFromProps(props) {
            let beginDate = null;
            let fv = props.formValues;
            if (fv) {
              if (fv.beginDate) {
                beginDate = fv.beginDate;
              }
            }

            return {
              beginDate: beginDate,
            };
          }

          componentDidUpdate(prevProps) {
            let prevFv = prevProps.formValues;
            let fv = this.props.formValues;
            if (prevFv) {
              if (prevFv.beginDate !== fv.beginDate) {
                this.validateTimes();
              }
            }
          }

          handleChange(event) {
            const target = event.target;
            const name = target.name;
            const value = target.type === 'checkbox' ?
                target.checked :
                target.value;

            this.props.onChange(name, value);
          }

          handleDateChange = (name) => (value) => {
            this.props.onChange(name, value, this.validateTimes);
          };

          getInput(ref) {
            const dom = ReactDOM.findDOMNode(ref.current);
            return dom.querySelector('input');
          }

          validateTimes() {
            let beginInput = this.getInput(this.beginDateRef);
            let beginDate = moment(this.state.beginDate);
            let date = moment();

            let beginErrorMsg = '';

            if (beginDate.isValid()) {
              if (beginDate.isAfter(date) || beginDate.isSame(date)) {
                beginErrorMsg = this.getTranslation('ERROR_START_AFTER_TODAY');
              }
            }

            beginInput.setCustomValidity(beginErrorMsg);

            this.setState({
              beginDateErrorMsg: beginErrorMsg,
            });
          }

          render() {
            const {beginDate} = this.state;

            return (
                <div className="audit-search-form">
                  <div className="d-flex flex-row flex-wrap align-items-center">
                    <div className="col-tds-datepicker">
                      <DatePicker
                          ref={this.beginDateRef}
                          label={this.getTranslation('LABEL_START_DATE')}
                          dateFormat="MM/dd/yyyy"
                          autoComplete="off"
                          className="tds-date-width"
                          startDate={beginDate}
                          invalidDateMsg={this.getTranslation(
                              'ERROR_INVALID_DATE')}
                          id="startDate"
                          displayError={this.props.isValidated}
                          customError={this.state.beginDateErrorMsg}
                          onChange={this.handleDateChange('beginDate')}
                          selected={beginDate}
                          popperPlacement="top-end"/>
                    </div>
                    <div className="col-auto">
                      <div className="float-right">
                        <Button type="button" variant="link"
                                onClick={this.props.onClear}>
                          {this.getTranslation('BUTTON_CLEAR')}</Button>
                        <Button type="submit" variant="primary"
                                disabled={this.props.loading}>
                          <LoadingIcon display={this.props.loading}/>
                          {this.getTranslation('LABEL_SEARCH')}
                        </Button>
                      </div>
                    </div>

                  </div>

                </div>
            );
          }
        })));