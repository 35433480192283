import React from 'react';
import {
    Select,
    TranslationMixin,
    WithCommonTranslations,
    WithFormValidation,
    Button, LoadingIcon,
} from 'tds_shared_ui';
import {WithAdminTranslations} from "../hocs";

import './KeycodeProperties.css';

export const TisProperties = WithAdminTranslations(WithFormValidation(
    WithCommonTranslations(
        class TisProperties extends TranslationMixin(React.Component) {
            constructor(props) {
                super(props);

                this.handleSelectChange = this.handleSelectChange.bind(this);
                this.handleInputChange = this.handleInputChange.bind(this);
            }

            handleInputChange = (event) => {
                const target = event.target;
                const value = target.type === 'checkbox' ?
                    target.checked :
                    target.value;
                const name = target.name;

                this.props.onChange(name, value);
            };

            handleSelectChange = (name) => (value) => {
                this.props.onChange(name, value);
            };

            getTisOptions() {
                let options = [
                    {
                        id: 'ACDelcoT2WT2W',
                        value: 'ACDelcoT2WT2W',
                        label: 'ACDelcoT2WT2W',
                    },
                    {
                        id: 'ACDelcoT2Wsps',
                        value: 'ACDelcoT2Wsps',
                        label: 'ACDelcoT2Wsps',
                    },
                    ];

                return options;
            }

            render() {
                return (
                    <div className="keycodeSearch">
                        <div className="d-flex flex-row flex-wrap align-items-center">
                            <React.Fragment>
                                <div className="tds-destination-select">
                                    <Select
                                        label='Tis Subs'
                                        name="reportType"
                                        id="reportType"
                                        removable={true}
                                        options={this.getTisOptions()}
                                        value={this.props.formValues.reportType}
                                        readOnly={this.props.readOnly}
                                        required={true}
                                        error={this.props.isValidated &&
                                        !this.props.formValues.reportType}
                                        errorMessage={this.getTranslation(
                                            'ERROR_REQUIRED')}
                                        onChange={this.handleSelectChange('reportType')}/>

                                </div>
                            </React.Fragment>
                            <div className="col-auto">
                                <div className="float-left">
                                    <Button variant="link" type="button"
                                            onClick={this.props.onCancel}>
                                        {this.getTranslation('BUTTON_RESET')}</Button>

                                    <Button className="float-right"
                                            variant="primary" type='submit'
                                            disabled={this.props.loading}>
                                        <LoadingIcon display={this.props.loading}/>
                                        {this.getTranslation('BUTTON_SEARCH')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        })));