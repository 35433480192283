import React from 'react';
import {TranslationMixin, WithCommonTranslations, Panel, NimbusLink,} from 'tds_shared_ui';
import {WithUser} from '../hocs';
import {NotificationAdminList} from './NotificationAdminList';
import './NotificationAdminIcon.css';

export const NotificationAdminIcon = WithUser(WithCommonTranslations(
    class extends TranslationMixin(React.Component) {
        constructor(props) {
            super(props);

            this.node = React.createRef();

            this.state = {
                displayNotification: false,
                markAsRead: false,
            };

            this.handleMarkAsReadClick = this.handleMarkAsReadClick.bind(this);
            this.togglePanel = this.togglePanel.bind(this);
            this.handleNotificationDisplay = this.handleNotificationDisplay.bind(
                this);
        }

        componentDidMount() {
            this.toggleClickOutsideEvent(true);

        }

        componentWillUnmount() {
            this.toggleClickOutsideEvent(false);
        }

        toggleClickOutsideEvent(enabled) {
            if (enabled) {
                document.addEventListener('click', this.handleClickOutside);
            } else {
                document.removeEventListener('click', this.handleClickOutside);
            }
        }

        handleMarkAsReadClick() {
            this.setState({
                markAsRead: true,
            });
        }

        handleNotificationDisplay(bool) {
            this.setState({
                displayNotification: bool,
            });
        };

        handleClickOutside = (event) => {
            if (this.state.displayNotification) {
                const node = this.node.current;
                if (!node || !node.contains(event.target)) {
                    this.handleNotificationDisplay(false);
                }
            }
        };

        togglePanel() {
            this.handleNotificationDisplay(!this.state.displayNotification);
        }

        render() {
            const {className, notificationData} = this.props;
            const {markAsRead, displayNotification} = this.state;

            const displayClass = ('cart-container ' +
                (className ? className : '')).trim();

            return (
                <div className={displayClass} ref={this.node}>
                    <NimbusLink href="#" onClick={this.togglePanel}>
                        <div><i className={'fas fa-bell'}></i>{notificationData.length >
                        0 && markAsRead === false &&
                        <span
                            className="badge badge-danger rounded-circle">{notificationData.length}</span>}
                        </div>
                    </NimbusLink>


                    <Panel floating={true}
                           hidden={!displayNotification}
                           className='cart-panel'>

                        <NotificationAdminList items={notificationData}
                                               onClick={this.handleMarkAsReadClick}
                                               markAsRead={markAsRead}
                                               readOnly={true}/>
                    </Panel>
                </div>
            );
        }
    }));