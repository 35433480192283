import React from 'react';

import {UtilService} from 'tds_shared_ui';

const Context = React.createContext();

export class LanguagesProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      languages: null,
      networkError: false
    };
  }

  componentDidMount() {
    UtilService.getLanguages().then((data) => {
      this.setState({
        languages: this.setupLanguages(data)
      })
    }).catch(e => {
      this.setState({
        networkError: true
      })
    });
  }

  setupLanguages(langs){
    return langs.map((l) => {
      return {label: l.name, value: l.languageCode}
    });
  }

  render() {
    return (
        <Context.Provider value={this.state}>
          {this.props.children}
        </Context.Provider>
    );
  }
}

export const LanguagesConsumer = Context.Consumer;