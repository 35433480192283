import React from 'react';

import {
    Alert,
    AlertMixin,
    Button,
    ConfirmationModal,
    TranslationMixin,
    WithCommonTranslations,
} from 'tds_shared_ui';
import {ReportsTable} from '../Reports';
import {PromoCodeService} from "../../services";
import {WithAdminTranslations} from '../hocs';

export const PromoCodeTable = WithAdminTranslations(WithCommonTranslations(
    class PromoCodeTable extends AlertMixin(TranslationMixin(React.Component)) {
        constructor(props) {
            super(props);

            this.state = {
                showModal: false,
                code: null,
                alert: {
                    display: false,
                    type: 'default',
                    message: '',
                },
            };

            this.handleOpen = this.handleOpen.bind(this);
            this.handleClose = this.handleClose.bind(this);
            this.decorateColumns = this.decorateColumns.bind(this);
            this.handleVoid = this.handleVoid.bind(this);
        }

        handleOpen(code) {
            this.setState({
                showModal: true,
                code: code,
            });
        }

        handleClose() {
            this.clearError();
            this.setState({
                showModal: false,

            });
        }

        handleVoid() {
            let code = this.state.code;
            this.clearError();

            return PromoCodeService.invalidatePromoCode([code]).then((data) => {
                let {tableData} = this.props;
                let newTableData = [];
                for (let i = 0; i < tableData.length; i++) {
                    let row = tableData[i];
                    if (row.indexOf(code) > -1) {
                        newTableData.push(data[0]);
                    } else {
                        newTableData.push(row.slice());
                    }
                }

                this.props.onSetTableData(newTableData);

                this.setState({
                    showModal: false,
                });

            }).catch(e => this.displayError(e));
        }

        decorateColumns(cols) {
            let statusIndex = null;
            let codeIndex = null;

            for (let i = 0; i < cols.length; i++) {
                let col = cols[i];
                if (col.header === 'Status') {
                    statusIndex = i;
                } else if (col.header === 'Code') {
                    codeIndex = i;
                }

                if (statusIndex && codeIndex) {
                    break;
                }
            }

            cols.push({
                    header: this.getTranslation('COLUMN_ACTION'),
                    accessor: 'action',
                    render: (rowData) => {
                        let status = rowData[statusIndex];
                        let code = rowData[codeIndex];

                        if (status === 'Valid') {
                            return <Button variant="link"
                                           onClick={() => this.handleOpen(code)}>

                                {this.getTranslation('BUTTON_VOID')}</Button>;
                        }
                    },
                },
            );

            return cols;
        }

        render() {
            let {showModal, alert} = this.state;
            let {tableHeaders, exportURL, tableData, tableAttributes, onError} = this.props;
            return (
                <div>
                    <ReportsTable
                        headers={tableHeaders}
                        exportUrl={exportURL}
                        tableData={tableData}
                        attributes={tableAttributes}
                        onError={onError}
                        decorateColumns={this.decorateColumns}/>

                    <ConfirmationModal
                        open={showModal}
                        className="loginConfirmModal"
                        title={this.getTranslation('CONFIRMATION_TITLE')}
                        content={
                            <div>
                                {alert.display &&
                                <Alert ref={this.alertRef}
                                       type={alert.type}>
                                    {alert.message}</Alert>}
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: this.getTranslation(
                                            'HTML_CONFIRMATION_BODY_MSG_VOID'),
                                    }}></p></div>}
                        okText={this.getTranslation('CONFIRMATION_SUCCESS')}
                        cancelText={this.getTranslation('CONFIRMATION_CANCEL')}
                        onCancel={this.handleClose}
                        onSuccess={this.handleVoid}/>
                </div>
            );
        }

    }));