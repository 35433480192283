import React from 'react';
import {Alert, Button, Header, TranslationMixin, AlertMixin} from 'tds_shared_ui';
import {CreateNotificationsPage} from './CreateNotificationsPage';
import {NotificationsTable} from '../components/Notifications/NotificationsTable';
import moment from 'moment';
import './NotificationsPage.css';
import {WithAdminTranslations} from '../components/hocs';


export const NotificationsPage = (WithAdminTranslations(
    class extends AlertMixin(TranslationMixin(
        React.Component)) {

        constructor(props) {
            super(props);

            this.alertRef = React.createRef();

            this.state = {
                alert: {
                    display: false,
                    type: 'default',
                    message: ''
                },
                showCreatePage: false,
                showModal: false,
                activeTab: 'main',
                notificationsDate: moment().format('YYYY-MM-DD'),
                tableMessage: '',
                notificationData: null
            };
            this.handleClick = this.handleClick.bind(this);
            this.handleCancel = this.handleCancel.bind(this);
            this.handleEdit = this.handleEdit.bind(this);
            this.handleSuccess = this.handleSuccess.bind(this);
            this.handleShowMainTable = this.handleShowMainTable.bind(this);
            this.handleShowHistoryTable = this.handleShowHistoryTable.bind(this);
        }


        componentDidMount() {
            this.showMainTable();
        }

        handleClick() {
            this.clearError();
            this.setState({
                showCreatePage: true,
                notificationData: null
            });
        }

        handleCancel() {
            this.setState({
                showCreatePage: false,
            });
        }

        handleShowMainTable(e) {
            e.preventDefault();
            this.showMainTable();
        }

        showMainTable() {
            this.setState({
                activeTab: 'main',
                notificationsDate: moment().format('YYYY-MM-DD'),
                tableMessage: this.getTranslation('MESSAGE_CURRENT_NOTIFICATIONS'),
            });
        }

        handleShowHistoryTable(e) {
            e.preventDefault();
            this.setState({
                activeTab: 'history',
                notificationsDate: moment().subtract(90, 'day').format('YYYY-MM-DD'),
                tableMessage: this.getTranslation('MESSAGE_LAST_90_DAYS_NOTIFICATIONS'),
            });
        }

        handleEdit(data) {
            this.clearError();
            this.setState({
                notificationData: data,
                showCreatePage: true,
            });

        }

        handleSuccess(e) {
            this.displayAlert(e,
                'success')
        }

        handleError(e) {
            this.displayError(e);
        }

        renderCreate() {
            return <CreateNotificationsPage onCancel={this.handleCancel}
                                            onSuccess={this.handleSuccess}
                                            notificationData={this.state.notificationData}/>;
        }


        render() {
            const {showCreatePage, notificationsDate, activeTab, tableMessage, alert} = this.state;
            if (showCreatePage) {
                return this.renderCreate();
            } else {
                return (
                    <div className="notificationsPage">
                        <div className="clearfix">
                            <Header level={1}>{this.getTranslation('HEADER_NOTIFICATION_MESSAGING')}</Header>
                            <p>{this.getTranslation('TEXT_INSTRUCTIONAL_NOTIF_PAGE')}</p>

                            {alert.display && <Alert ref={this.alertRef} type={alert.type}>
                                {alert.message}</Alert>}

                            <div className="float-md-right">
                                <Button onClick={this.handleClick}
                                        variant='primary'
                                        type='button'>{this.getTranslation('BUTTON_CREATE_NOTIFICATION')}</Button>
                            </div>
                        </div>
                        <ul className="tds-tabs">
                            <li className="tds-tab">
                                <button
                                   className={activeTab === 'main' ? 'active' : ''}
                                   onClick={this.handleShowMainTable}>{this.getTranslation('TAB_CURRENT')}</button>

                            </li>
                            <li className="tds-tab">
                                <button
                                   className={activeTab === 'history' ? 'active' : ''}
                                   onClick={this.handleShowHistoryTable}>{this.getTranslation('TAB_HISTORY')}</button>

                            </li>
                        </ul>

                        <NotificationsTable date={notificationsDate}
                                            onError={this.handleError}
                                            message={tableMessage}
                                            onEdit={this.handleEdit}/>
                    </div>
                );
            }
        }
    }));