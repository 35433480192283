import {CacheableAjaxService, adminAPI} from 'tds_shared_ui';

export default class CacheableHelperService extends CacheableAjaxService {

  static getOrderTypes(opts){
    return super.getAPI(adminAPI, `orders/orderTypes`, opts);
  }

  static getNotificationsOptions(){
    return super.getAPI(adminAPI, `notifications/options`,);
  }
}