import React from 'react';
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import {
  Alert,
  AuthService,
  UserService,
  UserTranslationsProvider,
  withRouter,
} from 'tds_shared_ui';

import {AppHeader} from './containers/AppHeader';
import {AdminUserPage} from './containers/AdminUserPage';
import {PromoCodePage} from './containers/PromoCodePage';
import {FinancialReportsPage} from './containers/FinancialReportsPage';
import {UserStatusReportsPage} from './containers/UserStatusReportsPage';
import {AdminStatusReportsPage} from "./containers/AdminStatusReportsPage";
import {KeycodeReportsPage} from './containers/KeycodeReportsPage';
import {TisReportsPage} from './containers/TisReportsPage';
import {NotificationsPage} from './containers/NotificationsPage';
import {LoginPage} from './containers/LoginPage';
import {TDSSettings} from "./containers/TDSSettings";
import {WithUser} from './components/hocs';
import {OrderRefundPage} from './containers/OrderRefundPage';
import {ACDelcoLookupPage} from './containers/ACDelcoLookupPage';
import {UnauthorizedService} from './services';
import {ResetPasswordPage, PasswordTranslationsProvider} from 'tds_shared_ui';
import {LanguagesConsumer} from './context';

import './App.css';

export const AppRouter = withRouter(WithUser(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };

    this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    UnauthorizedService.handleUnauthorizedErrors((error) => {
      this.props.setUser(null);
    });
  }

  handleLoginSuccess(user) {
    this.props.setUser(user);
  }

  handleLogout = () => {
    AuthService.logout()
        .then(() => {
          this.props.setUser(null);
        })
        .catch(error => {
          console.log(error);
        });
  };

  renderResetPasswordRoute() {
    return (
        <Route path="/resetpassword" element={
            <PasswordTranslationsProvider locale={this.props.locale}>
              <ResetPasswordPage locale={this.props.locale}/>
            </PasswordTranslationsProvider>
        }/>
    );
  }

  renderPublicRoutes() {
    return (
      <Routes>
        {this.renderResetPasswordRoute()}
        <Route path={this.props.location.pathname} element={
          <LoginPage onSuccess={this.handleLoginSuccess}/>
        }/>
      </Routes>
    );
  }

  renderSuperAdminRoutes(){
    let jsx = [];
    jsx.push(<Route path="/tdssettings" element={<TDSSettings />} key="tds"/>);
    jsx.push(<Route path="/acdelcolookup" element={<ACDelcoLookupPage />} key="acdelco"/>);
    jsx.push(<Route path="/adminstatus" element={<AdminStatusReportsPage />} key="adminstatus"/>);
    return jsx;
  }

  renderLoggedInRoutes() {
    const isSuperAdmin = UserService.isSuperAdmin(this.props.user);

    return (
        <Routes>
          <Route path="/" element={<Navigate to="/admin" replace={true} />}/>
          <Route path="/admin/*" element={
              <UserTranslationsProvider locale={this.props.locale}>
                <AdminUserPage />
              </UserTranslationsProvider>
          }/>
          <Route path="/order/:orderNumber" element={<OrderRefundPage />} />
          <Route path="/promo" element={<PromoCodePage />} />
          <Route path="/financial" element={<FinancialReportsPage />} />
          <Route path="/userstatus"
                 element={<UserStatusReportsPage />} />
          <Route path="/keycodes" element={<KeycodeReportsPage />} />
          <Route path="/tisreport" element={<TisReportsPage />} />
          <Route path="/notifications"
                 element={<NotificationsPage />} />
          {this.renderResetPasswordRoute()}
          {isSuperAdmin && this.renderSuperAdminRoutes()}
        </Routes>
    );
  }

  render() {

    return (
        <div>
          <AppHeader user={this.props.user}
                     onLogout={this.handleLogout}/>

          <div className="container">
            <LanguagesConsumer>
              {({networkError}) => (
                  networkError ?
                      <Alert type="error">Unknown error occurred. Please refresh
                        page to try again.</Alert>
                      :
                      this.props.user ?
                          this.renderLoggedInRoutes() :
                          this.renderPublicRoutes()
              )}
            </LanguagesConsumer>
          </div>
        </div>
    );
  }
}));

