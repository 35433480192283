import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import {CommonTranslationsProvider} from 'tds_shared_ui';

import {
  AdminTranslationsProvider,
  LanguagesProvider,
  UserProvider,
} from './context';
import {AppRouter} from './AppRouter';

import './App.css';

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locale: 'en-US',
    };
  }

  render() {
    return (
        <UserProvider>
          <CommonTranslationsProvider locale={this.state.locale}>
            <AdminTranslationsProvider locale={this.state.locale}>
              <LanguagesProvider>
                <Router>
                <AppRouter locale={this.state.locale}/>
                </Router>
              </LanguagesProvider>
            </AdminTranslationsProvider>
          </CommonTranslationsProvider>
        </UserProvider>
    );
  }
}

