import React from 'react';
import {
    TranslationMixin,
    WithCommonTranslations,
    Alert, AlertMixin
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../../hocs';

import {AuditLogSearchForm} from './AuditLogSearchForm';

export const AuditLogSearch = WithAdminTranslations(WithCommonTranslations(
    class AuditLogSearch extends AlertMixin(TranslationMixin(React.Component)) {
      constructor(props) {
        super(props);

        this.state ={
          alert: {
            display: false,
            type: 'default',
            message: '',
          },
        }

        this.handleValidationError = this.handleValidationError.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
      }

      handleValidationError(){
        this.displayAlert(this.getTranslation('ERROR_FORM_VALIDATION'), "error");
      };

      handleClear(){
        this.clearError();
        this.props.onClear();
      }

      handleSubmit(){
        this.clearError();
        this.props.onSubmit();
      }

      render() {
        const {formValues, loading, onChange} = this.props;
        return (
            <div>
              {this.state.alert.display &&
              <Alert ref={this.alertRef}
                     type={this.state.alert.type}>{this.state.alert.message}</Alert>}

              <AuditLogSearchForm
                  onValidationError={this.handleValidationError}
                  formValues={formValues}
                  loading={loading}
                  onClear={this.handleClear}
                  onChange={onChange}
                  submit={this.handleSubmit}/>
            </div>
        );
      }
    }));