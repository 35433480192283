import React from 'react';

import {
    Alert,
    Button,
    ConfirmationModal,
    LoadingIcon,
    Select,
    ToggleSwitch,
    TranslationMixin,
    UserService,
    WithCommonTranslations,
    WithUserTranslations,
} from 'tds_shared_ui';
import {WithAdminTranslations, WithUser} from '../hocs';

import {AdminUserService} from "../../services";
import {AuditLogs} from './auditlog/AuditLog';

import './UserCard.css';

export const UserCard = WithUser(WithCommonTranslations(WithAdminTranslations(
    WithUserTranslations(class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);

        this.state = {
          confirmMsg: '',
          loading: false,
          deleting: false,
          displayConfirm: false,
          showEditableRole: false,
          updatingRole: false,
          displayAudit: false,
          formValues: {
            role: '',
          },
          handleConfirm: () => {
          },
          alert: {
            display: false,
            type: 'default',
            message: '',
          },
        };

        this.handleDisable = this.handleDisable.bind(this);
        this.handleEnable = this.handleEnable.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleShowEditRole = this.handleShowEditRole.bind(this);
        this.handleSaveRole = this.handleSaveRole.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
          this.displayAuditLog = this.displayAuditLog.bind(this);
          this.toggleLocksmithDisabled = this.toggleLocksmithDisabled.bind(this);
      }

      componentDidMount() {
        this.setState({
          formValues: {
            role: this.props.profileUser.role,
          },
        });
      }

      handleCancel() {
        this.setState({
          displayConfirm: false,
          displayAudit: false,
        });
      }

      handleDisable() {
        this.setState({
          alert: {
            display: false,
          },
          displayConfirm: true,
          confirmMsg: this.getTranslation('HTML_CONFIRM_DISABLE_MSG'),
          handleConfirm: () => {
            this.setState({
              displayConfirm: false,
              loading: true,
            });
            AdminUserService.disable(this.props.profileUser.username)
                .then((user) => {
                  this.props.onUpdate(user);
                })
                .catch((e) => {
                  this.displayError(e);
                })
                .finally(() => {
                  this.setState({
                    loading: false,
                  });
                });
          },
        });

      }

      handleToggleNotifications = (checked) => {
        let adminFunction;
        if (checked) {
          adminFunction = AdminUserService.WatchUser;
        } else {
          adminFunction = AdminUserService.UnwatchUser;
        }
        return adminFunction(this.props.profileUser.username)
            .then((data) => {
              this.props.onUpdate(data);
            })
            .catch(e => this.displayError(e));
      };

        toggleLocksmithDisabled() {
            const {profileUser} = this.props;
            return AdminUserService.setLocksmithStatus(profileUser.username,
                !profileUser.locksmithDisabled)
                .then(d => {
                    this.props.onUpdate(d);
                })
                .catch(e => this.displayError(e));
        }

      handleEnable() {
        this.setState({
          alert: {
            display: false,
          },
          displayConfirm: true,
          confirmMsg: this.getTranslation('HTML_CONFIRM_ENABLE_MSG'),
          handleConfirm: () => {
            this.setState({
              displayConfirm: false,
              loading: true,
            });
            AdminUserService.enable(this.props.profileUser.username)
                .then((user) => {
                  this.props.onUpdate(user);
                })
                .catch((e) => {
                  this.displayError(e);
                })
                .finally(() => {
                  this.setState({
                    loading: false,
                  });
                });
          },
        });

      }

      handleDelete() {
        this.setState({
          alert: {
            display: false,
          },
          displayConfirm: true,
          confirmMsg: this.getTranslation('HTML_CONFIRM_DELETE_MSG'),
          handleConfirm: () => {
            this.setState({
              displayConfirm: false,
              deleting: true,
            });
            AdminUserService.delete(this.props.profileUser.username)
                .then(() => {
                  this.props.onUpdate(null);
                })
                .catch((e) => {
                  this.displayError(e);
                  this.setState({
                    deleting: false,
                  });
                });
          },
        });
      }

      handleShowEditRole = (bool) => () => {
        this.setState({
          showEditableRole: bool,
        });
      };

      handleChangeRole(e) {
        this.setState({
          formValues: {
            role: e,
          },
        });
      }

      handleSaveRole() {
        this.setState({
          updatingRole: true,
        });
        AdminUserService.updateRole(this.props.profileUser.username,
            this.state.formValues.role).then((data) => {
          this.props.onUpdate(data);
          this.setState({
            showEditableRole: false,
            updatingRole: false,
          });
        }).catch((e) => {
          this.displayError(e);
          this.setState({
            updatingRole: false,
          });
        });
      }

      displayError(e) {
        this.setState({
          alert: {
            display: true,
            type: 'error',
            message: e.translatedMessage ||
                this.getTranslation('ERROR_GENERIC'),
          },
        });
      }

      displayAuditLog() {
        this.setState({
          displayAudit: true,
        });
      }

      renderRole() {
        const {profileUser} = this.props;
        const isSuperAdmin = UserService.isSuperAdmin(this.props.user);

        if (!isSuperAdmin) {
          return (
              <div><strong>{this.getTranslation('LABEL_ROLE')}:
              </strong> {profileUser.roleDisplay}</div>
          );
        } else {
          return (
              <div><strong>{this.getTranslation('LABEL_ROLE')}:&nbsp;
              </strong>
                {this.state.showEditableRole ?
                    <React.Fragment>
                      <Select
                          name="role"
                          id="role"
                          className="d-inline-block"
                          options={this.props.roles}
                          value={this.state.formValues.role}
                          onChange={this.handleChangeRole}/>
                      <Button variant="link" type="button"
                              className="user-card-btn"
                              disabled={this.state.updatingRole}
                              onClick={this.handleSaveRole}>
                        <LoadingIcon display={this.state.updatingRole}/>
                        {this.getTranslation('BUTTON_SAVE')}
                      </Button>
                      <Button variant="link" type="button"
                              className="user-card-btn"
                              onClick={this.handleShowEditRole(false)}>
                        {this.getTranslation('BUTTON_CANCEL')}
                      </Button>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      {profileUser.roleDisplay}
                      <Button variant="link" type="button"
                              className="user-card-btn"
                              onClick={this.handleShowEditRole(true)}>
                        {this.getTranslation('LABEL_EDIT')}
                      </Button>
                    </React.Fragment>
                }
              </div>
          );
        }
      }

        renderLocksmith() {
            const {profileUser, user} = this.props;
            const {locksmithID, locksmithDisabled} = profileUser;
            const isSuperAdmin = UserService.isSuperAdmin(user);

            if (locksmithID) {
                let btnLabel = 'LABEL_DISABLE_VSC';
                let cssClass = '';
                let additional = '';
                if (locksmithDisabled) {
                    btnLabel = 'LABEL_ENABLE_VSC';
                    cssClass = 'font-italic text-muted';
                }
                let regionCode = user.address.country;
                let locksmithLabel;
                if (regionCode === "AU") {
                    locksmithLabel = 'AASRA'
                } else {
                    locksmithLabel = this.getTranslation('LABEL_VSC_ID');
                }

                if (isSuperAdmin) {
                    additional =
                        <Button type="button" variant="link" className="user-card-btn"
                                onClick={this.toggleLocksmithDisabled}
                        >{this.getTranslation(btnLabel)}</Button>;
                } else {
                    if (locksmithDisabled) {
                        additional = <span className="ml-1">{this.getTranslation(
                            'LABEL_DISABLED')}</span>;
                    }
          }

          return (
              <div>
                  <strong>{locksmithLabel}:
                  </strong> <span className={cssClass}>{locksmithID}</span>
                  {additional}
              </div>
          );
        }
      }

      render() {
        const {profileUser} = this.props;
        let statusTrans;
        let statusIcon;
        let buttonTrans;
        let buttonAction;
        if (profileUser.disabled === true) {
          statusTrans = 'LABEL_DISABLED';
          statusIcon = 'fas fa-times-circle';
          buttonTrans = 'BUTTON_ENABLE_USER';
          buttonAction = this.handleEnable;
        } else {
          statusTrans = 'LABEL_ACTIVE';
          statusIcon = 'fas fa-check-circle';
          buttonTrans = 'BUTTON_DISABLE_USER';
          buttonAction = this.handleDisable;
        }

        return (
            <div>
              {this.state.alert.display &&
              <Alert ref={this.alertRef}
                     type={this.state.alert.type}>{this.state.alert.message}</Alert>}

              <div className="user-card w-auto">
                <div className="user-title">{this.getTranslation(
                    'LABEL_STATUS')}:&nbsp;
                  {this.getTranslation(statusTrans)}&nbsp;
                  <i className={statusIcon}></i></div>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-auto">
                      <div><strong>{this.getTranslation(
                          'LABEL_LOGIN_NAME')}:</strong> {profileUser.username}
                      </div>
                      <div><strong>{this.getTranslation(
                          'LABEL_NAME')}:</strong> {profileUser.firstName} {profileUser.lastName}
                      </div>
                      <div><strong>{this.getTranslation('LABEL_LAST_LOGIN')}: </strong>{profileUser.lastLoginTime}</div>

                        {this.renderLocksmith()}
                        {this.renderRole()}
                      <div><strong>{this.getTranslation(
                          'LABEL_SOURCE_SYSTEM')}:</strong> {profileUser.sourceSystem}
                      </div>
                      <div><strong>{this.getTranslation(
                          'LABEL_LOCKED')}:</strong> {this.getTranslation(
                          profileUser.locked ? 'BUTTON_YES' : 'BUTTON_NO')}
                      </div>
                      <div><strong>{this.getTranslation(
                          'HEADER_WATCH_LIST')}:</strong>
                        <ToggleSwitch on={profileUser.watched || false}
                                      ariaLabel={this.getTranslation(
                                          'HEADER_WATCH_LIST')}
                                      onChange={(checked) => this.handleToggleNotifications(
                                          checked)}
                                      onLabel={this.getTranslation(
                                          'LABEL_ON')}
                                      offLabel={this.getTranslation(
                                          'LABEL_OFF')}
                                      onError={this.displayError}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user-btn">
                  <Button variant="primary" type="button"
                          disabled={this.state.loading}
                          className="w-100" onClick={buttonAction}>
                    <LoadingIcon display={this.state.loading}/>&nbsp;
                    {this.getTranslation(buttonTrans)}</Button>
                </div>
                <div className="auditlog-link-btn">
                  <Button variant="link" type="button"
                          disabled={this.state.loading}
                          className="w-100" onClick={this.displayAuditLog}>
                    <LoadingIcon display={this.state.loading}/>&nbsp;
                    {this.getTranslation('BUTTON_AUDIT_LOG')}</Button>
                </div>
                <div className="delete-btn">
                  <Button variant="link" type="button"
                          onClick={this.handleDelete}
                          disabled={this.state.deleting}>
                    <LoadingIcon display={this.state.deleting}/>&nbsp;
                    {this.getTranslation('LABEL_DELETE_USER')}
                  </Button>
                </div>

              </div>

              <ConfirmationModal
                  title={this.getTranslation('HEADER_CONFIRM')}
                  content={
                    <p dangerouslySetInnerHTML={{
                      __html: this.state.confirmMsg,
                    }}></p>}
                  open={this.state.displayConfirm}
                  cancelText={this.getTranslation('BUTTON_CANCEL')}
                  okText={this.getTranslation('BUTTON_YES')}
                  onCancel={this.handleCancel}
                  onSuccess={this.state.handleConfirm}/>

              {this.state.displayAudit && <AuditLogs
                  open={this.state.displayAudit}
                  username={profileUser.username}
                  onCancel={this.handleCancel}/>}
            </div>
        );
      }
    }))));