import React from 'react';
import {
    DatePicker,
    Select,
    TranslationMixin,
    WithCommonTranslations,
} from 'tds_shared_ui';
import {CacheableHelperService} from '../../services'
import {LanguageCheckBox} from './LanguageCheckBox';
import {WithAdminTranslations} from '../hocs';

export const NotificationProperties = WithAdminTranslations(
    WithCommonTranslations(class extends TranslationMixin(React.Component) {
        constructor(props) {
            super(props);

            this.state = {
                audienceOptions: [],
                regionOptions: [],
                typeOptions: [],
                languageOptions: [],
                checked: false,
                disabled: false,
                beginDate: null,
                endDate: null,
            };

            this.handleSelectChange = this.handleSelectChange.bind(this);
            this.fetchOptions = this.fetchOptions.bind(this);
            this.checkRegions = this.checkRegions.bind(this);
            this.handleChecked = this.handleChecked.bind(this);
        }

        componentDidMount() {
            this.fetchOptions();
        }

        static getDerivedStateFromProps(props) {
            let beginDate = null;
            let endDate = null;
            let fv = props.formValues;
            if (fv) {
                if (fv.begin) {
                    beginDate = typeof fv.begin === 'string' ? new Date(fv.begin) : fv.begin;
                }
                if (fv.end) {
                    endDate = typeof fv.end === 'string' ? new Date(fv.end) : fv.end;
                }
            }

            return {
                beginDate: beginDate,
                endDate: endDate,
            };
        }

        checkRegions() {
            if (this.props.formValues.regions.length === 0) {
                return true;
            }
        }

        checkAll(newRegions) {
            const oldRegions = this.props.formValues.regions;
            if (!oldRegions.includes('all') && newRegions.includes('all')) {
                let newArray = Array.of('all');
                return newArray;
            } else if (oldRegions.includes('all') && newRegions.includes('all') &&
                newRegions.length > 1) {
                let newArray = newRegions.filter(region => region !== 'all');
                return newArray;
            }
            else {
                return newRegions;
            }
        }

        fetchOptions() {
            CacheableHelperService.getNotificationsOptions().then((data) => {
                this.setState({
                    audienceOptions: data.audienceOptions,
                    regionOptions: data.regionOptions,
                    typeOptions: data.typeOptions,
                    languageOptions: data.languageOptions,
                });
            }).catch(() => {
                let message = this.getTranslation('ERROR_NOTIFICATION_OPTIONS');
                this.props.onError(message);
            });
        }

        handleSelectChange = (name) => (value) => {
            if (name === 'regions') {
                let validRegions = this.checkAll(value);
                this.props.onChange(name, validRegions);
            }
            else {
                this.props.onChange(name, value);
            }
        };

        handleChecked = (languageCode, language, id) => (e) => {
            this.props.onSelectChange(languageCode, language, id, e.target.checked);
        };

        render() {
            const {audienceOptions, regionOptions, typeOptions, languageOptions, beginDate, endDate, disabled} = this.state;
            const {formValues, isValidated, beginRef, endRef, selectedLanguages, beginDateErrorMsg, endDateErrorMsg, onChange, readOnly} = this.props;
            return (
                <div>
                    <div className="row">
                        <div className="col-sm-2">
                            <Select label={this.getTranslation('HEADER_RECIPIENT')}
                                    name="Recipient"
                                    id="Recipient"
                                    required={true}
                                    options={audienceOptions}
                                    value={formValues.audience}
                                    readOnly={readOnly}
                                    error={isValidated && !formValues.audience}
                                    errorMessage={this.getTranslation(
                                        'ERROR_REQUIRED')}
                                    onChange={this.handleSelectChange('audience')}/>
                        </div>

                        <div className="col-sm-2">
                            <Select label={this.getTranslation('HEADER_REGION')}
                                    name="Region"
                                    id="Region"
                                    required={true}
                                    multi={true}
                                    removable={true}
                                    options={regionOptions}
                                    value={formValues.regions}
                                    readOnly={readOnly}
                                    error={isValidated && this.checkRegions()}
                                    errorMessage={this.getTranslation(
                                        'ERROR_REQUIRED')}
                                    onChange={this.handleSelectChange('regions')}/>
                        </div>

                        <div className="col-sm-2">
                            <Select label={this.getTranslation('HEADER_MESSAGE_TYPE')}
                                    name="Message Type"
                                    id="Message Type"
                                    required={true}
                                    options={typeOptions}
                                    value={formValues.type}
                                    readOnly={readOnly}
                                    error={isValidated && !formValues.type}
                                    errorMessage={this.getTranslation(
                                        'ERROR_REQUIRED')}
                                    onChange={this.handleSelectChange('type')}/>
                        </div>

                        <div className="col-sm-4">
                            <div className="row">
                                <div className="col-6">
                                    <DatePicker
                                        label={this.getTranslation('HEADER_START_DATE')}
                                        showTimeSelect
                                        timeIntervals={60}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        autoComplete="off"
                                        startDate={beginDate}
                                        endDate={endDate}
                                        ref={beginRef}
                                        invalidDateMsg={this.getTranslation(
                                            'ERROR_INVALID_DATE')}
                                        required={true}
                                        id={'begin'}
                                        displayError={isValidated}
                                        customError={beginDateErrorMsg}
                                        onChange={(begin) => onChange('begin', begin)}
                                        selected={beginDate}/>
                                </div>
                                <div className="col-6">
                                    <DatePicker label={this.getTranslation('HEADER_END_DATE')}
                                                showTimeSelect
                                                timeIntervals={60}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete="off"
                                                startDate={beginDate}
                                                endDate={endDate}
                                                ref={endRef}
                                                invalidDateMsg={this.getTranslation(
                                                    'ERROR_INVALID_DATE')}
                                                required={true}
                                                id={'end'}
                                                displayError={isValidated}
                                                customError={endDateErrorMsg}
                                                onChange={(end) => onChange('end', end)}
                                                selected={endDate}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="mb-0"><b>{this.getTranslation(
                        'HEADER_LANGUAGES')}</b></p>
                    {languageOptions && languageOptions.map((languageOption) =>
                        <LanguageCheckBox checkBoxHeader='LANGUAGES'
                                          checkBoxLabel={languageOption.label}
                                          checked={selectedLanguages &&
                                          (selectedLanguages.includes(
                                              languageOption.label) ||
                                              languageOption.label === 'English')}
                                          disabled={disabled ||
                                          languageOption.label === 'English'}
                                          onChange={this.handleChecked(
                                              languageOption.value,
                                              languageOption.label, '')}
                                          id={languageOption.label}
                                          htmlFor={languageOption.label}
                                          key={languageOption.label}/>)}
                </div>
            );
        }
    }));