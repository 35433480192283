import React from 'react';
import moment from 'moment';

import {
    AlertMixin,
    Button,
    Header,
    LoadingIcon,
    Modal,
    WithCommonTranslations,
    TranslationMixin,
    Table, Alert,
} from 'tds_shared_ui';
import {AuditLogService} from "../../../services";
import {WithAdminTranslations} from '../../hocs';

import {AuditLogSearch} from './AuditLogSearch';
import './AuditLog.css'

export const AuditLogs =
    WithAdminTranslations(WithCommonTranslations(
        class AuditLogs extends AlertMixin(
            TranslationMixin(React.Component)) {
            constructor(props) {
                super(props);

            this.state = {
              loading: false,
              saving: false,
              date: moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              auditData: [],
              searching: false,
              formValues: {
                beginDate: null,
                endDate: null,
                searchDate: null,
              },
              alert: {
                display: false,
                type: 'default',
                message: '',
              },
            };

            this.getAuditLog = this.getAuditLog.bind(this);
            this.handleChange = this.handleChange.bind(this);
            this.handleClear = this.handleClear.bind(this);
            this.handleSearch = this.handleSearch.bind(this);
          }

          componentDidMount() {
            if(this.props.username) {
              this.getAuditLog();
            }
          }

          componentDidUpdate(prevProps) {
            if (prevProps.username !== this.props.username ||
                (this.props.open && !prevProps.open)) {
              this.getAuditLog();
            }
          }

          getAuditLog(){
            this.setState({loading: true});
            AuditLogService.get(this.props.username, this.state.date).then((data) => {
              const {auditItemDisplayList} = data;
              this.setState({
                auditData: auditItemDisplayList,
                loading: false,
              });
            }).catch(e => {
              this.displayError(e)
              this.setState({loading: false});
            });
          }

          handleChange(name, value, callback) {
            this.setState((prevState) => {
              let newState = {};
              let formValues = Object.assign({}, prevState.formValues);
              formValues[name] = value;
              newState.formValues = formValues;
              return newState;
            }, callback);
          }

          handleSearch() {
            let formValues = Object.assign({}, this.state.formValues);
            this.search(formValues);
          }

          handleClear() {
            this.clearError();
            this.setState({
              formValues: {
                beginDate: null,
                searchDate: null,
              },
            });
            this.getAuditLog();
          }

          search(formValues) {
            this.clearError();
            this.setState({
              searching: true,
              auditData: null,
              loading: true,
            });

            let dateDif = moment().diff(formValues.beginDate, 'days');
            formValues.searchDate = moment().subtract(dateDif, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ');


            return AuditLogService.get(this.props.username, formValues.searchDate).then((data) => {
              const {auditItemDisplayList} = data;
              this.setState({
                auditData: auditItemDisplayList,
                searching: false,
                loading: false,
              })
            }).catch(e => {
              if (!e.unmounted) {
                this.displayError(e);
                this.setState({
                  searching: false,
                  loading: false,
                });
              }
            });
          }

          getColumns() {
            return [
              {
                header: this.getTranslation('COLUMN_PROMO_TYPE'),
                accessor: 'type',
              },
              {
                header: this.getTranslation('COLUMN_CREATED_DATE'),
                accessor: 'createdDate',
              },
              {
                header: this.getTranslation('COLUMN_CREATED_BY'),
                accessor: 'actionOwner',
              },
              {
                header: this.getTranslation('LABEL_DESCRIPTION'),
                accessor: 'description',
              },
              {
                header: this.getTranslation('TITLE_COMMENTS'),
                accessor: 'adminComment',
              }];

          }

          render() {
            const {
              open, onCancel
            } = this.props;
            const {auditData, formValues, loading, searching} = this.state;
            const columns = this.getColumns();

            return (
                <Modal header={<Header level={1}>
                  {this.getTranslation('HEADER_AUDIT_LOG')}</Header>}
                       onClose={onCancel}
                       open={open}
                       onBackdropClick={onCancel}>
                  <div className='auditlog-modal'>
                    {this.state.alert.display &&
                    <Alert ref={this.alertRef}
                           type={this.state.alert.type}>{this.state.alert.message}</Alert>}

                    <p>{this.getTranslation(
                        'MESSAGE_AUDITLOG_INSTRUCTIONS')}</p>

                    <AuditLogSearch formValues={formValues}
                                    loading={searching}
                                    onChange={this.handleChange}
                                    onClear={this.handleClear}
                                    onSubmit={this.handleSearch}/>

                    <div className="loading-icon">
                      <LoadingIcon display={loading}
                                   text={this.getTranslation('MESSAGE_LOADING')}/>
                    </div>

                    <React.Fragment>
                      {!searching && !loading && <Table columns={columns} rows={auditData}
                             filterId="subFilter"/>}
                    </React.Fragment>

                    <div className="float-right close-btn">
                                    <span className="pr-2">
                                <Button type="button"
                                        variant="primary"
                                        onClick={onCancel}>{this.getTranslation(
                                    'BUTTON_CLOSE')}</Button>
                                    </span>
                    </div>
                  </div>

                </Modal>
            );
          }
        }));