import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';

import {
  Header,
  TranslationMixin,
  AlertMixin,
  WithCommonTranslations,
  Alert, withRouter,
} from 'tds_shared_ui';
import {KeycodeProperties, ReportsTable} from '../components/Reports';
import {WithAdminTranslations, WithUser} from '../components/hocs';
import {Link} from 'react-router-dom';

import {ReportsService} from "../services";

export const KeycodeReportsPage = withRouter(WithAdminTranslations(
  WithUser(WithCommonTranslations(
    class KeycodeReportsPage extends AlertMixin(
      TranslationMixin(React.Component)) {
      constructor(props) {
        super(props);

        this.beginRef = React.createRef();
        this.endRef = React.createRef();

        this.state = {
          formValues: {
            startDate: '',
            reportType: 'all',
            username: '',
            vsc: '',
            vin: '',
            endDate: '',
            whichReport: null,
          },
          alert: {
            display: false,
            type: 'default',
            message: '',
          },
          exportUrl: null,
          beginDateErrorMsg: '',
          endDateErrorMsg: '',
          loading: false,
          tableHeaders: [],
          tableAttributes: '',
          tableData: [],
          showTable: false,
        };

        this.handleError = this.handleError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.validateTimes = this.validateTimes.bind(this);
        this.onValidationError = this.onValidationError.bind(this);
        this.fetchTable = this.fetchTable.bind(this);
        this.decorateColumns = this.decorateColumns.bind(this);
      }

      getInput(ref) {
        const dom = ReactDOM.findDOMNode(ref.current);
        return dom.querySelector('input');
      }

      onValidationError() {
        this.displayAlert(this.getTranslation('ERROR_FORM_VALIDATION'),
          'error');
      };

      handleReset() {
        this.setState({
          formValues: {
            startDate: null,
            reportType: 'all',
            username: '',
            vsc: '',
            vin: '',
            endDate: null,
            whichReport: null,
          },
          tableHeaders: [],
          tableData: [],
          tableAttributes: null,
          showTable: false,
        });
      }

      validateTimes() {
        let beginInput = this.getInput(this.beginRef);
        let endInput = this.getInput(this.endRef);

        let beginDate = moment(this.state.formValues.startDate);
        let endDate = moment(this.state.formValues.endDate);

        let beginErrorMsg = '';
        let endErrorMsg = '';

        if (beginDate.isValid() && endDate.isValid()) {
          if (beginDate.isAfter(endDate)) {
            beginErrorMsg = this.getTranslation('ERROR_START_AFTER_END');
            endErrorMsg = this.getTranslation('ERROR_END_BEFORE_START');
          }
        }

        beginInput.setCustomValidity(beginErrorMsg);
        endInput.setCustomValidity(endErrorMsg);

        this.setState({
          beginDateErrorMsg: beginErrorMsg,
          endDateErrorMsg: endErrorMsg,
        });
      }

      handleError(message) {
        this.displayAlert(message, 'error');
      }

      handleChange(name, value) {
        this.setState((prevState) => {
          let newState = {...prevState};
          newState.formValues[name] = value;
          return newState;
        }, () => {
          if (name === 'startDate' || name === 'endDate') {
            this.validateTimes();
          }
        });
      }

      getReportCriteria(data) {
        let {startDate, endDate} = data;
        if (startDate) {
          data.startDate = moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ');
        }
        if (endDate) {
          data.endDate = moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ');
        }

        return data;
      }

      fetchTable() {
        this.clearError();
        let saveData = this.getReportCriteria({...this.state.formValues});
        let exportUrl;
        let reportFunction;

        if (this.state.formValues.whichReport === 'Keycode Log') {
          exportUrl = ReportsService.getKeyCodeReportCSVUrl(saveData);
          reportFunction = ReportsService.getKeycodeReport;
        } else {
          exportUrl = ReportsService.getAVGKeyCodeReportCSVUrl(saveData);
          reportFunction = ReportsService.getAVGKeycodeReport;
        }

        this.setState({
          exportUrl: exportUrl,
          loading: true,
        });

        reportFunction(saveData).then((data) => {
          this.setState({
            tableHeaders: data.headers,
            tableData: data.data,
            tableAttributes: data.attributes,
            loading: false,
            showTable: true,
          });
        }).catch((e) => {
          this.displayError(e);
        }).finally(() => {
          this.setState({
            loading: false,
          });
        });
      }

          decorateColumns(cols) {
            for (let i = 0; i < cols.length; i++) {
              let col = cols[i];
              if (col.header === 'User Name' || col.header === 'User ID') {
                col.render = (rowData) => {
                  let username = rowData[i];
                  return <Link to={'/admin/user/profile?username=' + username} >{username}</Link>
                };
              }
            }

        return cols;
      }

      render() {
        let {showTable} = this.state;

        return (
          <div className="keycodeReportsPage">
            <div className="clearfix">
              <Header level={1}>{this.getTranslation(
                'HEADER_KEYCODE_REPORTS_PAGE')}</Header>
              <p>{this.getTranslation('MESSAGE_REPORTS')}</p>
              {this.state.alert.display &&
                <Alert ref={this.alertRef} type={this.state.alert.type}>
                  {this.state.alert.message}</Alert>}


              <KeycodeProperties
                formValues={this.state.formValues}
                onChange={this.handleChange}
                onCancel={this.handleReset}
                onError={this.handleError}
                onValidationError={this.onValidationError}
                loading={this.state.loading}
                beginDateErrorMsg={this.state.beginDateErrorMsg}
                endDateErrorMsg={this.state.endDateErrorMsg}
                submit={this.fetchTable}
                beginRef={this.beginRef}
                endRef={this.endRef}/>

              {showTable &&
                <ReportsTable
                  onError={this.handleError}
                  headers={this.state.tableHeaders}
                  attributes={this.state.tableAttributes}
                  exportUrl={this.state.exportUrl}
                  tableData={this.state.tableData}
                  decorateColumns={this.decorateColumns}/>
              }

                  </div>
                </div>
            );
          }
        }))));