import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import {
    Alert,
    AlertMixin,
    Header,
    TranslationMixin,
    WithCommonTranslations,
    HelperUtils,
} from 'tds_shared_ui';
import {PromoCodeService} from "../services";
import {WithAdminTranslations} from "../components/hocs";
import {CreatePromoCodeProperties, PromoCodeTable} from '../components/PromoCodes';

import './PromoCodePage.css';

export const CreatePromoCodePage = WithAdminTranslations(
    WithCommonTranslations(
        class CreatePromoCodePage extends AlertMixin(TranslationMixin(React.Component)) {
            constructor(props) {
                super(props);

                this.alertRef = React.createRef();
                this.beginRef = React.createRef();
                this.endRef = React.createRef();
                this.tableRef = React.createRef();

                this.state = {
                    beginDateErrorMsg: '',
                    endDateErrorMsg: '',
                    date: '',
                    code: '',
                    showTable: false,
                    tableHeaders: [],
                    tableData: [],
                    tableAttributes: [],
                    exportUrl: '',
                    loading: false,
                    saveData: '',
                    formValues: {
                        isPercentDiscount: false,
                        productId: '',
                        numUses: '',
                        numCodes: '',
                        codeType: '',
                        startDate: '',
                        endDate: '',
                        regions: '',
                        percent: '',
                        discountAmount: '',
                        discount: '',
                    },
                    alert: {
                        display: false,
                        type: 'default',
                        message: '',
                    },
                };

                this.validateTimes = this.validateTimes.bind(this);
                this.scrollToAlert = this.scrollToAlert.bind(this);
                this.handleSubmit = this.handleSubmit.bind(this);
                this.handleError = this.handleError.bind(this);
                this.handleReset = this.handleReset.bind(this);
                this.handleChange = this.handleChange.bind(this);
                this.onValidationError = this.onValidationError.bind(this);
            }

            getInput(ref) {
                const dom = ReactDOM.findDOMNode(ref.current);
                return dom.querySelector('input');
            }

            scrollToAlert() {
                HelperUtils.scrollToAlert(this.alertRef);
            }

            onValidationError() {
                this.displayError(this.getTranslation('ERROR_FORM_VALIDATION'),
                    this.scrollToAlert);
            };

            handleSubmit() {
                this.clearError();
                let saveData = this.getPromoCodeData(this.state.formValues);

                this.setState({
                    loading: true,
                });
                PromoCodeService.createPromoCode(saveData).then((data) => {
                    let {reportId, ...attributes} = data.attributes;
                    this.setState({
                        tableHeaders: data.headers,
                        tableData: data.data,
                        tableAttributes: attributes,
                        loading: false,
                        showTable: true,
                        exportUrl: PromoCodeService.getCreatedPromoCodeDownloadUrl({reportId: reportId})
                    }, () => {
                      this.handleReset();
                      HelperUtils.scrollToAlert(this.tableRef);
                    });
                }).catch(e => this.displayError(e, this.scrollToAlert),
                ).finally(() => {
                    this.setState({
                        loading: false,
                    });
                });

            }

            validateTimes() {
                let beginInput = this.getInput(this.beginRef);
                let endInput = this.getInput(this.endRef);

                let beginDate = moment(this.state.formValues.startDate);
                let endDate = moment(this.state.formValues.endDate);

                let beginErrorMsg = '';
                let endErrorMsg = '';

                if (beginDate.isValid() && endDate.isValid()) {
                    if (beginDate.isBefore(new Date(), 'day')) {
                        beginErrorMsg = this.getTranslation('ERROR_START_DATE_IN_PAST');
                    } else if (beginDate.isAfter(endDate)) {
                        beginErrorMsg = this.getTranslation('ERROR_START_AFTER_END');
                        endErrorMsg = this.getTranslation('ERROR_END_BEFORE_START');
                    }
                }

                beginInput.setCustomValidity(beginErrorMsg);
                endInput.setCustomValidity(endErrorMsg);

                this.setState({
                    beginDateErrorMsg: beginErrorMsg,
                    endDateErrorMsg: endErrorMsg,
                    isValidated: true,
                });
            }

            getPromoCodeData(data) {
                let promoCodeData = {};

                promoCodeData.codeType = data.codeType;
                promoCodeData.region = data.regions;
                promoCodeData.productId = data.productId;
                promoCodeData.numUses = data.numUses;
                promoCodeData.startDate = data.startDate;
                promoCodeData.endDate = data.endDate;
                promoCodeData.numCodes = data.numCodes;

                promoCodeData.startDate = moment(data.startDate)
                    .format('YYYY-MM-DDTHH:mm:ssZ');
                promoCodeData.endDate = moment(data.endDate)
                    .format('YYYY-MM-DDTHH:mm:ssZ');

                if (data.isPercentDiscount) {
                    promoCodeData.percent = data.discount;
                } else {
                    promoCodeData.discountAmount = data.discount;
                }

                return promoCodeData;
            }

            handleError(message) {
                this.displayError(message, this.scrollToAlert);
            }

            handleReset() {
                this.setState({
                    formValues: {
                        isPercentDiscount: false,
                        numUses: '',
                        numCodes: '',
                        productId: '',
                        startDate: '',
                        endDate: '',
                        regions: '',
                        discount: '',
                    },
                });
            }

            handleChange(name, value) {
                this.setState((prevState) => {
                    let formValues = {...prevState.formValues};
                    formValues[name] = value;
                    if (name === 'regions') {
                        formValues.productId = null;
                        formValues.codeType = null;
                    } else if (name === 'productId') {
                        formValues.codeType = null;
                    }
                    return {formValues: formValues};
                }, () => {
                    if (name === 'startDate' || name === 'endDate') {
                        this.validateTimes();
                    }
                });
            }

            render() {
                const {alert, formValues, loading, showTable, beginDateErrorMsg, endDateErrorMsg, tableAttributes, tableData, tableHeaders} = this.state;
                const {onCancel} = this.props;
                return (
                    <div className="createPromoPage">
                        {alert.display &&
                        <Alert ref={this.alertRef}
                               type={alert.type}>
                            {alert.message}</Alert>}

                        <Header level={1}>{this.getTranslation(
                            'HEADER_CREATE_PROMO_CODES')}</Header>
                        <p>{this.getTranslation('MESSAGE_PROMO_CODES')}</p>

                        <CreatePromoCodeProperties
                            onChange={this.handleChange}
                            onError={this.handleError}
                            onCancel={onCancel}
                            formValues={formValues}
                            loading={loading}
                            onReset={this.handleReset}
                            beginDateErrorMsg={beginDateErrorMsg}
                            endDateErrorMsg={endDateErrorMsg}
                            submit={this.handleSubmit}
                            beginRef={this.beginRef}
                            endRef={this.endRef}/>


                        {showTable &&
                        <PromoCodeTable
                            ref={this.tableRef}
                            exportURL={this.state.exportUrl}
                            onSetTableData={d => this.setState({tableData: d})}
                            tableAttributes={tableAttributes}
                            tableData={tableData}
                            tableHeaders={tableHeaders}/>}
                    </div>
                );
            }

        }));