import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';

import {
    Header,
    TranslationMixin,
    AlertMixin,
    WithCommonTranslations,
    Alert,
} from 'tds_shared_ui';
import {TisProperties, ReportsTable} from '../components/Reports';
import {WithAdminTranslations, WithUser} from '../components/hocs';

import {ReportsService} from "../services";

export const TisReportsPage = WithAdminTranslations(
    WithUser(WithCommonTranslations(
        class TisReportsPage extends AlertMixin(
            TranslationMixin(React.Component)) {
            constructor(props) {
                super(props);

                this.beginRef = React.createRef();
                this.endRef = React.createRef();

                this.state = {
                    formValues: {
                        reportType: 'all',
                        whichReport: null,
                    },
                    alert: {
                        display: false,
                        type: 'default',
                        message: '',
                    },
                    exportUrl: null,
                    loading: false,
                    tableHeaders: [],
                    tableAttributes: '',
                    tableData: [],
                    showTable: false,
                };

                this.handleError = this.handleError.bind(this);
                this.handleChange = this.handleChange.bind(this);
                this.handleReset = this.handleReset.bind(this);
                this.onValidationError = this.onValidationError.bind(this);
                this.fetchTable = this.fetchTable.bind(this);
                this.decorateColumns = this.decorateColumns.bind(this);
            }

            getInput(ref) {
                const dom = ReactDOM.findDOMNode(ref.current);
                return dom.querySelector('input');
            }

            onValidationError() {
                this.displayAlert(this.getTranslation('ERROR_FORM_VALIDATION'),
                    'error');
            };

            handleReset() {
                this.setState({
                    formValues: {
                        reportType: 'all',
                    },
                });
            }

            handleError(message) {
                this.displayAlert(message, 'error');
            }

            handleChange(name, value) {
                this.setState((prevState) => {
                    let newState = {...prevState};
                    newState.formValues[name] = value;
                    return newState;
                }, () => {
                    if (name === 'startDate' || name === 'endDate') {
                        this.validateTimes();
                    }
                });
            }

            getReportCriteria(data) {
                let {startDate, endDate} = data;
                if (startDate) {
                    data.startDate = moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ');
                }
                if (endDate) {
                    data.endDate = moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ');
                }

                return data;
            }

            fetchTable() {
                this.clearError();
                let saveData = this.getReportCriteria({...this.state.formValues});
                let exportUrl = ReportsService.getTisSubscriptionsReportCSVUrl(saveData);
                let reportFunction = ReportsService.getTisSubscriptionsReport;

                this.setState({
                    exportUrl: exportUrl,
                    loading: true,
                });

                reportFunction(saveData).then((data) => {
                    this.setState({
                        tableHeaders: data.headers,
                        tableData: data.data,
                        tableAttributes: data.attributes,
                        loading: false,
                        showTable: true,
                    });
                }).catch((e) => {
                    this.displayError(e);
                }).finally(() => {
                    this.setState({
                        loading: false,
                    });
                });
            }

            decorateColumns(cols) {
                return cols;
            }

            render() {
                let {showTable} = this.state;

                return (
                    <div className="tisReportsPage">
                        <div className="clearfix">
                            <Header level={1}>{'Tis Reports Page'}</Header>
                            <p>{this.getTranslation('MESSAGE_REPORTS')}</p>
                            {this.state.alert.display &&
                            <Alert ref={this.alertRef} type={this.state.alert.type}>
                                {this.state.alert.message}</Alert>}


                            <TisProperties
                                formValues={this.state.formValues}
                                onChange={this.handleChange}
                                onCancel={this.handleReset}
                                onError={this.handleError}
                                onValidationError={this.onValidationError}
                                loading={this.state.loading}
                                beginDateErrorMsg={this.state.beginDateErrorMsg}
                                endDateErrorMsg={this.state.endDateErrorMsg}
                                submit={this.fetchTable}
                                beginRef={this.beginRef}
                                endRef={this.endRef}/>

                            {showTable &&
                            <ReportsTable
                                onError={this.handleError}
                                headers={this.state.tableHeaders}
                                attributes={this.state.tableAttributes}
                                exportUrl={this.state.exportUrl}
                                tableData={this.state.tableData}
                                decorateColumns={this.decorateColumns}/>
                            }

                        </div>
                    </div>
                );
            }
        })));