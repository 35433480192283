import React from "react";
import {TranslationMixin,WithCommonTranslations,} from 'tds_shared_ui'
import './NotificationAdminIcon.css';

export const NotificationAdminItem = (WithCommonTranslations(
    class extends TranslationMixin(React.Component) {
        constructor(props) {
            super(props);

            this.handleRemove = this.handleRemove.bind(this);
        }

        handleRemove() {
            this.props.onRemove(this.props.item.skuid) //Probably going to use id here
        }

        render() {
            const {item} = this.props;
            let icon = item.type === "info" ? <i className="fas fa-info-circle"></i> : <i className="fas fa-exclamation-triangle"></i>

            return (
                <div className="notification-item">
                    <div className="row">
                        <div className="col-auto col-icon">{icon}</div>
                        <div className="col col-icon">
                            <span className="font-weight-bold">{item.title}</span>
                            <div>{item.message}</div>
                        </div>
                    </div>
                </div>
            )
        }
    }));