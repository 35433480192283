import React from 'react';
import {
    Button, ConfirmationModal,
    LoadingIcon,
    TranslationMixin,
    WithCommonTranslations,
    WithFormValidation,
} from 'tds_shared_ui';
import {NotificationContent} from './NotificationContent';
import {NotificationProperties} from './NotificationProperties';
import {WithAdminTranslations} from '../hocs';

export const CreateNotificationsForm = WithAdminTranslations(
    WithCommonTranslations(
        WithFormValidation(class extends TranslationMixin(React.Component) {
            constructor(props) {
                super(props);

                this.state = {
                    loading: '',
                    showModal: false,
                    notificationContents: [
                        {
                            title: '',
                            message: '',
                            languageCode: '',
                            language: '',
                        }],
                };

                this.handleChange = this.handleChange.bind(this);
                this.handleClick = this.handleClick.bind(this);
                this.handleOpen = this.handleOpen.bind(this);
                this.handleClose = this.handleClose.bind(this);
                this.handleCheckChange = this.handleCheckChange.bind(this);
                this.handleDeleteContent = this.handleDeleteContent.bind(this);
                this.handleDeleteNotification = this.handleDeleteNotification.bind(
                    this);

            }

            handleDeleteContent(languageCode) {
                this.props.onDelete(languageCode);
            }

            handleCheckChange(languageCode, language, id, checkedValue) {
                id = this.props.formValues.id;
                this.props.onSelectChange(languageCode, language, id, checkedValue);
            }

            handleChange(name, value) {
                this.props.onChange(name, value);
            }

            handleClick() {
                this.setState({
                    loading: true,
                });
            }


            handleClose() {
                this.setState({
                    showModal: false,
                });
            }

            handleOpen() {
                this.setState({
                    showModal: true,
                });
            }

            handleDeleteNotification(id) {
                id = this.props.formValues.id;
                this.props.onRemove(id);
                this.handleClose();
            }

            render() {
                const {showModal} = this.state;
                const {onError, formValues, beginDateErrorMsg, endDateErrorMsg, isValidated, selectedLanguages, beginRef, endRef, onContentChange, onCancel, loading} = this.props;
                return (
                    <div>
                        <div className="forms-group">
                            <NotificationProperties onChange={this.handleChange}
                                                    onError={onError}
                                                    formValues={formValues}
                                                    beginDateErrorMsg={beginDateErrorMsg}
                                                    endDateErrorMsg={endDateErrorMsg}
                                                    onSelectChange={this.handleCheckChange}
                                                    isValidated={isValidated}
                                                    selectedLanguages={selectedLanguages}
                                                    beginRef={beginRef}
                                                    endRef={endRef}/>

                            {formValues.notificationContents.map((content) =>
                                <NotificationContent onDelete={this.handleDeleteContent}
                                                     formValues={content}
                                                     key={content.languageCode}
                                                     languages={formValues.language}
                                                     isValidated={isValidated}
                                                     onChange={onContentChange}/>)}
                        </div>

                        <ConfirmationModal open={showModal}
                                           className="loginConfirmModal"
                                           title={this.getTranslation(
                                               'CONFIRMATION_TITLE')}
                                           content={<div
                                               dangerouslySetInnerHTML={{
                                                   __html: this.getTranslation(
                                                       'HTML_CONFIRMATION_BODY_MSG'),
                                               }}></div>}
                                           okText={this.getTranslation(
                                               'CONFIRMATION_SUCCESS')}
                                           cancelText={this.getTranslation(
                                               'CONFIRMATION_CANCEL')}
                                           onCancel={this.handleClose}
                                           onSuccess={this.handleDeleteNotification}/>

                        <div className="clearfix mt-3">
                            <div className="float-left">
                                {formValues.id ?
                                    <Button className="p-0" onClick={this.handleOpen}
                                            variant='link'
                                            type='button'>{this.getTranslation(
                                        'BUTTON_DELETE_NOTIFICATION')}</Button> : ' '}
                            </div>

                            <div className="float-right">
                                <Button variant="link" type="button"
                                        onClick={onCancel}>{this.getTranslation(
                                    'BUTTON_CANCEL')}</Button>

                                <Button variant="primary" type="submit"
                                        disabled={loading}
                                        onClick={this.handleClick}>
                                    <LoadingIcon display={loading}/>
                                    {this.getTranslation('BUTTON_SAVE')}
                                </Button>
                            </div>
                        </div>
                    </div>

                );
            }

        })));