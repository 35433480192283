import React from 'react';

import {
    AlertMixin,
    Button,
    FormInput,
    Header,
    TranslationMixin,
    WithCommonTranslations,
    HelperUtils, Alert, Table,
} from 'tds_shared_ui';
import {ACDelcoAccountService} from "../services";
import {WithAdminTranslations} from '../components/hocs';

import './ACDelcoLookupPage.css';
import {Link} from "react-router-dom";

export const ACDelcoLookupPage = WithCommonTranslations(WithAdminTranslations(
    class extends AlertMixin(TranslationMixin(React.Component)) {
      constructor(props) {
        super(props);

        this.state = {
          accountNumber: '',
          results: null,
          alert: {
            display: false,
            type: 'default',
            message: '',
          },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
      }

      scrollToAlert() {
        HelperUtils.scrollToAlert(this.alertRef);
      }

      handleChange(e) {
        let value = e.target.value;

        this.clearError();
        this.setState({
          accountNumber: value,
        });
      }

      handleRemove(data) {
        console.log(data);
        return ACDelcoAccountService.remove(data.accountNumber)
            .then(() => {
              this.setState({
                results: null,
                accountNumber: '',
              });
              this.displayAlert(
                  this.getTranslation('MESSAGE_ACDELCO_ACCT_REMOVE_SUCCESS'),
                  'success');
            })
            .catch(e => this.displayError(e));
      }

      handleSearch(e) {
        e.preventDefault();

        this.clearError();

        if (this.state.accountNumber) {
          this.setState({
            loading: true,
            hideResults: true,
            results: null,
          });
          return ACDelcoAccountService.search(this.state.accountNumber)
              .then((data) => {

                this.setState({
                  searchResults: data,
                  hideResults: false,
                  results: data,
                });
              })
              .catch((e) => {
                this.displayError(e);
              })
              .finally(() => {
                this.setState({
                  loading: false,
                });
              });
        }
      }

      getCols() {
        return [
          {
            header: this.getTranslation('HEADER_USERNAME'),
            accessor: 'userName',
            render: (rowData) => {
              let username = rowData.userName;
              return <Link to={'/admin/user/profile?username=' + username} >{username}</Link>
            }
          },
          {
            header: this.getTranslation('LABEL_ACDELCO_ACCT_NUM'),
            accessor: 'accountNumber',
          },
          {
            header: this.getTranslation('LABEL_ACTIONS'),
            accessor: 'action',
            render: (row) =>
                <Button variant="link"
                        onClick={() => this.handleRemove(row)}>
                  {this.getTranslation('BUTTON_REMOVE')}</Button>,

          },
        ];
      }

      render() {
        const {accountNumber, results} = this.state;
        const cols = this.getCols();

        return (
            <div className="lookupPage">
              <Header level={1}>{this.getTranslation(
                  'TITLE_ACDELCO_ACCOUNT_LOOKUP')}</Header>
              <p>{this.getTranslation("MESSAGE_ACDELCO_ACCT_INSTRUCTIONS")}</p>

              {this.state.alert.display &&
              <Alert ref={this.alertRef}
                     type={this.state.alert.type}>{this.state.alert.message}</Alert>}

              <form noValidate onSubmit={this.handleSearch}>
                <div className="row searchRow">
                  <div className="col">
                    <FormInput id="searchField" name="searchField"
                               placeholder={this.getTranslation(
                                   'LABEL_ACDELCO_ACCT_NUM')}
                               autoComplete="off"
                               maxLength={100}
                               onChange={this.handleChange}
                               value={accountNumber}></FormInput>
                  </div>
                  <div className="col-auto">
                    <Button variant="primary" type="submit">
                      <i className="fas fa-search pull-right"></i>&nbsp;
                      {this.getTranslation('LABEL_SEARCH')}
                    </Button>
                  </div>
                </div>
              </form>
              {results &&
              <div className="result w-auto">
                <Table columns={cols} rows={[results]}
                       filterId="filterId"/>
              </div>}
            </div>
        );
      }
    }));