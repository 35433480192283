import {GenericAjaxService, adminAPI} from 'tds_shared_ui';

export default class SubscriptionsService extends GenericAjaxService {

  static getAll(username, opts) {
    return super.getAPI(adminAPI,
        `subscriptions?un=${encodeURIComponent(username)}`, opts);
  }

  static get(username, subData, opts) {
    let {id, productType} = subData;
    return super.getAPI(adminAPI,
        `subscriptions/${productType.toLowerCase()}?un=${encodeURIComponent(
            username)}&id=${id}`, opts);

  }

  static delete(id, type, comment, opts) {
    return super.deleteAPI(adminAPI, `subscriptions/${type}/${id}?note=${encodeURIComponent(comment)}`, opts);
  }

  static setEmailNotification(productId, bool, username, opts){
    return super.putAPI(adminAPI, `subscriptions/${productId}/notifications?q=${bool}&un=${username}`, opts);
  }

  static getFreeProducts(username, opts) {
    return super.getAPI(adminAPI,
        `subscriptions/products?un=${encodeURIComponent(username)}`, opts);
  }

  static getFreeSkuOptions(id, username, opts) {
    return super.getAPI(adminAPI,
        `subscriptions/${id}/skus?un=${encodeURIComponent(username)}`, opts);
  }

  static grantFreeSubscription(productId, skuId, quantity, username, productType, comment, opts) {
    let url = productType ? `subscriptions/${productType.toLowerCase()}/grant` : `subscriptions/grant`;

    return super.postAPI(adminAPI, url, {
      'username': username,
      'productId': productId,
      'skuId': skuId,
      'quantity': quantity,
      'adminComment': comment
    }, opts);
  }
}